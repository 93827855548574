<template>
  <div>
    <b-modal
      id="modal-coupon-booking"
      size="lg"
      centered
      hide-header
      cancel-variant="none"
      ok-title="Kiểm tra"
      cancel-title="Quay lại"
      @ok="saveNoShow"
      ok-variant="primary"
    >
      <b-card-header class="bg-linear-warning">
        <div class="d-flex justify-content-between align-items-center block-header">
          <p class="mb-0 title-modal color-warning">
            Áp dụng mã
          </p>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-red color-warning"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <div class="form">
          <div class="form-block">
            <b-form-group>
              <b-form-input v-model="reason" placeholder="Nhập mã áp dụng"/>
              <span class="error" v-if="error">
                vui lòng nhập mã áp dụng
              </span>
            </b-form-group>
          </div>
        </div>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import {BCardHeader, BFormGroup, BFormInput, BModal, BCardBody} from 'bootstrap-vue'

export default {
  components: {
    BModal, BCardHeader, BFormInput, BFormGroup, BCardBody
  },
  data() {
    return {
      reason: '',
      error: false,
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-coupon-booking')
    },
    saveNoShow(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.reason) {
        return this.error = true
      }
      this.error = false
      alert('[TODO] chưa có API')
      this.$nextTick(() => {
        this.$bvModal.hide('modal-coupon-booking')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables';

.color-warning {
  color: #114A9F;
}

.error {
  color: red;
}

.pd15 {
  padding: 15px;
}

::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 400px;
  }
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>

<template>
  <b-modal
    id="transfer-subscription"
    v-model="isOpen"
    size="md"
    centered
    hide-header
    ok-only
    cancel-variant="none"
    ok-title="Cập nhật"
    ok-variant="primary"
    @ok="transferSubscription_USU04"
    no-close-on-backdrop
  >
    <b-card-header class="banned-background-color transfer">
      <div class="d-flex justify-content-between align-items-center transfer">
        <p class=" mb-0 title-modal">
          {{ $t('golf_member_transfer_subscription') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`#114a9f`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="isLoadding"
        :opacity="0.3"
        rounded="sm"
      > 
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <div class="title d-flex justify-content-between">
              {{ $t('golf_member_member_inheritance_info') }}
              <ez-icon
                icon="ezGolf-icon-edit"
                class="pointer"
                size="18"
                @click="refreshData"
              />
            </div>
            
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col
                cols="5"
                class="d-flex justify-content-center align-items-center"
              >
                <div class="avatar">
                  <label for="upload-new-image">
                    <b-avatar
                      v-model="memberDetail.Avatar"
                      size="140"
                      :src="(memberDetail.Avatar) ? 'data:image/jpeg;base64,' + memberDetail.Avatar : ''"
                      style="margin: 0 16px 10px 0"
                      type="button"
                    />
                    <input
                      id="upload-new-image"
                      class="d-none"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      @change="uploadImage(isAddNew = true)"
                    >
                  </label>
                </div>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12" class="pl-0">
                    <b-form-group :label="$t('golf_common_first_and_last_name')">
                      <b-form-input 
                        :disabled="editMemberDetail ? false : true"
                        v-model="memberDetail.FullName"
                        :placeholder="$t('golf_common_first_and_last_name')" 
                        @change="getListMember_RME01"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="5" class="pl-0">
                    <b-form-group :label="$t('golf_member_card_code_2')">
                      <b-form-input
                      :disabled="editMemberDetail ? false : true"
                      v-model="memberDetail.CardCode"
                      :placeholder="$t('golf_member_card_code_2')"
                      @change="getListMember_RME01"
                    />
                    </b-form-group>
                  </b-col>
                    
                  <b-col cols="7" class="pl-0">
                    <b-form-group :label="$t('golf_common_phone')">
                      <b-form-input
                        :disabled="editMemberDetail ? false : true"
                        v-model="memberDetail.PhoneNumber"
                        type="number"
                        :placeholder="$t('golf_member_input_phone')"
                        @change="getListMember_RME01"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="pl-0">
                    <b-form-group label="Email">
                      <b-form-input
                      :disabled="editMemberDetail ? false : true"
                      v-model="memberDetail.Email"
                      :placeholder="$t('golf_member_input_email')"
                      @change="getListMember_RME01"
                    />
                    </b-form-group>
                  </b-col>      
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="5">
                <b-form-group :label="$t('golf_common_date_of_birth')">
                  <date-range-picker
                  :disabled="editMemberDetail ? false : true"
                  v-model="memberDetail.Birthday"
                  ref="picker"
                  :single-date-picker="true"
                  :ranges="false"
                  opens="right"
                  :time-picker="false"
                  :time-picker-increment="1"
                  :show-dropdowns="true"
                  :auto-apply="true"
                  :locale-data="{
                      format: 'mm/dd/YYYY',
                      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                      firstDay: 0
                  }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7" class="pl-0">
                <b-form-group :label="$t('golf_member_country')">
                  <v-select
                  :disabled="editMemberDetail ? false : true"
                    v-model="memberDetail.NationalityCode"
                    label="CountryNameVN"
                    :options="countries"
                    :reduce="option => option.ID"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row >
              <b-col cols="5" >
                <b-form-group :label="$t('golf_member_member_id')">
                      <b-form-input
                      :disabled="editMemberDetail ? false : true"
                        v-model="memberDetail.IdentityCard"
                        :placeholder="$t('golf_member_member_id')"
                      />
                </b-form-group>
              </b-col>
              <b-col cols="7" class="pl-0">
                <b-form-group :label="$t('golf_member_address1')">
                  <b-form-input
                  :disabled="editMemberDetail ? false : true"
                    v-model="memberDetail.Address"
                    :placeholder="$t('golf_member_address1')"
                  />
                </b-form-group>
              </b-col>
              <div
                v-if="editMemberDetail && listMember.length > 0"
                class="suggest-guest"
              >
                <h6 class="suggest-guest-title">
                  Gợi ý khách
                </h6>
                <b-media
                  v-for="(item, index) in listMember"
                  :key="index"
                  no-body
                  class="suggest-guest-item"
                  @click="addMemberToList(item)"
                >
                  <b-media-aside
                    class="media-left"
                    style="margin-right: 10px;"
                  >
                    <UserAvatar :user="item" />
                  </b-media-aside>
                  <b-media-body class="text-truncate">
                    <h6 class="text-truncate">
                      <span><strong>{{ item.MemberName }}</strong></span>
                    </h6>
                    <div class="phone-email">
                      <span>
                        {{ item.PhoneNumber }}
                      </span>
                      <span class="text-truncate">
                        {{ item.Email }}
                      </span>
                    </div>
                    <div
                      v-for="(des, ind) in item.MemberSubscription"
                      :key="ind"
                      class="card-type"
                    >
                      <div>
                        {{ des.MemberCard.CardTypeName }}
                      </div>
                      <div class="card-code">
                        {{ des.CardCode }}
                      </div>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-row>
            <b-row v-if="editMemberDetail">
              <div class="w-100 pr-1">
                <b-button
                  variant="primary"
                  size="md"
                  class="float-right"
                  @click="addMember"
                >
                  {{ $t('golf_common_save') }}
                </b-button>
              </div>
              
            </b-row>
            <b-row>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="title mt-1">
              {{ $t('golf_member_member_transfer_info') }}
            </div>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col cols="7">
                    <b-form-group>
                      <b-form-input
                        v-model="MemberSubscriptionTransfer.TransferFee"
                        type="number"
                        :placeholder="$t('golf_member_enter_transfer_fee')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="5">
                    <b-form-group>
                      <date-range-picker
                        v-model="MemberSubscriptionTransfer.TransferDate"
                        ref="picker"
                        :single-date-picker="true"
                        :ranges="false"
                        opens="right"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                            format: 'mm/dd/YYYY',
                            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            firstDay: 0
                        }"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="MemberSubscriptionTransfer.Note"
                    class="mt-1"
                    :placeholder="$t('golf_member_enter_the_reason_for_the_transfer')"
                    rows="2"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('golf_member_typing') }} {{ $t('golf_member_member_transfer_reason') }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
      
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import dayjs from 'dayjs'
import { members } from '@/api/members'
import UserAvatar from '@/views/modules/booking/UserAvatar'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    UserAvatar
  },
  props: [
    'data',
    'countries'
  ],
  data() {
    return {
      MemberSubscriptionTransfer: {
        MemberSubscriptionId: this.data.Id,
        MemberId: null,
        TransferFee: null,
        TransferDate: null,
        Note: null,
      },
      memberDetail: {
        Avatar: null,
        FullName: null,
        Address: null,
        Birthday: null,
        Email: null,
        PhoneNumber: null,
        IdentityCard: null,
        CardCode: null,
        NationalityCode: null
      },
      listMember: [],
      editMemberDetail: true,
      isLoadding: false,
      isOpen: false
    }
  },
  watch: {
    isOpen(val) {
      if(val) {
        this.refreshData()
      }
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('transfer-subscription')
    },

    async transferSubscription_USU04(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.isLoadding = true
          if(this.MemberSubscriptionTransfer.MemberId && this.MemberSubscriptionTransfer.TransferFee && this.MemberSubscriptionTransfer.TransferDate && this.MemberSubscriptionTransfer.Note) {
            this.$refs.simpleRules.reset()
            this.MemberSubscriptionTransfer.TransferFee = Number(this.MemberSubscriptionTransfer.TransferFee)
            const response = await members.transferSubscription({
              MemberSubscriptionTransfer: this.MemberSubscriptionTransfer,
            })

            this.showResToast(response)
            this.isLoadding = false
            if (response.Status === '200') {
              this.$emit('event', { type: 'action_success' })
              this.$bvModal.hide('transfer-subscription')
            }
          } else {
            this.showToast('error', "Vui lòng lưu thông tin khách hoặc nhập đầy đủ thông tin trước khi cập nhật")
            this.isLoadding = false
          }
        }
      })
    },
    uploadImage(isAddNew) {
      let file = ''
      if (isAddNew) {
        file = document.querySelector('#upload-new-image').files[0]
      } else {
        file = document.querySelector('#upload-image').files[0]
      }
      const reader = new FileReader()
      reader.onload = e => {
        const dataURI = e.target.result
        if (dataURI) {
          this.memberDetail.Avatar = dataURI.replace(/^data:image\/(png|jpg|gif|jpeg);base64,/, '')
        }
      }
      reader.readAsDataURL(file)
    },
    async getListMember_RME01() {
      if (this.memberDetail.FullName || this.memberDetail.CardCode || this.memberDetail.Email || this.memberDetail.PhoneNumber) {
        const requestData = {
          MemberName: (this.memberDetail.FullName ? this.memberDetail.FullName : ''),
          CardCode: this.memberDetail.CardCode,
          CardTypeId: null,
          MemberType: null,
          Email: this.memberDetail.Email,
          PhoneNumber: this.memberDetail.PhoneNumber,
        }

        const response = await members.getListMember(requestData)
        if (response.Status === '200') {
          this.listMember = response?.Data?.Member
        }
      } else {
        this.listMember = []
      }
    },
    async addMember() {
      this.isLoadding = true
      this.editMemberDetail = false
      this.listMember = []
        const body = {
          Member: {
            Avatar: this.memberDetail.Avatar,
            FullName: this.memberDetail.FullName,
            Address: this.memberDetail.Address,
            Birthday: this.memberDetail.Birthday,
            Email: this.memberDetail.Email,
            PhoneNumber: this.memberDetail.PhoneNumber,
            IdentityCard: this.memberDetail.IdentityCard,
            NationalityCode: this.memberDetail.NationalityCode
          },
          BookingDetailId: null,
        }
        await members.createMember(body).then(res => {
          this.MemberSubscriptionTransfer.MemberId = res.Data.MemberId
          this.getMemberDetail(res.Data.MemberId)
        })
    },
    async addMemberToList(data) {
      console.log(data.MemberSubscription.filter(x => x.Status == 'UN_DEPOSTION'))
      this.isLoadding = true
      this.editMemberDetail = false
      this.listMember = []
      this.MemberSubscriptionTransfer.MemberId = data.Id
      if(data.MemberSubscription.filter(x => x.Status == 'UN_DEPOSTION').length > 0) {
        this.memberDetail.CardCode = data.MemberSubscription.filter(x => x.Status == 'UN_DEPOSTION')[0].CardCode
      } else {
        this.memberDetail.CardCode = null
      }
      this.getMemberDetail(data.Id)
    },
    async getMemberDetail(data) {
      await members.detailMember({
        Member: {
          Id: data,
        },
      }).then(res => {
        this.memberDetail.Avatar = res.Data.Member.Avatar
        this.memberDetail.FullName = res.Data.Member.FullName
        this.memberDetail.Address = res.Data.Member.Address
        this.memberDetail.Birthday = res.Data.Member.Birthday
        this.memberDetail.Email = res.Data.Member.Email
        this.memberDetail.PhoneNumber = res.Data.Member.PhoneNumber
        this.memberDetail.IdentityCard = res.Data.Member.IdentityCard
        this.memberDetail.NationalityCode = res.Data.Member.NationalityCode
        this.isLoadding = false
      })
    },
    refreshData() {
      this.editMemberDetail = true
      this.MemberSubscriptionTransfer.MemberId = null
      this.memberDetail =  {
        Avatar: null,
        FullName: null,
        Address: null,
        Birthday: null,
        Email: null,
        PhoneNumber: null,
        IdentityCard: null,
        CardCode: null,
        NationalityCode: null
      }
    }
  },
}
</script>

<style lang="scss">
.avatar {
  height: 140px;
  width: 140px;
  background-color: #ccc;
  border-radius: 50%;
}
.title {
  font-size: 14px;
  color: #424242;
  font-weight: 500;
  padding-bottom: 10px;
}
.banned-background-color.transfer {
  width: 100%;
  padding: 0 18px;
  height: 40px;
  background: linear-gradient(0deg, rgba(17, 74, 159, 0.12), rgba(17, 74, 159, 0.12)), #FFFFFF !important;
  border-radius: 6px 6px 0px 0px;

}

.transfer {
  height: 48px;
}
.title-modal {
  font-size: 18px;
  line-height: 48px;
  font-weight: 500;
  color: #114A9F;
}
.suggest-guest {
    position: fixed;
    top: 30%;
    left: 30%;
    width: 320px;
    height: 390px;
    right: 0;
    z-index: 99;
    bottom: 0;
    overflow-y: auto;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 50%);
    &-title {
        padding: 10px 15px;
    }
    &-item {
       padding: 8px 15px;
       cursor: pointer;
       &:nth-child(2n) {
            background: linear-gradient(0deg, rgba(17, 74, 159, 0.05), rgba(17, 74, 159, 0.05)), #FFFFFF;
       }
       &:hover {
            background: linear-gradient(0deg, rgba(17, 74, 159, 0.12), rgba(17, 74, 159, 0.12)), #FFFFFF;
       }
       .media-aside {
        align-self: center!important;
       }
       .media-body {
            .phone-email {
                max-width: 220px;
                display: flex;
                justify-content: space-between;
                span:first-child{
                    flex-basis: 100px;
                }
            }
            .card-type {
                display: flex;
                justify-content: space-between;
                .card-code {
                    color: #29B869;
                    font-weight: 500;
                    font-size: 12px;
                }
            }
       }
       
    }
    .pointer {
        padding: 12px;
    }
    .pointer:hover {
        background: #f5f5f5;
    }
    .d-title {
        padding: 12px 12px 0 12px;
    }
}
</style>

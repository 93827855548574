import {
  ROOT,
  GET_LIST_GOLF_CLASS,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListGolfClass(data) {
  return fetch(ROOT + GET_LIST_GOLF_CLASS, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const golfClassSetting = {
  getListGolfClass,
}

import {
  BFormInput, BRow, BCol,
  BFormGroup, BButton, BFormRadioGroup, BFormCheckboxGroup,
  BInputGroup,
} from 'bootstrap-vue'

import { directive as onClickaway } from 'vue-clickaway'
import { createPopper } from '@popperjs/core'

export default {
  name: 'SpecialDay',
  components: {
    BFormCheckboxGroup,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    BFormRadioGroup,
  },
  props: {
    value: {
      type: Array,
      required: false,
    },
    error: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  directives: {
    onClickaway,
  },
  data() {
    return {
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      popperInstance: null,
      open: false,
      uid: Math.floor(Math.random() * 100),
    }
  },
  created() {
  },
  methods: {
    updateValue() {
      this.$emit('input', this.value)
    },
    show() {
      this.open = true
      document.body.append(document.querySelector(`#popup${this.uid}`))

      const button = document.querySelector(`#value${this.uid}`)
      const tooltip = document.querySelector(`#popup${this.uid}`)
      const popper = createPopper(button, tooltip, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -103],
            },
          },
        ],
      })

      // Update its position
      return () => popper.destroy()
    },
    onClickOutside(event) {
      this.open = false
    },
  },
}

<template>
  <b-popover target="payment-type"
    id="payment-type-popover"
    triggers="focus"
    placement="bottomright"
    ref="popover"
  >
    <!--             :show.sync="show"-->
    <div class="row" >
      <div class="col-12">
        <!-- Table -->
        <div  v-for="(item, index) in data" :key="index" >
          <b-table
            borderless
            :items="item['data']"
            :fields="item['fields']"
            responsive="sm"
            fixed
            detailsShowing
            :tbody-tr-class="'payment-type-tr'"
          >

            <template v-for="field in item['fields']" v-slot:[`cell(${field.key})`]="{ itemx }">
              <div :key="field.id" v-if="field.key === itemx.Name">
                <div >
                  {{ itemx.Name }} <br>
                </div>
              </div>
            </template>
            <template v-for="field in item['fields']" v-slot:[`cell(${field.key})`]="{ item }">
              <div @click="handleClickOnCell" class="payment-cell" v-b-tooltip.hover >{{ item[field.key] }}</div>
            </template>
          </b-table>
        </div>
      </div>
    </div>

  </b-popover>
</template>
<script>
import {VBTooltip} from 'bootstrap-vue'

export default {
  components: {
    loader: true,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props:[
    'data'
  ]
  ,
  data() {
    return {
      show:true,
      title: 'Tham số',
      PaymentMethods: [
      ],
      fields: [
        {
          key: 'col1',
          label: 'Tiền mặt',
          thStyle: {width: "30%",},
          thClass: 'payment-type-th',
          tdClass: 'payment-type-td',
        },
        {
          key: 'col2',
          label: 'Chuyển khoảng',
          thStyle: {width: "40%"},
          thClass: 'payment-type-th',
          tdClass: 'payment-type-td',
        },
        {
          key: 'col3',
          label: 'Công nợ',
          thStyle: {width: "30%"},
          thClass: 'payment-type-th',
          tdClass: 'payment-type-td',
        }
      ],
      items1: [
        {col1: '40', col2: 'Dickerson', col3: 'Macdonald'},
        {col1: '40', col2: 'Dickerson', col3: 'Macdonald'},
        {col1: '40', col2: 'Dickerson', col3: 'Macdonald'},
        {col1: '40', col2: 'Dickerson', col3: 'Macdonald'},
        {col1: '40', col2: 'Dickerson', col3: 'Macdonald'},
      ],
    }
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
      },
      deep: true
    },
  },
  mounted() {

  },

  methods: {
    handleClickOnCell(){
      // console.log("handleClickOnCell");
    },
    async getData() {
    }
  },
}
</script>
<style lang="scss" scoped>

</style>

import {
  ROOT,
  GET_LIST_MEMBER_API,
  CREATE_MEMBER_API,
  UPDATE_MEMBER_API,
  DETAIL_MEMBER_API,
  ACTIVE_MEMBER_API,
  DEACTIVE_MEMBER_API,
  DELETE_MEMBER_API,
  GET_COUNTRIES_API,
  GET_COMPANIES_API,
  GET_LIST_SUBSCRIPTION_API,
  UPDATE_SUBSCRIPTION_API,
  DELETE_SUBSCRIPTION_API,
  CREATE_SUBSCRIPTION_API,
  BANS_SUBSCRIPTION_API,
  UNBANS_SUBSCRIPTION_API,
  TRANSFER_SUBSCRIPTION_API,
  GET_LIST_COMBO_API,
  GET_HISTORY_API, GET_DETAIL_SUBSCRIPTION_API,
  ADD_PACKAGE_RETAIL,
  UPDATE_PACKAGE_RETAIL,
  CREATE_PAYMENT_RETAIL,
  ADD_PAYMENT_RETAIL,
  GET_PAYMENT_RETAIL,
  GET_PACKAGE_RETAIL_DETAIL,
  API_RMEH02,
  API_IME02,
  MERGE_MEMBER_API,
  CHECK_MEMBER_DUP_VGA
} from '@/api/constant'
import {handleResponse} from '@/api/handle-response'
import {requestOptions} from '@/api/request-options'

function getListMember(data) {
  return fetch(ROOT + GET_LIST_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function CheckMember_RME07(data) {
  return fetch(ROOT + CHECK_MEMBER_DUP_VGA, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function api_IME02(data) {
  return fetch(ROOT + API_IME02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function createMember(data) {
  return fetch(ROOT + CREATE_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function mergeMember(data) {
  return fetch(ROOT + MERGE_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function updateMember(data) {
  return fetch(ROOT + UPDATE_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function activeMember(data) {
  return fetch(ROOT + ACTIVE_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function deActiveMember(data) {
  return fetch(ROOT + DEACTIVE_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function deleteMember(data) {
  return fetch(ROOT + DELETE_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function detailMember(data) {
  return fetch(ROOT + DETAIL_MEMBER_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function getCountries(data) {
  return fetch(ROOT + GET_COUNTRIES_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function getCompanies(data) {
  return fetch(ROOT + GET_COMPANIES_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function getSubscriptions(data) {
  return fetch(ROOT + GET_LIST_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function getSubscriptionDetail(data) {
  return fetch(ROOT + GET_DETAIL_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function updateSubscription(data) {
  return fetch(ROOT + UPDATE_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function deleteSubscription(data) {
  return fetch(ROOT + DELETE_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function createSubscription(data) {
  return fetch(ROOT + CREATE_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function bansSubscription(data) {
  return fetch(ROOT + BANS_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function unBansSubscription(data) {
  return fetch(ROOT + UNBANS_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function transferSubscription(data) {
  return fetch(ROOT + TRANSFER_SUBSCRIPTION_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function getListCombo(data) {
  return fetch(ROOT + GET_LIST_COMBO_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function getHistoryPlaying(data) {
  return fetch(ROOT + GET_HISTORY_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}
function addPackageRetail(data) {
  return fetch(ROOT + ADD_PACKAGE_RETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function updatePackageRetail(data) {
  return fetch(ROOT + UPDATE_PACKAGE_RETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function createPaymentRetail(data) {
  return fetch(ROOT + CREATE_PAYMENT_RETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function addPaymentRetail(data) {
  return fetch(ROOT + ADD_PAYMENT_RETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getPaymentRetail(data) {
  return fetch(ROOT + GET_PAYMENT_RETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getPackageRetailDetail(data) {
  return fetch(ROOT + GET_PACKAGE_RETAIL_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RMEH02(data) {
  return fetch(ROOT + API_RMEH02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
export const members = {
  getListMember,
  createMember,
  updateMember,
  detailMember,
  deleteMember,
  activeMember,
  deActiveMember,
  getCountries,
  getCompanies,
  getSubscriptions,
  getSubscriptionDetail,
  updateSubscription,
  deleteSubscription,
  createSubscription,
  unBansSubscription,
  bansSubscription,
  transferSubscription,
  getListCombo,
  getHistoryPlaying,
  addPackageRetail,
  updatePackageRetail,
  createPaymentRetail,
  addPaymentRetail,
  getPaymentRetail,
  getPackageRetailDetail,
  api_RMEH02,
  api_IME02,
  mergeMember,
  CheckMember_RME07
}

<template>
  <div id="subscription-detail">
    <b-modal
      id="subscription-detail"
      size="lg"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
      @shown="showSubscriptionDetail"
      v-model="isOpen"
    >
      <b-card-header class="banned-background-color detail">
        <div class="d-flex justify-content-between align-items-center detail-subscription">
          <p
            v-if="!isCreate"
            class=" mb-0 title-modal"
          >
            {{ $t('golf_member_member_sub_detail') }}
          </p>
          <p
            v-if="isCreate"
            class=" mb-0 title-modal"
          >
            {{ $t('golf_member_member_add_subscription') }}
          </p>
          <ez-icon
            icon="ezGolf-icon-x"
            :color="`#114a9f`"
            class="cursor"
            :size="24"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <validation-observer ref="simpleRules">
        <b-tabs
          v-model="tabIndex"
          :class="{removeTabs: isCreate}"
        >
          <b-tab
            active
            :title="$t('golf_member_subscription_information')"
            :class="{ 'inactive': data.SubscriptionStatus }"
          >
            <div class="information">
              <b-row>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <label>{{ $t('golf_member_card_code_2') }} <span class="color-red">*</span></label>
                      <b-form-input
                        v-model="data.CardCode"
                        placeholder="Mã thẻ"
                        name="cardcode"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('golf_member_card_code_require') }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <label>{{ $t('golf_member_member_contract_code') }} <span class="color-red">*</span></label>
                      <b-form-input
                        v-model="data.ContractCode"
                        placeholder="Mã hợp đồng"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('golf_member_card_contract_require') }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <label>{{ $t('golf_member_member_type_card') }}</label>
                    <v-select
                      v-model="cardTypeSelected"
                      label="CardTypeName"
                      :options="memberCard"
                      class="bg-white"
                      placeholder="chọn loại thẻ"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label>{{ $t('golf_member_member_contract_value') }}</label>
                    <b-form-input
                      v-model="data.ContractValue"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <b-form-group>
                    <label>{{ $t('golf_member_member_turn_rest') }}</label>
                    <b-form-input
                      v-model="data.NumberOfPlays"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group>
                    <label>{{ $t('golf_member_subscription_transfer_turn') }}</label>
                    <b-form-input
                      v-model="data.TransferTurn"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <label>{{ $t('golf_member_date_contract_start') }} <span class="color-red">*</span></label>
                      <b-form-group>
                        <date-range-picker
                          v-model="data.OpenDate"
                          :ranges="false"
                          opens="topleft"
                          :show-dropdowns="true"
                          :time-picker="false"
                          :time-picker24hour="false"
                          :auto-apply="true"
                          :single-date-picker="true"
                          :locale-data="{
                            format: 'DD/MM/YYYY',
                            daysOfWeek: ['CN', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            firstDay: 0
                          }"
                        />
                        <small
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ $t('golf_member_contract_date_require') }}</small>
                      </b-form-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="3">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <label>{{ $t('golf_member_date_contract_end') }} <span class="color-red">*</span></label>
                      <!-- :min-date="data.OpenDate" -->
                      <b-form-group>
                        <date-range-picker
                          v-model="data.EndDate"
                          :ranges="false"
                          opens="topleft"
                          :show-dropdowns="true"
                          :time-picker="false"
                          :time-picker24hour="false"
                          :auto-apply="true"
                          :single-date-picker="true"
                          :locale-data="{
                            format: 'DD/MM/YYYY',
                            daysOfWeek: ['CN', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            firstDay: 0
                          }"
                        />
                        <small
                          v-if="errors[0]"
                          class="text-danger"
                        >{{ $t('golf_member_contract_date_require') }}</small>
                      </b-form-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('golf_member_contract_file')"
                  >
                    <div class="upload">
                      <label
                        for="upload-contact"
                        class="pointer-event"
                      >
                        {{ $t('golf_common_drag_and_drop_into_this_box_or') }} <span>{{ $t('golf_common_upload') }}</span>
                      </label>
                      <div class="items">
                        <ul>
                          <li
                            v-for="(item,index) of data.ContractFile"
                            v-if="item.ContractFileName"
                            :key="item.ContractFileName"
                          >
                            <span @click="downloadContract(item)">{{ item.ContractFileName }}</span>
                            <ez-icon
                              icon="ezGolf-icon-trash"
                              size="14"
                              color="#ff0000"
                              style="margin-left: 10px"
                              class="mr-50 delete-contract"
                              @click="deleteContract(index)"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <input
                      id="upload-contact"
                      class="d-none"
                      type="file"
                      multiple
                      @change="uploadContactFile"
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('golf_member_note')"
                  >
                    <b-form-textarea
                      v-model="data.Note"
                      :placeholder="$t('golf_member_note')"
                      rows="5"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="text-danger"
                  cols="12"
                >
                  <div v-if="data.SubscriptionStatus && member">
                    {{ $t('golf_member_member') }} {{ member.FullName }} {{ $t('golf_member_was_bans') }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab
            v-if="!isCreate"
            :title="$t('golf_member_subscription_history')"
          >
            <div class="history">
              <b-row style="margin-bottom:10px">
                <b-col cols="5">
                  <b-form-group>
                    <date-range-picker
                      ref="picker"
                      v-model="OpenDate"
                      :single-date-picker="true"
                      :ranges="false"
                      opens="right"
                      :time-picker="false"
                      :time-picker-increment="1"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :locale-data="{
                        format: 'mm/dd/YYYY',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="5">
                  <b-form-group>
                    <date-range-picker
                      ref="picker"
                      v-model="EndDate"
                      :single-date-picker="true"
                      :ranges="false"
                      opens="right"
                      :time-picker="false"
                      :time-picker-increment="1"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :locale-data="{
                        format: 'mm/dd/YYYY',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    style="float: right;"
                    @click="search()"
                  >
                    {{ $t('golf_common_search') }}
                  </b-button>
                </b-col>
              </b-row>
              <div class="timeLineHistory">
                <app-timeline class="mt-1 mr-1">
                <app-timeline-item
                  v-for="item in dataHistory"
                  :key="item"
                  :title="titleType(item.Type)"
                  :subtitle="subtitleType(item)"
                  :subtitle2="subtitle2Type(item)"
                  :subtitle3="subtitle3Type(item)"
                  :subtitle4="subtitle4Type(item)"
                  :subtitle5="subtitle5Type(item)"
                  :time="convertUTC(item.CreateDate,'HH:mm DD/MM/YYYY')"
                  :variant="variantType(item.Type)"
                />
              </app-timeline>
              </div>
              
            </div>
          </b-tab>
          <b-tab v-if="!isCreate"
            :title="$t('golf_member_subscription_annual_fee')"
          >
            <div class="annual_fee">
              <b-row>
                <b-col cols="5">
                  <b-form-group label="Tìm kiếm BDC"> 
                    <b-form-input v-model="searchBDCRetail" @change="searchBookingRetail"/>
                  </b-form-group>
                </b-col>
                <b-col cols="7">
                  <b-form-group label="Chọn booking bán lẻ"> 
                    <v-select
                      v-model="BookingRetailSelect"
                      label="label"
                      :options="listBookingRetail"
                      :clearable="false"
                      @input="getDetailBookingRetail(BookingRetailSelect.Id)"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div>
                <b-form-group label="Chọn dịch vụ">
                  <div v-for="(item,index) in listService" :key="index + 'sv'" class="d-flex mb-1">
                    <b-form-checkbox class="mb-auto mt-auto" v-model="item.check" >
                      <span class="ml-1 mb-auto mt-auto">{{ item.ServiceName }}: {{ formatPrice(item.TotalAmount) }}</span> 
                    </b-form-checkbox>
                    <v-select
                      style="width: 120px;margin-left: 10px;"
                      v-model="item.Type"
                      label="label"
                      :options="TypeSelect"
                      :clearable="false"
                      @input="changeTypeSelect(item)"
                    />
                    <div v-if="item.Type == 'ADD'" class="d-flex ml-1">
                      <date-range-picker
                        v-model="item.MemberSubscriptionFromDate"
                        ref="picker"
                        :single-date-picker="true"
                        :ranges="false"
                        opens="right"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                          format: 'mm/dd/YYYY',
                          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
                      />
                      <date-range-picker
                        v-model="item.MemberSubscriptionToDate"
                        class="ml-1"
                        ref="picker"
                        :single-date-picker="true"
                        :ranges="false"
                        opens="right"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                          format: 'mm/dd/YYYY',
                          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
                      />
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </validation-observer>
      <div
        v-if="tabIndex==0"
        class="d-flex p-2 justify-content-between align-items-center"
      >
        <div class="d-flex">
          <div
            v-if="!isCreate && !data.DepositionFromDate && !data.DepositionToDate"
            class="_button-no-bg primary"
            @click="transferSubscription"
          >
            {{ $t('golf_member_member_transfer') }}
          </div>
          <div
            v-if="!isCreate && !data.DepositionFromDate && !data.DepositionToDate"
            class="_button-no-bg warning ml-1"
            @click="bansSubscription"
          >
            {{ $t('golf_member_member_bans') }}
          </div>

          <div
            v-if="!isCreate && data.DepositionFromDate && data.DepositionToDate"
            class="_button-no-bg warning ml-1"
            @click="unBans"
          >
            {{ $t('golf_member_member_un_bans') }}
          </div>
        </div>
        <button
          v-if="!isCreate"
          class="btn btn-primary"
          v-b-tooltip.hover title="Ctrl + S"
          @click="updateSubscription_USU01()"
        >
          {{ $t('golf_common_update') }}
        </button>
        <button
          v-if="isCreate"
          class="btn btn-primary"
          v-b-tooltip.hover title="Ctrl + S"
          @click="createSubscription_ISU01()"
        >
          {{ $t('golf_common_save') }}
        </button>
      </div>
      <div
        v-if="tabIndex==2"
        class="d-flex p-2 justify-content-end"
      >
        <button
          class="btn btn-primary"
          v-b-tooltip.hover title="Ctrl + S"
          @click="updateAnnualFeeSubcription()"
        >
          {{ $t('golf_common_save') }}
        </button>
      </div>

      <!-- modals -->
      <TransferSubscription :countries="countries" :data="data" @event="handleEvent"/>
      <BansSubscription
        :data="data"
        @event="handleEvent"
      />
      <UnBansSubscription
        :data="data"
        @event="handleEvent"
      />
      <ShortKey
        :shortcuts="ShortCutKey"
        :is-active="isOpen"
        :all-child-component-closed="allChildComponentClosed"
        @triggered="onTriggeredEventHandler"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardBody,
  BButton,
  BTabs,
  BTab,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { members } from '@/api/members'
import dayjs from 'dayjs'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import BansSubscription from './BansSubscription.vue'
import UnBansSubscription from './UnBansSubscription.vue'
import TransferSubscription from './TransferSubscription.vue'
import { formatPrice } from '@/@core/utils/utils'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { apiBookingRetail } from '@/api/booking-retail'
import { booking } from '@/api/booking'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    vSelect,
    required,
    BCardHeader,
    BCardBody,
    BButton,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    flatPickr,
    BFormFile,
    BFormTextarea,
    AppTimeline,
    AppTimelineItem,
    TransferSubscription,
    BansSubscription,
    UnBansSubscription,
    ShortKey
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    dataHistory: {
      type: Object,
      default: null,
    },
    isCreate: {
      type: Boolean,
      default: true,
    },
    member: {
      type: Object,
      default: null,
    },
    memberCard: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: []
    }
  },
  watch: {
    isOpen(value) {
      this.$emit('is-open', {ComponentId: "subscription-detail",IsActive:value});
      if(value) {
        this.getHistoryMemberSubcriptionPayment_RMEH02()
      }
    }
  },
  data() {
    return {
      tabIndex: 0,
      // rangeDate: null,
      file: null,
      cardTypeSelected: null,
      OpenDate:  this.dayjs(this.getUTC('start', 'day')).add(-30, 'day').format('YYYY-MM-DDTHH:mm:ss'),
      EndDate: this.getUTC('start', 'day'),
      timeZoneOffSet: localStorage.getItem('timeZoneOffset'),
      isOpen: false,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [],
      searchBDCRetail: null,
      listBookingRetail: [],
      BookingRetailSelect: null,
      listService: [],
      TypeSelect: ['ADD','REMOVE']
    }
  },
 
  methods: {
    async updateAnnualFeeSubcription() {
      const ServiceTemp = this.listService.filter(x => x.check == true)
      if(ServiceTemp.length > 0) {
        const body = {
          Service: []
        }
        ServiceTemp.forEach(x => {
          body.Service.push({
            BookingRetailId: this.BookingRetailSelect.Id,
            BookingRetailServiceId: x.Id,
            MemberSubscriptionId: this.data.Id,
            MemberSubscriptionFromDate: x.MemberSubscriptionFromDate,
            MemberSubscriptionToDate: x.MemberSubscriptionToDate,
            Type: x.Type
          })
        })
        await apiBookingRetail.api_UBKRT2(body).then(res => {
          this.showResToast(res)
          if(res.Status == 200) {
            this.search()
            this.searchBDCRetail = null
            this.listBookingRetail = []
            this.BookingRetailSelect = null
            this.listService = []
          }
        })
      } else {
        return this.showToast('error', this.$t('golf_update_sub_please_select_one_service'))
      }
    },
    async searchBookingRetail() {
      this.BookingRetailSelect = null
      this.listService = []
      const body = {
        BookingRetailCode: null,
        MemberName: null,
        BookingRetailDate: new Date(dayjs().startOf('day')).toISOString(),
        CashierCode: null,
        BDC: [this.searchBDCRetail],
      }
      await apiBookingRetail
        .getListBookingRetail_RBKRT1(body)
        .then(res => {
          if(res.Status == 200) {
            res.Data.BookingRetail.forEach(x => {
              x.label = x.Bdc + ' - ' + x.MemberName
            });
            this.listBookingRetail = res.Data.BookingRetail
            if(this.listBookingRetail.length > 0) {
              this.BookingRetailSelect = this.listBookingRetail[0]
              this.getDetailBookingRetail(this.BookingRetailSelect.Id)
            }
          }
        })
    },
    changeTypeSelect(data) {
      if(data.Type == 'ADD') {
        data.MemberSubscriptionFromDate = data.MemberSubscriptionFromDate ? data.MemberSubscriptionFromDate : this.getFirstDayOfYear()
        data.MemberSubscriptionToDate = data.MemberSubscriptionToDate ? data.MemberSubscriptionToDate : this.getLastDayOfYear()
      } else {
        data.MemberSubscriptionFromDate =  null
        data.MemberSubscriptionToDate =  null
      }
    },
    getFirstDayOfYear() {
      const year = this.dayjs().year()
      const timeZone = localStorage.getItem('timeZone')
      const offset = localStorage.getItem('timeZoneOffset')
      const date = this.dayjs(year + '-1-1').utc().tz(timeZone).startOf('day')
        .add(-(offset / 60), 'hour')
        .format('YYYY-MM-DDTHH:mm:ss')
      return `${date}Z`
    },
    getLastDayOfYear() {
      const year = this.dayjs().year() + 1
      const timeZone = localStorage.getItem('timeZone')
      const offset = localStorage.getItem('timeZoneOffset')
      const date = this.dayjs(year + '-1-1').utc().tz(timeZone).endOf('day')
        .add(-(offset / 60), 'hour')
        .add(-1, 'day')
        .format('YYYY-MM-DDTHH:mm:ss')
      return `${date}Z`
    },
    async getDetailBookingRetail(data) {
      this.listService = []
      const dataPost = {
        BookingRetail: {
          Id: data,
        },
      }
      await booking.getBookingRetail(dataPost).then(res => {
        if(res.Status == 200) {
          res.Data.BookingRetail.AddtionalService.forEach(x => {
            x.BookingRetailService.forEach(y => {
              y.check = false
              y.Type = "ADD"
              this.listService.push(y)
              this.changeTypeSelect(y)
            })
          })
        }
      })
    },
    showSubscriptionDetail() {
      this.resetData()
      if (!this.isCreate && this.data) {
        const memberCardID = 'MemberCardId' in this.data ? this.data.MemberCardId : null
        // const openDate = 'OpenDate' in this.data ? this.data.OpenDate : null
        // const endDate = 'EndDate' in this.data ? this.data.EndDate : null
        this.cardTypeSelected = this.getCardTypeDetail(memberCardID)
        // this.rangeDate = this.getContractPeriod(openDate, endDate)
      }
    },
    resetData() {
      this.tabIndex = 0
      // this.rangeDate = null
      this.file = null
      this.cardTypeSelected = null
      this.OpenDate =  this.dayjs(this.getUTC('start', 'day')).add(-30, 'day').format('YYYY-MM-DDTHH:mm:ss')
      this.EndDate = this.getUTC('start', 'day')
    },
    handleEvent(result) {
      switch (result.type) {
        case 'bans_success':
          // this.data.SubscriptionStatus = true
          this.data.DepositionFromDate = true
          this.data.DepositionToDate = true
          this.$forceUpdate()
          break
        case 'unbans_success':
          // this.data.SubscriptionStatus = false

          this.data.DepositionFromDate = null
          this.data.DepositionToDate = null

          this.$forceUpdate()
          break
        case 'action_success':
          this.hideModal()
          this.$emit('event', { type: 'action_success' })

          break
      }
    },
    unBans() {
      this.handleModal('un-bans-subscription')
    },
    hideModal() {
      this.$bvModal.hide('subscription-detail')
    },
    uploadContactFile(event) {
      if (this.data.ContractFile == null) {
        this.data.ContractFile = []
      }
      const { files } = event.target
      for (const file of files) {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = e => {
          const dataURI = e.target.result
          this.data.ContractFile.push({
            ContractFileName: file.name,
            Content: dataURI.replace(/^data:image\/(png|jpg|gif|jpeg);base64,/, ''),
          })
        }
      }
    },
    async updateSubscription_USU01() {
      const self = this
      self.$refs.simpleRules.validate().then(async success => {
        if (success) {
          self.$refs.simpleRules.reset()
          const fromData = self.formatDataBeforeSubmit()
          fromData.TransferTurn = Number(fromData.TransferTurn)
          const response = await members.updateSubscription({
            MemberSubscription: fromData,
          })

          await this.showSuccess(response, 'Cập nhật Subscription thành công')
        }
      })
    },
    async showSuccess(response, mess) {
      this.showResToast(response, mess)
      if (response.Status === '200') {
        this.$emit('event', { type: 'action_success' })
        this.hideModal()
      }
    },
    async createSubscription_ISU01() {
      const self = this
      self.$refs.simpleRules.validate().then(async success => {
        if (success) {
          self.$refs.simpleRules.reset()
          const fromData = self.formatDataBeforeSubmit()
          fromData.TransferTurn = Number(fromData.TransferTurn)
          const response = await members.createSubscription({ MemberSubscription: fromData })
          await this.showSuccess(response, 'Thêm Subscription thành công')
        }
      })
    },
    async transferSubscription() {
      this.handleModal('transfer-subscription')
    },
    async bansSubscription() {
      this.handleModal('bans-subscription')
    },
    handleModal(id) {
      this.$bvModal.show(id)
    },
    formatDataBeforeSubmit() {
      const fromDataClone = { ...this.data }
      // if (this.rangeDate) {
      //   const myDate = this.rangeDate.split(' to ')
      //   fromDataClone.OpenDate = dayjs(myDate[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
      //   fromDataClone.EndDate = dayjs(myDate[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
      // }

      if (this.cardTypeSelected) {
        fromDataClone.MemberCardId = this.cardTypeSelected.Id
      } else {
        fromDataClone.MemberCardId = null
      }
      if(fromDataClone.NumberOfPlays) {
        fromDataClone.NumberOfPlays = Number(this.data.NumberOfPlays)
      } else {
        fromDataClone.NumberOfPlays = null
      }
      fromDataClone.ContractValue = Number(this.data.ContractValue)
      return fromDataClone
    },
    deleteContract(index) {
      this.data.ContractFile.splice(index, 1)
    },
    getCardTypeDetail(cardID) {
      const cardTypeDetail = (this.memberCard || []).find(s => s.Id === cardID)
      return cardTypeDetail || null
    },
    /**
     * fromDate format 'YYYY-MM-DDTHH:mm:ss'
     * toDate format'YYYY-MM-DDTHH:mm:ss'
     * @return format 'DD/MM/YYYY to DD/MM/YYYY'
     */
    getContractPeriod(fromDate, toDate) {
      const dateFormatDMY = 'DD/MM/YYYY'
      return fromDate && toDate ? `${dayjs(fromDate).format(dateFormatDMY)} to ${dayjs(toDate).format(dateFormatDMY)}` : null
    },
    downloadContract(data) {
      const a = document.createElement('a')
      a.href = data.Content
      a.download = data.ContractFileName
      a.click()
    },
    search() {
      this.getHistoryMemberSubcriptionPayment_RMEH02()
    },
    async getHistoryMemberSubcriptionPayment_RMEH02() {
      const body = {
        StartDate: this.OpenDate,
        EndDate: this.EndDate,
        MemberSubscriptionId: this.data.Id,
      }
      await members.api_RMEH02(body).then(res => {
        this.dataHistory = res.Data.HistoryMember
      })
    },
    titleType(data) {
      if(data === 'UN_DESPOSITION') {
        return 'Bỏ truất quyền'
      } else if (data === 'DESPOSITION') {
        return 'Truất quyền'
      } else if (data === 'TRANSFER') {
        return 'Chuyển nhượng'
      } else if (data === 'PAYMENT') {
        return 'Phí thường niên'
      }
    },
    variantType(data) {
      if(data === 'UN_DESPOSITION') {
        return 'success'
      } else if (data === 'DESPOSITION') {
        return 'danger'
      } else if (data === 'TRANSFER') {
        return 'warning'
      } else if (data === 'PAYMENT') {
        return 'primary'
      }
    },
    subtitleType(data) {
      if(data.Type === 'UN_DESPOSITION') {
        return `Ngày bỏ truất quyền: ${this.convertUTC(data.Date,'DD/MM/YYYY')}`
      } else if (data.Type === 'DESPOSITION') {
        return `Truất quyền: ${this.convertUTC(data.FromDate,'DD/MM/YYYY')} - ${this.convertUTC(data.ToDate,'DD/MM/YYYY')}`
      } else if (data.Type === 'TRANSFER') {
        return `Người được chuyển nhượng: ${data.FullName}`
      } else if (data.Type === 'PAYMENT') {
        return `Tổng: ${this.formatPrice(data.Amount)}`
      }
    },
    subtitle2Type(data) {
      if(data.Type === 'UN_DESPOSITION') {
        return `Lý do: ${data.Note ? data.Note : ''}`
      } else if (data.Type === 'DESPOSITION') {
        return `Lý do: ${data.Note ? data.Note : ''}`
      } else if (data.Type === 'TRANSFER') {
        return `Ngày chuyển nhượng: ${this.convertUTC(data.Date,'DD/MM/YYYY')}`
      } else if (data.Type === 'PAYMENT') {
        return `Thời gian áp dụng: ${this.convertUTC(data.FromDate,'DD/MM/YYYY')} - ${this.convertUTC(data.ToDate,'DD/MM/YYYY')}`
      }
    },
    subtitle3Type(data) {
      if(data.Type === 'UN_DESPOSITION') {
        return `Người tạo: ${data.CreateByName}`
      } else if (data.Type === 'DESPOSITION') {
        return `Người tạo: ${data.CreateByName}`
      } else if (data.Type === 'TRANSFER') {
        return `Phí chuyển nhượng: ${this.formatPrice(data.Amount)}`
      } else if (data.Type === 'PAYMENT') {
        return `Người tạo: ${data.CreateByName}`
      }
    },
    subtitle4Type(data) {
      if(data.Type === 'UN_DESPOSITION') {
        return null
      } else if (data.Type === 'DESPOSITION') {
        return null
      } else if (data.Type === 'TRANSFER') {
        return `Ghi chú: ${data.Note ? data.Note : ''}`
      } else if (data.Type === 'PAYMENT') {
        return null
      }
    },
    subtitle5Type(data) {
      if(data.Type === 'UN_DESPOSITION') {
        return null
      } else if (data.Type === 'DESPOSITION') {
        return null
      } else if (data.Type === 'TRANSFER') {
        return `Người tạo: ${data.CreateByName}`
      } else if (data.Type === 'PAYMENT') {
        return null
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        if(this.isCreate) {
          this.createSubscription_ISU01()
        } else {
          this.updateSubscription_USU01()
        }
      }
    },
  },
}
</script>

<style lang="scss">
#subscription-detail {
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .modal-body, .modal-content, .modal-dialog {
    width: 750px;
  }

  .banned-background-color.detail {
    width: 100%;
    padding: 0 18px;
    height: 40px;
    background: linear-gradient(0deg, rgba(17, 74, 159, 0.12), rgba(17, 74, 159, 0.12)), #FFFFFF !important;
    border-radius: 6px 6px 0px 0px;
  }

  .title-modal {
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
    color: #114A9F;
  }

  .color-red {
    color: red;
  }
  .timeLineHistory {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
  }

  .text {
    font-size: 12px;
    line-height: 18px;
  }

  .custom_input {
    background-color: white;
  }

  ._button-no-bg {
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  .primary {
    border: 1px solid #114A9F;
    color: #114A9F;
  }

  .warning {
    border: 1px solid red;
    color: red;
  }

  .detail-subscription {
    height: 48px !important;

    svg {
      color: #114A9F;
    }
  }

  .inactive input, .inactive .custom_input, .inactive select, .inactive textarea {
    background-color: #efefef;
    pointer-events: none;
  }

  .history, .information, .annual_fee {
    padding: 16px;
    min-height: 420px;
  }

  .nav.nav-tabs {
    background: rgba(193, 193, 193, 0.12);
    padding: 0 18px;
    height: 44px;
    margin: 0;

    .nav-link {
      height: 44px;
      font-size: 12px;
      font-weight: 500;
    }

    .nav-link.active {
      color: rgba(17, 74, 159, 1);
    }

    .nav-link.active::after {
      background: rgba(17, 74, 159, 1) !important;
    }
  }

  .removeTabs ul.nav.nav-tabs {
    display: none !important;
  }

  .upload {
    border: 1px dashed #D6D9E1;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    height: auto;

    label {
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      color: #A7A9AC;
      line-height: 32px;

      span {
        color: #114A9F;
      }
      &:hover {
        cursor: pointer;
        span {
          font-weight: 500;
        }
      }
    }

    ul {
      padding: 0 10px;
      li {
        display: inline;
        border: 1px solid #D6D9E1;
        border-radius: 5px;
        font-size: 12px;
        line-height: 24px;
        color: #114A9F;
        padding: 5px 10px;
        margin-right: 10px;
        flex: none;
        order: 0;
        flex-grow: 1;
        list-style: none;
        span:hover, .delete-contract:hover {
          cursor: pointer;
        }
        .delete-contract:hover {
          font-weight: 700;
        }
      }
    }
  }
}
</style>

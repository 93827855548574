<template>
  <b-modal
    id="delete-subscription"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    :ok-title="$t('golf_common_confirm_delete')"
    :cancel-title="$t('golf_common_confirm_go_back')"
    ok-variant="danger"
    @ok="deleteAction()"
  >
    <b-card-header class="banned-background-color">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 pt-1 pb-1 title-modal color-red">
          {{ $t('golf_member_delete_subscription') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`red`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <p class="mb-0 pt-1 pb-1 text">
        {{ $t('golf_member_confirm_delete_subscription') }}
      </p>
    </b-card-body>
  </b-modal>
</template>

<script>
import { members } from '@/api/members'

export default {
  props: [
    'data',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('delete-subscription')
    },
    async deleteAction() {
      const response = await members.deleteSubscription({
        MemberSubscription: {
          Id: this.data.Id,
        },
      })
      this.showResToast(response)

      if (response.Status === '200') {
        this.$emit('event', { type: 'action_success' })
      }
    },
  },
}
</script>

<style lang="scss">
.card-header.banned-background-color {
  height: 48px !important;
  background: rgba(234, 84, 85, 0.12) !important;
  .color-red {
    color: #EA5455;
  }
  border-radius: 6px 6px 0px 0px;
  .title-modal {
    padding: 10px 0;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }
  .text {
      color: #5D5F65;
      font-size: 12px;
      line-height: 18px;
    }
}
.block-header {
  height: 48px !important;
}

.card-header {
  padding: 0 1.5rem;
}
</style>

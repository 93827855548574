import {
    ROOT,
    API_RBKRT1,
    API_ICIR03,
    API_DBKRT01,
    API_ICIR02,
    API_RHS03,
    API_UBKRT2
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'
function getHistoryRetail_RHS03(data) {
    return fetch(ROOT + API_RHS03, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_UBKRT2(data) {
    return fetch(ROOT + API_UBKRT2, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function getListBookingRetail_RBKRT1(data) {
    return fetch(ROOT + API_RBKRT1, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function checkOutRetail_ICIR02(data) {
    return fetch(ROOT + API_ICIR02, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_ICIR03(data) {
    return fetch(ROOT + API_ICIR03, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_DBKRT01(data) {
    return fetch(ROOT + API_DBKRT01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
export const apiBookingRetail = {
    getListBookingRetail_RBKRT1,
    api_ICIR03,
    api_DBKRT01,
    checkOutRetail_ICIR02,
    getHistoryRetail_RHS03,
    api_UBKRT2
}

<template>
  <div class="history-playing">
    <b-container>
      <b-row style="height: 72px">
        <b-col
          cols="9"
          class="d-flex align-items-center"
        >
          <div class="item">
            {{ $t('golf_member_member_all') }}
            <span style="background: #114A9F">{{ countTotalNav() }}</span>
          </div>
          <div
            v-for="item in navList"
            :key="item.Title"
            class="item"
          >
            {{ item.Title }}
            <span style="background: #29B869">{{ item.Value }}</span>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="text-right my-auto"
        >
          <div
            class="_button-no-bg text-nowrap d-inline-flex align-items-center"
            @click="btnBuyPackage_Click"
          >
            <plus-circle-icon
              size="1.5x"
              class="mr-1"
            />
            <span>{{ $t('golf_member_member_buy_combo') }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="p-0"
        >
          <b-table
            :hover="true"
            :sticky-header="true"
            :items="items"
            :fields="fieldsPackageTable"
            :tbody-tr-class="rowClass"
            responsive="sm"
            @row-clicked="package_onRowClick"
          >
            <!-- Tuỳ chỉnh hiển thị tên gói -->
            <template #cell(PackageName)="{item}">
              <div style="max-width: 120px; word-wrap: break-word;">
                {{ item.PackageName }}
              </div>
            </template>
            <!-- Tuỳ chỉnh hiển thị số lượt chơi -->
            <template #cell(PlayNumber)="{item}">
              <div
                class="text-right pr-3"
                style="max-width: 120px; word-wrap: break-word;"
              >
                {{ item.PlayNumber }}
              </div>
            </template>
            <!-- Tuỳ chỉnh hiển thị số ngày hết hạn -->
            <template #cell(ExpirationDate)="{item}">
              <div style="max-width: 120px; word-wrap: break-word;">
                {{ _dayjs(item.ExpirationDate).format('DD/MM/YYYY') }}
              </div>
            </template>
            <!-- tuỳ chỉnh thao tác user -->
            <template #cell(actions)>
              <div class="d-flex align-items-center">
                <b-dropdown
                  no-caret
                  variant="link"
                  toggle-class="text-decoration-none"
                  class="table-dropdown"
                >
                  <template #button-content>
                    <more-vertical-icon
                      class="action-icon"
                      size="1.5x"
                    />
                  </template>
                  <b-dropdown-item-button @click="showDetailPackage">
                    {{ $t('golf_common_detail') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button>
                    {{ $t('golf_member_print_code') }}
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="p-10"
        >
          <div class="footer-wrapper d-flex align-items-center justify-content-between">
            <span>Hiển thị {{ items.length }} trên tổng số {{ items.length }} kết quả</span>
            <!--<b-pagination class="m-0" v-model="currentPage" pills :total-rows="rows"></b-pagination>-->
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-sidebar
      id="sidebar-detail-package"
      v-model="isOpenSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="780px"
    >
      <template #default="{ hide }">
        <div
          class="
            header-sidebar
            p-1
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <h4 class="mb-0 text-primary text-truncate">
            {{ $t("golf_package_master_detail") }} {{ currentPackageName }}
          </h4>
          <div>
            <ez-icon
              icon="ezGolf-icon-x"
              size="24"
              class="cursor-pointer"
              color="#114A9F"
              @click="hide"
            />
          </div>
        </div>
        <ContentDetailPackage
          v-if="currentPackage"
          :key="currentPackage"
          :current-package="currentPackage"
          :list-golf-class="listGolfClass"
          :list-company="listCompany"
          :list-course="listCourse"
          :list-transaction="listTransaction"
          :is-detail="isDetail"
          ref="contentDetailPackage"    
        />
      </template>
    </b-sidebar>
    <ComboDetail />
    <AddGolfServiceRetail :member-detail="memberDetailAddServiceRetail" />
  </div>
</template>

<script>
import { members } from '@/api/members'
import { packageSetting } from '@/api/package-setting'
import { commonServices } from '@/api/common-services'
import { courseSetting } from '@/api/course-setting'
import { golfClassSetting } from '@/api/golf-class-setting'
import { PlusCircleIcon, MoreVerticalIcon } from 'vue-feather-icons'
import dayjs from 'dayjs'
import AddGolfServiceRetail from '@/views/modules/ultils/AddGolfServiceRetail/AddGolfServiceRetail.vue'
import ComboDetail from '../modals/ComboDetail.vue'
import ContentDetailPackage from '@/views/modules/package-setting/ContentDetailPackage/ContentDetailPackage.vue'
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)
export default {
  name: 'Combo',
  components: {
    ComboDetail,
    PlusCircleIcon,
    MoreVerticalIcon,
    AddGolfServiceRetail,
    ContentDetailPackage
  },
  props: {
    memberInfo: {
      type: Object,
      default() {
        return {
          Id: null,
        }
      },
    },
    golfClass: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      navList: [],
      memberDetailAddServiceRetail: {},
      isOpenSidebar : false,
      currentPackage : null,
      currentPackageStatus : null,
      currentPackageName : null,
      listCourse: [],
      listCompany: [],
      listGolfClass: [],
      listTransaction: [],
      isDetail: true,
    }
  },
  created() {
    this.init_RRA01_AC1()
  },
  computed: {
    fieldsPackageTable() {
      return [
        { key: 'PackageId', label: this.$t('golf_member_package_group') },
        { key: 'PackageName', label: this.$t('golf_member_package_name_package_group') },
        { key: 'PlayNumber', label: this.$t('golf_member_play_number') },
        { key: 'ExpirationDate', label: this.$t('golf_member_expiration_date') },
        { key: '', label: this.$t('golf_member_beneficiaries') },
        { key: 'actions', label: '' },
      ]
    },
  },
  watch: {
    memberInfo: {
      immediate: true,
      handler() {
        this.getListCombo_RME04()
      },
    },
  },
  methods: {
    async getListCombo_RME04() {
      const requestData = {
        Member: {
          Id: this.memberInfo.Id,
        },
      }

      const response = await members.getListCombo(requestData)
      if (response.Status === '200') {
        this.items = response?.Data?.MemberPackage
        this.navList = response?.Data?.PackageStatusStatistic
      }
    },
    _dayjs: dayjs,
    showDetailPackage() {
      this.$bvModal.show('combo-detail')
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.InActive === true) {
        return 'isOver'
      }
    },
    countTotalNav() {
      let total = 0
      if (this.navList) {
        this.navList.forEach(item => {
          total += item.Value
        })
      }

      return total
    },
    btnBuyPackage_Click() {
      this.memberDetailAddServiceRetail = { ...this.memberInfo }
      this.$root.$emit('bv::toggle::collapse', 'add-service-item-retail')
    },
    package_onRowClick(item) {
      this.isOpenSidebar = true
      this.currentPackage = item.PackageId
      this.currentPackageStatus = item.PackageStatus
      this.currentPackageName = item.PackageName
    },
        async init_RRA01_AC1() {
      this.isLoading = true
      // Lấy list sân
      courseSetting
        .getListCourse()
        .then(res => {
          if (res.Status === '200') {
            this.listCourse = res.Data.Course
          } else {
            this.showResToast(res)
          }
        })

      // Lấy list package

      // Lấy danh sách golf class
      const body = {
        KeyWord: null,
        CourseId: [],
      }
      await golfClassSetting
        .getListGolfClass(body)
        .then(res => {
          if (res.Status === '200') {
            this.listGolfClass = res.Data.GolfClass
          } else {
            this.showResToast(res)
          }
        })

      // Lấy danh sách company
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listCompany = res.Data.Client
          } else {
            this.showResToast(res)
          }
        })

      // Lấy danh sách đơn vị tiền tệ
      // await commonServices
      //   .getCurrency()
      //   .then(res => {
      //     if (res.Status === '200') {
      //       this.listCurrency = res.Data.CurrencyCode
      //     }
      //   })
      const bodyTrans = {
        hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      }
      // Lấy danh sách transaction
      await commonServices
        .getListTransactionCode(bodyTrans)
        .then(res => {
          if (res.Status === '200') {
            this.listTransaction = res.Data.TransactionCode
          } else {
            this.showResToast(res)
          }
        })

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
.history-playing {
  ._button-no-bg {
    border-radius: 5px;
    border: 1px solid #114A9F;
    padding: 5px 10px;
    color: #114A9F;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #5d5f65;
    font-weight: 500;

    span {
      margin-left: 8px;
      margin-right: 22px;
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      justify-content: center;
      height: 20px;
      min-width: 20px;
      padding: 0 6px;
      border-radius: 4px;
      color: #FFFFFF;
    }
  }

  .item:first-child {
    margin-left: 16px;
  }
}
</style>

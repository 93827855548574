import {
  ROOT,
  API_RBK08,
  API_RBKD04,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListDetailBooking(data) {
  return fetch(ROOT + API_RBK08, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getListDetailBookingDr(data) {
  return fetch(ROOT + API_RBKD04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const bookingPrint = {
  getListDetailBooking,
  getListDetailBookingDr,
}

<template>
  <div>
    <b-modal
      id="modal-split-group"
      size="md"
      centered
      hide-header
      ok-only
      :ok-title="$t('golf_common_confirm')"
      ok-variant="primary"
      @ok="splitGroup_IBK01A"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0 text-primary">
            {{ $t('golf_booking_split_group') }}
          </h4>
          <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer"
            size="24"
            color="#114A9F"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <p class="mb-0">
          {{ $t('golf_booking_confirm_split_group') }}
        </p>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { booking } from '@/api/booking'

export default {
  components: {},
  props: [
    'items', 'BookingDetailId',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-split-group')
    },
    async splitGroup_IBK01A(bvModalEvent) {
      bvModalEvent.preventDefault()
      let BookingDetail = []
      if (this.items.length) {
        this.items.forEach(x => {
          BookingDetail.push({
            Id: x.Id,
          })
        })
      } else {
        // from detail
        BookingDetail = [
          {
            Id: this.BookingDetailId,
          },
        ]
      }

      const dataRequest = {
        BookingDetail,
      }

      const response = await booking.SplitGroup(dataRequest)
      this.showResToast(response)
      if (response.Status == '200') {
        this.$emit('event', { type: 'after-split-group' })
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-split-group')
      })
    },
  },
}

</script>

<style lang="scss" scoped>

</style>

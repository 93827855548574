import vSelect from 'vue-select'
import Decimal from 'decimal.js'
import { booking } from '@/api/booking'
import { members } from '@/api/members'
import PaymentType from '@/views/modules/booking/PaymentType.vue'
import Cleave from 'vue-cleave-component'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import SelectDebitAccount from '@/views/modules/ultils/SelectDebitAccount'
import { apiBookingRetail } from '@/api/booking-retail'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, R, P, S,
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { commonServices } from '@/api/common-services'

const PaymentTypeCode = {
  Payment: 'PAY',
  Refund: 'REFUND',
}

const PaymentMethodGroup = {
  AR: 'AR',
  BANKTRANSFER: 'BANKTRANSFER',
  CASH: 'CASH',
  CREDIT: 'CREDIT',
  EZTICKET: 'EZTICKET',
  EZGOLF: 'EZGOLF',
  OTHER: 'OTHER',
  PREPAIDCARD: 'PREPAIDCARD',
  QRCODE: 'QRCODE',
  ROOM: 'ROOM',
  VIP: 'VIP',
  PAYMENT_RECORDED_FOR_FO: 'PAYMENT_RECORDED_FOR_FO',
  PAYMENT_RECORDED_FOR_BA: 'PAYMENT_RECORDED_FOR_BA',
}

const paymentRetailBase = {
  Id: null,
  PaymentRetailCode: '',
  PaymentRetailDate: '',
  FiscalPaymentRetailDate: '',
  MemberId: null,
  TotalAmount: 0,
  ReceivedAmount: 0,
  PaymentRetailDetail: [],
}

const formBase = {
  PaymentMethodId: null,
  PaymentTypeCode: null,
  Note: '',
  Amount: 0,
  CurrencyCode: '',
  CardNumber: '',
  DebitAccountId: null,
  ClientId: null,
  ExpirationDate: null,
  Bdc: null,
  RoomCode: null,
  GuestID: null,
  FolioNum: null,
  VoucherCode: null,
}

export default {
  components: {
    PaymentType,
    SelectDebitAccount,
    vSelect,
    Cleave,
    ShortKey,
    ConfirmDialog,
  },
  props: {
    paymentRetailData: {
      type: Object,
      validator(value) {
        return 'Id' in value
      },
      default() {
        return {
          Id: null,
        }
      },
    },
    BDCOfBookingDetailId: {
      type: String
    },
    zoneId: {
      type: Number,
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      PaymentMethods: [],
      PaymentTypeData: [],
      form: { ...formBase },
      fields: [
        {
          key: 'type',
          label: 'ID',
          thStyle: { width: '80%', display: 'none' },
          tdClass: 'e-td',
        },
        {
          key: 'total',
          label: 'ID',
          thStyle: { width: '20%', display: 'none' },
          tdClass: 'e-td',
        },
      ],
      paymentType: '',
      canShowPaymentType: false,
      paymentRetail: { ...paymentRetailBase },
      paymentAmountInputExchange: 0,
      debitAccountSelected: {
        Id: null,
        ClientName: '',
      },
      maskExpirationDate: {
        date: true,
        datePattern: ['m', 'y'],
        delimiter: '/',
      },
      maskCreditCard: {
        creditCard: true,
        delimiter: ' ',
      },

      paymentTypeOptions: [],
      listPaymentType: [],
      // zoneId: [],
      paymentTypeName: null,
      TransactionCode: null,
      isLoading: true,
      listUserRoom: [],
      disableAddNew: false,
      findMemberByBDC: null,
      voucherDetail: null,
      isLoadingCheckVoucher: false,
      isOpen: false,
      allChildComponentClosed: [],
      ListPaymentMethodShortCutKey: null,
      listShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      pLang: null,
      pToken: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      pIndex: 0,
      pIsGetAll: true,
      pUrl: null,
      fileName: null,
      EzgoflPaymentMethodId : null,
      quickPublicInvoice: false,
      PaymentMethodByEvoucherInTransactionCode: null,
      PaymentMethodByVoucherInTransactionCode: null
    }
  },
  created() {
    this.getParam()
    this.getConfigInfo_RCOM01_PaymentMethodByVoucher()
    this.getListPaymentMethodShortCutKey()
    // this.getConfigInfo_RCOM01()
  },
  computed: {
    // paymentTypeOptions() {
    //   return [
    //     { title: this.$t('golf_common_payment'), value: PaymentTypeCode.Payment },
    //     { title: this.$t('golf_common_refund'), value: PaymentTypeCode.Refund },
    //   ]
    // },
    LS_Currency() {
      const userData = JSON.parse(localStorage.getItem('userData')) || {}
      return userData?.currency || []
    },
    baseCurrency() {
      const baseCurrency = this.LS_Currency.find(s => s.IsBase || false)
      return baseCurrency || {
        CurrencyCode: '',
        NoOfDec: 0,
      }
    },
    baseCurrencyCode() {
      return 'CurrencyCode' in this.baseCurrency ? this.baseCurrency.CurrencyCode : ''
    },
    currencyCodeSelected() {
      return this.form.CurrencyCode || ''
    },
    currencySelected() {
      const currencySelectedDetail = this.LS_Currency.find(s => s.CurrencyCode === this.currencyCodeSelected)
      if (typeof currencySelectedDetail !== 'undefined') {
        return {
          DivBase: currencySelectedDetail.DivBase || false,
          ExRate: currencySelectedDetail.ExRate || 1,
          NoOfDec: currencySelectedDetail.NoOfDec || 0,
        }
      }
      return {
        CurrencyCode: this.currencyCodeSelected,
        DivBase: false,
        ExRate: 1,
        NoOfDec: 0,
      }
    },
    totalAmountPaymentBaseCurr() {
      if (this.paymentRetail) {
        return this.paymentRetail.TotalAmount || 0
      }
      return 0
    },
    totalAmountPaymentExchange() {
      if (this.currencyCodeSelected === this.baseCurrencyCode) {
        return this.totalAmountPaymentBaseCurr
      }
      return this.GFn_GetExchangeMoney(this.totalAmountPaymentBaseCurr, this.currencySelected)
    },
    amountRemainPayment() {
      if (this.paymentRetail) {
        const receivedAmount = this.paymentRetail.ReceivedAmount || 0
        const balance = this.totalAmountPaymentBaseCurr - receivedAmount
        return balance >= 0 ? balance : 0
      }
      return 0
    },
    amountRemainPaymentExchange() {
      if (this.currencyCodeSelected === this.baseCurrencyCode) {
        return this.amountRemainPayment
      }
      return this.GFn_GetExchangeMoney(this.amountRemainPayment, this.currencySelected)
    },
    isPaymentDiffBase() {
      return this.currencyCodeSelected !== this.baseCurrencyCode
    },
    showMoneyExchange() {
      return this.isPaymentDiffBase
    },
    // Format Display
    amountRemainPaymentDisplay() {
      return `${this.formatPrice(this.amountRemainPayment)} ${this.baseCurrencyCode}`
    },
    totalAmountPaymentBaseCurrDisplay() {
      return `${this.formatPrice(this.totalAmountPaymentBaseCurr)} ${this.baseCurrencyCode}`
    },
    totalAmountPaymentExchangeDisplay() {
      return `${this.formatPrice(this.totalAmountPaymentExchange)} ${this.currencyCodeSelected}`
    },
    totalAmountPaymentInputExchangeDisplay() {
      return `${this.formatPrice(this.paymentAmountInputExchange)} ${this.baseCurrencyCode}`
    },
    showFormInputPaymentMethod() {
      return {
        AR: this.paymentType === PaymentMethodGroup.AR,
        BANKTRANSFER: this.paymentType === PaymentMethodGroup.BANKTRANSFER,
        CASH: this.paymentType === PaymentMethodGroup.CASH,
        CREDIT: this.paymentType === PaymentMethodGroup.CREDIT,
        EZTICKET: this.paymentType === PaymentMethodGroup.EZTICKET,
        EZGOLF: this.paymentType === PaymentMethodGroup.EZGOLF,
        OTHER: this.paymentType === PaymentMethodGroup.OTHER,
        PREPAIDCARD: this.paymentType === PaymentMethodGroup.PREPAIDCARD,
        QRCODE: this.paymentType === PaymentMethodGroup.QRCODE,
        ROOM: this.paymentType === PaymentMethodGroup.ROOM,
        VIP: this.paymentType === PaymentMethodGroup.VIP,
        PAYMENT_RECORDED_FOR_FO: this.paymentType === PaymentMethodGroup.PAYMENT_RECORDED_FOR_FO,
        PAYMENT_RECORDED_FOR_BA: this.paymentType === PaymentMethodGroup.PAYMENT_RECORDED_FOR_BA,
      }
    },
  },
  watch: {
    'form.GuestID': function (val) {
      if (val) {
        this.form.FolioNum = this.listUserRoom.find(x => x.GuestID == val).FolioNum
      } else {
        this.form.FolioNum = null
      }
    },
    isOpen(val) {
      if(!val) {
        this.$emit('event', { type: 'after-close-modal-payment'})
      }
      this.$emit('is-open', { ComponentId: 'modal-payment', IsActive: val })
    }
  },
  methods: {
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_RETAIL_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY_RT')
        return this.call_api_RPF02()
      }
    },
    async getConfigInfo_RCOM01_PaymentMethodByVoucher() {
      const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      if (response.Status === '200') {
        this.PaymentMethodByEvoucherInTransactionCode = response.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_BY_EVOUCHER_IN_TRANSACTIONCODE').KeyValue
        this.PaymentMethodByVoucherInTransactionCode = response.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_BY_VOUCHER_IN_TRANSACTIONCODE').KeyValue
      }
    },
    async call_api_RPF02() {
      if (this.zoneId) {
        await booking.api_RPF02({
          ZoneId: [this.zoneId.toString()], // this.zoneId.map(item => String(item.KeyValue)),
        }).then(async response => {
          if (response.Status === '200') {
            this.listPaymentType = response?.Data?.PaymentType
            this.paymentTypeOptions = response?.Data?.PaymentType.map(x => ({
              title: x.Name,
              value: x.Code,
            }))
          }
        })
      }
    },
    async openForm() {
      this.isLoading = true
      await this.getDefaultMethodConfig()
      await this.call_api_RPF02()
      this.resetFormData()
      // this.form.PaymentTypeCode = this.paymentTypeOptions[0].value
      this.form.CurrencyCode = this.baseCurrencyCode
      await this.getListPaymentAdded(this.paymentRetailData.Id)
      // await this.getPaymentMethod()

      this.form.PaymentTypeCode = this.paymentTypeOptions[0].value
      if(this.BDCOfBookingDetailId) {
        const paymentTypeGolfDefault =  this.listPaymentType[0].PaymentMethod.find(x => x.Id == this.EzgoflPaymentMethodId)
        this.handleChoicePayment(this.form.PaymentTypeCode,paymentTypeGolfDefault, paymentTypeGolfDefault.TransactionCode[0])
        this.form.Bdc = this.BDCOfBookingDetailId
        this.checkBDC(this.form.Bdc)
      } else {
        this.handleChoicePayment(this.form.PaymentTypeCode, this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
      }
      this.isLoading = false
      this.disableAddNew = false
    },
    async getDefaultMethodConfig() {
      const body = {
        KeyGroup: 'PAYMENT_CONFIG',
      }
      await commonServices.getCommon(body).then(res => {
        this.EzgoflPaymentMethodId = res.Data.find(x => x.KeyCode === 'PAYMENT_METHOD_TO_GOLF_CODE').KeyValue
      })
    },
    resetFormData() {
      this.form = { ...formBase }
      this.paymentRetail = { ...paymentRetailBase }
      this.paymentAmountExchange = 0
      this.PaymentTypeData = []
      this.listUserRoom = []
      this.voucherDetail = null
      this.findMemberByBDC = null
    },
    onSelectPaymentType(ev) {
      if (ev.value === PaymentTypeCode.Payment) {
        if (this.isPaymentDiffBase) {
          if (this.form.Amount > this.amountRemainPaymentExchange) {
            if (this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
              this.form.Amount = this.amountRemainPaymentExchange
            }
          }
          this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
        } else if (this.form.Amount > this.amountRemainPayment && this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
          this.form.Amount = this.amountRemainPayment
        }
      }
    },
    onSelectCurrency(ev) {
      this.paymentAmountInputExchange = this.amountRemainPayment
      this.form.Amount = this.amountRemainPaymentExchange
    },
    onChangeAmount(ev) {
      if (ev < 0) {
        this.form.Amount = 0
      }
      if (this.isPaymentDiffBase) {
        if (ev > this.amountRemainPaymentExchange) {
          if (this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
            this.form.Amount = this.amountRemainPaymentExchange
          }
        }
        this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
      } else if (ev > this.amountRemainPayment && this.form.PaymentTypeCode === PaymentTypeCode.Payment) {
        this.form.Amount = this.amountRemainPayment
      }
    },
    close() {
      this.$emit('closePopup', true)
    },
    handleEvent() {
    },
    hideModal() {
      this.$bvModal.hide('modal-payment-retail')
    },
    async getPaymentMethod() {
      await booking.getListPaymentMethod({}).then(response => {
        if (response.Status === '200') {
          this.PaymentMethods = response?.Data?.PaymentMethodGroup
          const paymentMethodDefault = this.getPaymentMethodDefault(this.PaymentMethods)
          if (paymentMethodDefault != null) {
            this.paymentType = paymentMethodDefault.Code
            this.form.PaymentMethodId = paymentMethodDefault.Id
          }
          // tach ra lam bo 3
          let count = 0
          const fields = []
          let fieldtmp = []
          let datatmp = []
          let { length } = this.PaymentMethods
          for (const payment of this.PaymentMethods) {
            const tmp = {}
            tmp.key = payment.Name
            tmp.label = payment.Name
            tmp.name = 'Code'
            payment[`${payment.Name}`] = payment.Name
            datatmp.push(payment)
            fieldtmp.push(tmp)
            count++
            length--
            if (count == 3) {
              fields.push({ fields: fieldtmp, data: datatmp })
              fieldtmp = []
              datatmp = []
              count = 0
            }
            if (length == 0) {
              fields.push(fieldtmp)
            }
          }
          this.PaymentTypeData = fields
        }
      })
    },
    async getListPaymentAdded(paymentRetailId) {
      const payment = {
        PaymentRetail: {
          Id: paymentRetailId,
        },
      }
      await members.getPaymentRetail(payment).then(async response => {
        if (response.Status === '200') {
          this.paymentRetail = response?.Data?.PaymentRetail
          if(this.quickPublicInvoice === true && this.paymentRetail.PaymentRetailStatus == "FULL_PAY") {
            this.$refs.confirmDialogComponent.open({
              Id: this.paymentRetail.Id,
              dataConfirm: {
                title: 'Phát hành hóa đơn và check out',
                content: 'Bạn có chắc chắn phát hành hóa đơn và check out không?',
              }
            })
            this.quickPublicInvoice = false
          }
          this.form.Amount = this.amountRemainPaymentExchange || 0
          if (this.isPaymentDiffBase) {
            this.paymentAmountInputExchange = this.GFn_GetBaseMoneyFromExchange(this.form.Amount, this.currencySelected)
          }
        }
      })
    },
    async checkOutBookingRetail(isCheck) {
      const body = {
        BookingRetailId: this.paymentRetail.BookingRetailId
      }
      const res =  await apiBookingRetail.checkOutRetail_ICIR02(body)
      this.showResToast(res)
      if(isCheck) {
        this.getInoicePdf()
      }
    },
    getPaymentMethodDefault(paymentMethods) {
      let paymentMethodDefault = null
      paymentMethods.forEach(s => {
        const paymentMethodList = s.PaymentMethod || []
        paymentMethodList.forEach(s1 => {
          if (s1.Code === PaymentMethodGroup.CASH) {
            paymentMethodDefault = {
              Id: s1.Id,
              Code: s1.Code,
            }
          }
        })
      })
      return paymentMethodDefault
    },
    calTotalAmount() {
      const totalAmount = this.paymentRetail.TotalAmount || 0
      const receivedAmount = this.paymentRetail.ReceivedAmount || 0
      const balance = totalAmount - receivedAmount
      const total = balance >= 0 ? balance : 0
      if (this.paymentRetail) {
        return `${this.formatPrice(total)} ${this.baseCurrencyCode}`
      }
      return `0 ${this.baseCurrencyCode}`
    },
    async addPaymentDetail() {
      this.disableAddNew = true
      const paymentRetailId = this.paymentRetailData.Id
      const amount = parseFloat(this.form.Amount || '0')
      const paymentData = {
        PaymentRetailId: paymentRetailId,
        PaymentMethodId: parseInt(this.form.PaymentMethodId, 10),
        PaymentRetailTypeCode: this.form.PaymentTypeCode,
        Amount: amount,

        HotelCode: JSON.parse(localStorage.getItem('currentSiteId')),
        RoomCode: this.form.RoomCode,
        GuestId: (this.form.GuestID) ? this.form.GuestID.toString() : null,
        GuestName: (this.form.GuestID) ? this.listUserRoom.find(x => x.GuestID == this.form.GuestID).GuestName : null,
        FolioNumber: this.form.FolioNum,

        VoucherCode: this.form.VoucherCode,

        Note: this.form.Note,
        CurrencyCode: this.form.CurrencyCode,
        CardNumber: this.form.CardNumber,
        ClientId: this.form.ClientId,
        DebitAccountId: this.form.DebitAccountId,
        Bdc: this.form.Bdc,
        CashierCode: '',
        ExpirationDate: this.createFullDataExpirationDate(this.form.ExpirationDate),
        TransactionCode: this.TransactionCode,
      }
      const dataPost = {
        PaymentRetailDetail: {
          ...paymentData,
        },
      }
      let isSubmit = true
      if (!paymentData.PaymentRetailTypeCode) {
        this.$toast.error('golf_common_please_select_payment_type')
        isSubmit = false
      }
      // if (amount < 0 || amount === 0) {
      //   isSubmit = false
      //   this.$toast.error('golf_common_amount_invalid')
      // }
      if (amount < 0 ) {
        isSubmit = false
        this.$toast.error('golf_common_amount_invalid')
      }
      if (!paymentData.CurrencyCode) {
        isSubmit = false
        this.$toast.error('golf_common_please_select_a_currency')
      }
      if (!this.paymentType || this.form.PaymentMethodId === null) {
        this.$toast.error('golf_common_please_choose_a_form_of_payment')
        isSubmit = false
      }
      if (this.paymentType === PaymentMethodGroup.AR) {
        if (!this.form.DebitAccountId) {
          this.$toast.error('golf_common_please_choose_debit_account')
          isSubmit = false
        }
      }
      if (this.paymentType === PaymentMethodGroup.CREDIT) {
        // if (!this.form.CardNumber) {
        //   this.$toast.error('golf_common_please_enter_card_number')
        //   isSubmit = false
        // }
        // if (!this.form.ExpirationDate) {
        //   this.$toast.error('golf_common_please_enter_expiration_date')
        //   isSubmit = false
        // }
      }

      if (this.paymentType === PaymentMethodGroup.OTHER && this.PaymentMethodByEvoucherInTransactionCode === this.TransactionCode || this.PaymentMethodByVoucherInTransactionCode === this.TransactionCode) {
        if (!this.form.VoucherCode) {
          this.showToast('error', this.$t('golf_common_please_enter_voucher'))
          isSubmit = false
        }
        if (this.form.VoucherCode && !this.voucherDetail) {
          this.showToast('error', this.$t('golf_common_voucher_invalid'))
          isSubmit = false
        }
      }

      if (!isSubmit) {
        setTimeout(() => {
          this.disableAddNew = false
        }, 1000)
        return
      }
      await members.addPaymentRetail(dataPost).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.quickPublicInvoice = true
          this.getListPaymentAdded(paymentRetailId)
        }
      })
      setTimeout(() => {
        this.disableAddNew = false
      }, 1000)
    },
    openModalDeletePaymentDetail(id) {
      this.$emit('event', { type: 'open_modal_delete_payment_detail', deletePaymentRetail: id })
    },
    handlePaymentType(type) {
      const isShow = type === 'show'
      this.canShowPaymentType = isShow
    },
    handleChoicePayment(item, payment, trans) {
      this.form.PaymentTypeCode = item
      this.paymentType = payment.Code
      this.form.PaymentMethodId = payment.Id
      this.paymentTypeName = `${payment.Name} - ${trans.Description}`
      this.TransactionCode = trans.TransactionCode
      this.canShowPaymentType = false
      if (this.paymentType !== PaymentMethodGroup.CREDIT) {
        this.form.ExpirationDate = null
        this.form.CardNumber = ''
      }

      if (this.paymentType !== PaymentMethodGroup.PAYMENT_RECORDED_FOR_FO) {
        this.form.GuestID = null
        this.form.RoomCode = null
        this.form.GuestName = null
        this.form.HotelCode = null
      }
      if (this.paymentType !== PaymentMethodGroup.PAYMENT_RECORDED_FOR_BA) {
        this.form.GuestID = null
        this.form.RoomCode = null
        this.form.GuestName = null
        this.form.HotelCode = null
      }

      if (this.paymentType !== PaymentMethodGroup.AR) {
        this.form.ClientId = null
        this.form.DebitAccountId = null
      }
    },
    getPaymentMethodNameById(id) {
      let name = id
      this.PaymentMethods.forEach(x => {
        x.PaymentMethod.forEach(y => {
          if (y.Id === id) {
            name = y.Name
          }
        })
      })
      return name
    },
    openSelectARAccount() {
      this.$root.$emit('bv::show::modal', 'modal-select-ar')
    },
    selectArAccount(arAccountData) {
      Object.keys(this.debitAccountSelected).forEach(arKey => {
        if (arKey in arAccountData) {
          this.debitAccountSelected[arKey] = arAccountData[arKey]
        }
      })
      this.form.DebitAccountId = this.debitAccountSelected.Id
      this.form.ClientId = this.debitAccountSelected.Id
    },
    createFullDataExpirationDate(shortExpirationDate) {
      if (shortExpirationDate) {
        const [month, yearOld] = shortExpirationDate.split('/')
        const year = this.dayjs(`${month}/01/${yearOld}`).format('YYYY')
        return new Date(year, month, 0, 23, 59, 59)
      }
      return null
    },
    GFn_GetExchangeMoney(baseMoney, {
      DivBase,
      ExRate,
      NoOfDec,
    }) {
      const baseMoneyToDecimal = new Decimal(baseMoney || 0)
      let exchangeMoney = 0
      const noOfDec = NoOfDec || 0
      const exRate = ExRate || 1
      if (DivBase || false) {
        exchangeMoney = Decimal.div(baseMoneyToDecimal, exRate).toFixed(noOfDec)
      } else {
        exchangeMoney = Decimal.mul(baseMoneyToDecimal, exRate).toFixed(noOfDec)
      }
      return new Decimal(exchangeMoney).mul(1).toNumber()
    },
    GFn_GetBaseMoneyFromExchange(exchangeMoney, {
      DivBase,
      ExRate,
    }) {
      const exchangeMoneyToDecimal = new Decimal(exchangeMoney || 0)
      let baseMoney = 0
      const noOfDec = this.baseCurrency.NoOfDec || 0
      const exRate = ExRate || 1
      if (DivBase || false) {
        baseMoney = Decimal.mul(exchangeMoneyToDecimal, exRate).toFixed(noOfDec)
      } else {
        baseMoney = Decimal.div(exchangeMoneyToDecimal, exRate).toFixed(noOfDec)
      }
      return new Decimal(baseMoney).mul(1).toNumber()
    },
    OpenInvoice() {
      const paymentRetailId = this.paymentRetailData.Id
      const routeData = this.$router.resolve({ name: 'retailInvoice', params: { id: paymentRetailId } })
      window.open(routeData.href, '_blank')
    },
    async getDetailRoom_RPM02M(RoomCode) {
      this.form.GuestID = null
      await booking.api_RPM02M({ RoomNumber: RoomCode }).then(response => {
        if (response.Status === '200') {
          this.listUserRoom = response.Data.Guests
          if (this.listUserRoom.length) {
            this.form.GuestID = this.listUserRoom[0].GuestID
          }
        } else {
          this.showResToast(response)
        }
      })
    },
    async validateVoucher_VC_RV05(voucher) {
      if (voucher) {
        this.isLoadingCheckVoucher = true
        await booking.api_VC_RV05({ VoucherCode: voucher, PaymentRetailId: this.paymentRetailData.Id }).then(response => {
          if (response.Status === '200') {
            this.voucherDetail = response.Data
            this.form.Amount = response.Data.TotalAmount
          } else {
            this.voucherDetail = null
            this.form.Amount = 0
            this.showResToast(response)
          }
        })
        this.isLoadingCheckVoucher = false
      } else {
        this.showToast('error', this.$t('golf_common_voucher_invalid'))
      }
    },
    async retryPaymentForRoom_IPM02R(PaymentDetailId) {
      await booking.api_IPM02R({ PaymentDetailId }).then(response => {
        this.showResToast(response)
        if (response.Status == '200') {
          this.getDetailPayment_RPM04(this.paymentId)
          this.$emit('event', { type: 'after-add-new-payment-detail', response })
        }
      })
    },
    getDetailPayment() {
      this.getListPaymentAdded(this.paymentRetailData.Id)
    },
    async checkBDC(BDC) {
      const body = {
        BDC,
      }
      await booking.api_RME01F(body).then(res => {
        if (res.Data.Member) {
          this.findMemberByBDC = res.Data.Member
        } else {
          this.findMemberByBDC = null
        }
      })
    },
    async getListPaymentMethodShortCutKey() {
      const body = {
        KeyGroup: 'SHORTCUT_KEY',
      }
      await commonServices.getCommon(body).then(res => {
        this.ListPaymentMethodShortCutKey = res.Data
        this.ListPaymentMethodShortCutKey.forEach((x, index) => {
          x.KeyCode = this.listShortCutKey[index]
        })
      })
    },
    onTriggeredEventHandler(payload) {
      this.ListPaymentMethodShortCutKey.forEach(x => {
        if (payload.key === x.KeyCode && x.KeyCode !== P && x.KeyCode !== S && x.KeyCode !== R) {
          this.listPaymentType.forEach(y => {
            y.PaymentMethod.forEach(z => {
              for (let i = 0; i < z.TransactionCode.length; i++) {
                if (z.TransactionCode[i].TransactionCode == x.KeyValue) {
                  this.handleChoicePayment(this.form.PaymentTypeCode, z, z.TransactionCode[i])
                  break
                }
              }
            })
          })
        }
      })
      if (payload.evt.ctrlKey && payload.key === S) {
        if(!this.disableAddNew) {
          this.addPaymentDetail()
        }
      }
      if (payload.evt.ctrlKey && payload.key === R) {
        this.$refs.confirmDialogComponent.open({
          Id: this.paymentRetailData.Id,
          dataConfirm: {
            title: 'Phát hành hóa đơn',
            content: 'Bạn có chắc chắn phát hành hóa đơn?',
          },
        })
      }
      if (payload.evt.ctrlKey && payload.evt.shiftKey && payload.key === P) {
        this.getInoicePdf()
      }
      if (payload.evt.ctrlKey && !payload.evt.shiftKey && payload.key === P) {
        this.OpenInvoice(this.paymentRetailData)
      }
    },
    call_api_UPM06(data) {
      const body = {
        PaymentRetail: {
          Id: data.Id,
        },
      }
      booking.api_UPM06(body).then(async response => {
        this.showResToast(response)
      })
    },
    async getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      this.pLang = localStorage.getItem('systemLanguage') || '1000000'
      this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
      this.pTimeZone = localStorage.getItem('timeZone')
      this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
      this.pPaymenRetailtId = this.$route.params.id * 1
      await this.api_RCOM01()
      await this.api_RCF00()
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrl = `${response.Data.find(x => x.SettingCode == 'SITE_API_URL').SettingValue}/api/course`
      })
    },
    async api_RCOM01() {
      const body = {
        KeyGroup: 'REPORT_CONFIG',
      }
      await commonServices.getCommon(body).then(response => {
        this.fileName = response.Data.find(x => x.KeyCode == 'IV03_REPORT_FILE_NAME').KeyValue
      })
    },
    async getInoicePdf() {
      const body = {
        ReportName: this.fileName,
        Params: {
          pIsGetAll: true, pInvoiceIndex: 0, pToken: this.pToken, pPaymentRetailId: this.paymentRetailData.Id, pUrl: this.pUrl, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset,pUser: JSON.parse(localStorage.getItem('userData')).userName
        },
      }
      await booking.api_RRPDF(body).then(response => {
        const decodedData = Buffer.from(response.Data.FileContents, 'base64')
        const newBlob = new Blob([decodedData], {
          type: 'application/pdf',
        })
        const iframe = document.createElement('iframe')
        const url = window.URL.createObjectURL(newBlob)
        iframe.src = url
        iframe.style.display = 'none'
        iframe.onload = () => {
          iframe.contentWindow.focus()
          iframe.contentWindow.print()
        }
        document.body.appendChild(iframe)
      })
    },
  },
}

<template>
  <div id="history-playing">
    <b-container>
      <b-row style="margin-top:20px">
        <b-col cols="5">
          <b-form-group>
                    <date-range-picker
                    ref="picker"
                    :single-date-picker="true"
                    v-model="StartDate"
                    :ranges="false"
                    opens="topleft"
                    :time-picker="false"
                    :time-picker-increment="1"
                    :show-dropdowns="true"
                    :auto-apply="true"
                    :locale-data="{
                        format: 'mm/dd/YYYY',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0
                    }"
                    />
            </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group>
              <date-range-picker
              ref="picker"
              :single-date-picker="true"
              v-model="EndDate"
              :ranges="false"
              opens="topleft"
              :time-picker="false"
              :time-picker-increment="1"
              :show-dropdowns="true"
              :auto-apply="true"
              :locale-data="{
                  format: 'mm/dd/YYYY',
                  daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                  firstDay: 0
              }"
              />
            </b-form-group>
        </b-col>
        <b-col cols="2">
            <b-button variant="primary" @click="search()" style="float: right;">
                {{ $t('golf_common_search') }}
            </b-button>
        </b-col>
      </b-row>
      <b-row style="height: 72px">
        <b-col
          cols="7"
          class="d-flex align-items-center"
        >
          <div class="item">
            {{ $t('golf_member_member_all') }}
            <span style="background: #114A9F; margin-left: 16px">{{ countTotalBooking() }}</span>
          </div>
          <div
            v-for="item in navList"
            :key="item.Title"
            class="item"
          >
            {{ item.Title }}
            <span style="background: #00CFE8">{{ item.Value }}</span>
          </div>

        </b-col>
        <b-col cols="3"  class="d-flex align-items-center">
          <b-form-group
                :label="$t('golf_starter_result_on_page')"
                label-for="h-first-name"
                label-cols-md="7"
                style="width: 230px"
            >
                <v-select
                v-model="perPage"
                :options="optionPerPage"
                :clearable="false"
                style="min-width: 50px"
                />
            </b-form-group>
        </b-col>
        <b-col
          cols="2"
          class="d-flex align-items-center"
        >
          <b-form-select
            value="null"
            class="_button-no-bg pl-2"
            :options="exportOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-overlay
            :show="isLoading"
            :opacity="0.3"
            rounded="sm"
        >
            <div>
                <DxDataGrid
                    ref="tablePackage"
                    :height="600"
                    class="table-starter"
                    :show-borders="false"
                    :data-source="items"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    column-resizing-mode="widget"
                    :column-auto-width="true"
                >
                <DxColumn
                    v-for="(item, i) in fieldsHistoryPlayingTable"
                    v-if="(item.field != 'action') ? item.isShow : true"
                    :key="i.field"
                    :caption="item.caption"
                    :data-field="item.field"
                    :data-type="item.type"
                    :alignment="item.alignment"
                    :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                    :width="item.width"
                    >
                    <DxColumn
                    v-for="(band, j) in item.bands"
                    v-if="band.isShow && item.bands.length > 0"
                    :key="band.field"
                    :caption="band.caption"
                    :data-field="band.field"
                    :data-type="band.type"
                    :group-cell-template="customText"
                    :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                    :alignment="band.alignment"
                    :width="item.width"
                  />
                </DxColumn>

                <template #open-date="{ data }">
                  <div v-if="data.data.OpenDate">
                    {{ convertUTC(data.data.OpenDate,'DD/MM/YYYY HH:mm') }}
                  </div>
                </template>

                <!-- Tuỳ chỉnh hiển thị sân áp dụng -->
              <template  #booking-detail-course="{ data }">
                <div v-if="data.data.BookingDetailCourse" >
                  <span
                    v-for="course in data.data.BookingDetailCourse"
                    :key="course.CourseId"
                  >
                    <ez-icon
                      icon="ezGolf-icon-golf-course"
                      size="18"
                    />
                    {{ course.CourseName }} ({{ course.NumberOfHole }})
                  </span>

                </div>
              </template>

              <template #with-player="{ data }">
              <div v-if="data.data.WithPlayer" style="min-width:25%">
                <div
                  v-for="user in data.data.WithPlayer"
                  :key="user.Id"
                >
                  
                  {{ user.FullName }}
                </div>
              </div>
            </template>
            <template #total-amount="{ data }">
              <div
              v-if="data.data.TotalAmount"
                class="text-right"
                style="padding-right: 50% "
              >
                {{ formatPrice(data.data.TotalAmount)}}
              </div>
            </template>
                <!-- Bật cố định column -->
                <DxColumnFixing :enabled="true" />

                <!-- Cho phép group -->
                <DxGroupPanel :visible="false" />

                <!-- Phân trang -->
                <DxPaging :page-size="perPage" />
                <DxPager
                  :visible="true"
                  display-mode="full"
                  :show-info="true"
                />
              </DxDataGrid>
            </div>
        </b-overlay>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="p-10"
        >
          <div class="footer-wrapper d-flex align-items-center justify-content-between">
            <span v-if="items">Hiển thị {{ items.length }} trên tổng số {{ items.length }} kết quả</span>
            <!--<b-pagination class="m-0" v-model="currentPage" pills :total-rows="rows"></b-pagination>-->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { members } from '@/api/members'
import dayjs from 'dayjs'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
const dataGridRef = 'tablePackage'
export default {
  name: 'History',
  props: ['data'],
  data() {
    return {
      items: [],
      navList: [],
      EndDate: null,
      StartDate: null,
      timeZoneOffSet: localStorage.getItem('timeZoneOffset'),
      isLoading: false,
      currentPage:1,
      perPage: 10,
      dataGridRef: 'tablePackage',
      optionPerPage: [10, 20, 50],
      fieldsHistoryPlayingTable: [
                {
                caption: this.$t('golf_member_booking'),
                field: 'BookingCode',
                isShow: true,
                alignment: 'center',
                width: '15%',
                },
                {
                caption: this.$t('golf_member_teetime'),
                field: 'TeeName',
                isShow: true,
                alignment: 'center',
                width: '10%',
                },
                {
                caption: this.$t('golf_member_booking_date'),
                field: 'OpenDate',
                isShow: true,
                cellTemplate: 'open-date',
                alignment: 'center',
                width: '15%',
                },
                {
                caption: this.$t('golf_member_course'),
                field: 'BookingDetailCourse',
                isShow: true,
                cellTemplate: 'booking-detail-course',
                alignment: 'center',
                width: '15%',
                },
                {
                caption: this.$t('golf_member_with_player'),
                field: 'WithPlayer',
                cellTemplate: 'with-player',
                isShow: true,
                alignment: 'center',
                width: '25%',
                },
                {
                caption: this.$t('golf_member_revenue'),
                field: 'TotalAmount',
                isShow: true,
                cellTemplate: 'total-amount',
                alignment: 'center',
                width: '10%',
                }
        ],
    }
  },
  components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
    },
  computed: {
    exportOptions() {
      return [
        { value: null, text: this.$t('golf_common_export') },
        { value: 1, text: this.$t('golf_common_export_to_pdf') },
        { value: 2, text: this.$t('golf_common_export_to_excel') },
      ]
    },
    dataGrid() {
          return this.$refs[dataGridRef].instance
        },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.getHistory_RMEH01()
      },
    },
  },
  methods: {
    async getHistory_RMEH01() {
      this.isLoading = true
      const requestData = {
        Member: {
          Id: this.data,
        },
      }
      const response = await members.getHistoryPlaying(requestData)

      if (response.Status === '200') {
        this.items = response?.Data?.BookingDetail
        this.navList = response?.Data?.BookingStatusStatistic
      }
      this.isLoading = false
    },
    countTotalBooking() {
      if (!this.navList) {
        return 0
      }

      let total = 0
      this.navList.forEach(item => {
        total += item.Value
      })

      return total
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.InActive === true) {
        return 'isOver'
      }
    },
    async search() {
      this.isLoading = true
      const requestData = {
        StartDate:this.StartDate ,
        EndDate: dayjs(this.EndDate).add(1, 'day').add(-this.timeZoneOffSet, 'minute').format('YYYY-MM-DDTHH:mm:ss'),
        Member: {
          Id: this.data,
        },
      }
      const response = await members.getHistoryPlaying(requestData)

      if (response.Status === '200') {
        this.items = response?.Data?.BookingDetail
        this.navList = response?.Data?.BookingStatusStatistic
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/dx-grid.scss';
#history-playing {
  ._button-no-bg {
    border-radius: 5px;
    border: 1px solid #114A9F;
    padding: 5px 10px;
    color: #114A9F;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #5d5f65;
    font-weight: 500;

    span {
      margin-left: 8px;
      margin-right: 22px;
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      justify-content: center;
      height: 20px;
      min-width: 20px;
      padding: 0 6px;
      border-radius: 4px;
      color: #FFFFFF;
    }
  }

  .item:first-child {
    margin-left: 16px;
  }
}
</style>

import {
    ROOT,
    api_RF01,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function API_RF01(data) {
    return fetch('/rp' + ROOT + api_RF01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

export const report = {
    API_RF01
}
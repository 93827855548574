import vSelect from 'vue-select'
import SidebarUpdateBooking from '@/views/modules/booking/modal/UpdatePlayInfo.vue'
import { booking } from '@/api/booking'
import { bookingPrint } from '@/api/booking-print'
import VueQrcode from 'vue-qrcode'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import InterestedCustomer from '@/views/modules/booking/detail/InterestedCustomer'
import SplitGroup from '@/views/modules/booking/modal/SplitGroup'
import Coupon from '@/views/modules/booking/modal/Coupon'
import UpdateGroup from '@/views/modules/booking/modal/UpdateGroup'
import CombineGroup from '@/views/modules/booking/modal/CombineGroup'
// import ShortKey from '@/views/modules/ultils/ShortKey'
import UpdateHole from '@/views/modules/booking/modal/UpdateHole'
import UpdateRateCode from '@/views/modules/booking/modal/UpdateRateCode'
import UpdatePackage from '@/views/modules/booking/modal/UpdatePackage'
import UpdateCaddy from '@/views/modules/booking/modal/UpdateCaddy'
import CheckIn from '@/views/modules/booking/modal/CheckIn'
import CancelBooking from '@/views/modules/booking/modal/CancelBooking.vue'
import CancelBookingGroup from '@/views/modules/booking/modal/CancelBookingGroup.vue'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P,
} from '@/api/constant.js'
import SidebarContent from '@/views/modules/members/SidebarContent.vue'
import { member_params } from '@/views/modules/booking/params/member'
import { members } from '@/api/members'
import { commonServices } from '@/api/common-services'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import NoShow from '@/views/modules/booking/modal/Noshow.vue'
import History from '@/views/modules/booking/modal/History'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
// import GolfService from '../AddGolfService/AddGolfService.vue'

export default {
  name: 'SidebarDetailBooking',
  components: {
    'confirm-dialog': ConfirmDialog,
    UpdateCaddy,
    UpdatePackage,
    UpdateGroup,
    CombineGroup,
    UpdateHole,
    UpdateRateCode,
    SidebarContent,
    SidebarUpdateBooking,
    UserAvatar,
    InterestedCustomer,
    SplitGroup,
    Coupon,
    CheckIn,
    NoShow,
    vSelect,
    // GolfService,
    'delete-dialog': DeleteDialog,
    CancelBooking,
    CancelBookingGroup,
    History,
    // ShortKey,
    VueQrcode,
  },
  props: [
    'id',
    'chooseDate',
    'bookingDetailId',
    'bookingId',
    'teeTimeId',
    'bookingDetailprops',
    'KeyWordDataProps',
    'listUserOfSale',
    'listUserOfGM',
    'listUserOfCashier',
    'defaultPrintBDCinCheckIn',
    'defaultCheckIn',
  ],
  data() {
    return {
      Payment: [],
      selectSevice: '',
      mode: 'info',
      KeyWord: '',
      BookingCode: '',
      checkboxSelected: '',
      groupName: {
        selected: null,
        list: [],
      },
      dataDetail2A: [],
      dataDetail2B: {
        GroupName: '',
        ClientName: '',
        SourceId: '',
        BagtagCode: '',
        TotalNumberOfGolfer: 0,
        ClientId: '',
        TeeName: '',
        RepresentativeNote: '',
        BookingDetail: {
          FullName: '',
          FirstName: '',
          BookingCode: '',
          LastName: '',
          MemberTypeId: '',
          Email: '',
          PhoneNumber: '',
          GolfClassName: '',
          RateName: '',
          PackageName: '',
          CaddyName: '',
          CaddyId: '',
          MemberId: '',
          Avatar: '',
        },
      },
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      countries: [],
      companies: [],
      cardType: [],
      isCreateMember: true,
      Member: member_params,
      checkboxSelectedAll: null,
      fields: ['age'],
      items: [{ age: 40, dataUser: 1 }],

      detailBookingId: null,
      detailBookingIds: [],
      bookingCode: [],
      caddy: [],
      bookingIdsChecked: [],
      // active: null,
      canShowAddService: false,
      checkedAll: false,
      isRemove: false,
      addMember: {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
      },
      listSex: [],
      currentLang: this.$i18n.locale,
      listSexIcon: [
        {
          value: 'SEX_M',
          icon: 'ezGolf-icon-male',
        },
        {
          value: 'SEX_F',
          icon: 'ezGolf-icon-female',
        },
        {
          value: 'SEX_LGBT',
          icon: 'ezGolf-icon-other-gender',
        },
      ],
      listMember: [],
      isMultiple: true,
      groupNameSearch: null,
      OpenDate: this.getUTC('start', 'day'),
      isLoading: false,
      data2ACheckedForNoShow: [],
      data2ACheckedForCancel: [],
      data2ACheckedForSplitGroup: [],
      data2ACheckedForCheckIn: [],
      data2ACheckedForCheckOut: [],
      data2ACheckedForPrint: [],
      listBDC: [],
      isOpen: false,
      sources: [],
      isLoading2A: false,
      listSale: [],
      allChildComponentClosed: [
        {
          ComponentId: 'modal-update-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-checkin',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-rate-code',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-hole',
          IsActive: false,
        },
        {
          ComponentId: 'modal-noshow-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-cancel-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-cancel-group-booking',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-booking-info',
          IsActive: false,
        },
        {
          ComponentId: 'modal-combine-group',
          IsActive: false,
        },
        {
          ComponentId: 'modal-update-package',
          IsActive: false,
        },
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P],
      caddyOption: [],
      BookingDetailPrint: null,
      isLoadPrintBDC: false,
      ListRate_RRA04A: null,
      Selected_Rate_RRA04A: null,
      checkInputRRA04A: false,
      feeCaddy: (localStorage.getItem('isDefaultCaddyChargeFee') == '1'),
      feeCaddyConfig: null, // Thông tin giá, transaction code vv.. của phí chuyển caddy
      zoneId: []
    }
  },
  watch: {
    bookingId(val) {
      if (!val) {
        const removeIframe = document.querySelectorAll('iframe')
        if (removeIframe.length > 0) {
          removeIframe.forEach(x => {
            document.body.removeChild(x)
          })
        }
      }
      if (val) {
        this.getDetailBooking2A()
      }
    },
    bookingDetailId(val) {
      // Nếu vào click từ màn teesheet, cần load hết các danh sách người chơi cùng tee
      // Nếu click vào detail2B từ kết quả tìm kiếm, thì không cần load danh sách người chơi cùng tee
      if (val) {
        if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode && !this.bookingId) {
          this.getDetailBooking2A()
        }
      }
    },
    listBDC(val) {
      // clearTimeout(this.timeout)
      // this.timeout = setTimeout(() => {
      //   if (val) {
      //     this.getDetailBooking2ASearch()
      //   } else {
      //     this.getDetailBooking2A()
      //   }
      // }, 500)

      setTimeout(() => {
        this.$refs.listBDC.$refs.search.focus()
      }, 10)
    },
    // KeyWord(val) {
    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     if (val) {
    //       this.getDetailBooking2ASearch()
    //     } else {
    //       this.getDetailBooking2A()
    //     }
    //   }, 500)
    // },
    OpenDate(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (val) {
          this.getDetailBooking2ASearch()
        } else {
          this.getDetailBooking2A()
        }
      }, 500)
    },
    // groupNameSearch(val) {
    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     if (val) {
    //       this.getDetailBooking2ASearch()
    //     } else {
    //       this.getDetailBooking2A()
    //     }
    //   }, 500)
    // },
    // BookingCode(val) {
    //   clearTimeout(this.timeout)
    //   this.timeout = setTimeout(() => {
    //     if (val) {
    //       this.getDetailBooking2ASearch()
    //     } else {
    //       this.getDetailBooking2A()
    //     }
    //   }, 500)
    // },
    dataDetail2A: {
      handler(val) {
        const arr = []
        for (const tee of val) {
          for (const booking of tee.Booking) {
            for (const BookingDetail of booking.BookingDetail) {
              if (BookingDetail.checked) {
                arr.push(BookingDetail.Id)
              }
            }
          }
        }
        this.bookingIdsChecked = [...arr]
      },
      deep: true,
    },
    // addMember: {
    //   handler(val) {
    //     clearTimeout(this.timeout)
    //     this.timeout = setTimeout(() => {
    //       this.getListMember_RME01(val)
    //     }, 500)
    //   },
    //   deep: true,
    // },
    isRemove(val) {
      if (!val) {
        this.listMember = []
      }
    },
    isOpen(val) {
      if (val) {
        setTimeout(() => {
          // this.$refs.listBDC.$refs.search.focus()
        }, 10)
      } else {
        // reset lại list bdc khi đóng sidebar
        this.listBDC = []
        this.KeyWord = null
        this.groupNameSearch = null
        this.BookingCode = null

        // if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
      }
      this.$emit('is-open', { ComponentId: 'sidebar-detail-booking', IsActive: val })
    },
  },
  async created() {
    await commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
        if (res) {
          this.listSex = res.Data.filter(x => x.LangId == this.currentLang)
        }
      })
    await this.getConfigInfo_RCOM01()
    await this.getListServicesFee_RSV02()
  },
  mounted() {
    // this.getListGroup_RBK06()
    this.getCountries_RNA01()
    // this.getCompanies_RCL01()
    // this.getListResources_RSO01()
    // this.getListCashier_RCS01()
  },
  methods: {
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listSale = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    openSidebarHistory() {
      this.$refs.History.open(this.dataDetail2B)
    },
    call_api_UBK04() {
      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          PackageId: this.dataDetail2B.BookingDetail.PackageId,
        },
        Type: 'DELETE',
      }
      booking.api_UBK04(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
        }
      })
    },

    async call_api_UBK07(ClientId, SourceId) {
      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          ClientId,
          SourceId,
        },
      }
      await booking.api_UBK07(body).then(res => {
        // this.showResToast(res)
        if (res.Status === '200') {
          const params = {
            BookingDetail: {
              Id: this.dataDetail2B.BookingDetail.Id,
              RateId: res.Data.Rate[0].Id,
              Reasons: null,
              BookingDetailServices: res.Data.Rate[0].ServiceList,
            },
          }

          booking.updateRateCode(params).then(response => {
            this.showResToast(response)
            if (response.Status === '200') {
              this.$emit('event', { type: 'after-update-booking-rate-code' })
              this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
            }
          })
        } else {
          this.showResToast(res)
        }
      })
    },

    async getListMember_RME01() {
      if (this.addMember.FullName || this.addMember.CardCode || this.addMember.Email || this.addMember.PhoneNumber) {
        const requestData = {
          MemberName: (this.addMember.FullName ? this.addMember.FullName : ''),
          CardCode: this.addMember.CardCode,
          CardTypeId: null,
          MemberType: null,
          Email: this.addMember.Email,
          PhoneNumber: this.addMember.PhoneNumber,
          BookingDetailId: this.dataDetail2B.BookingDetail.Id,
        }

        const response = await members.getListMember(requestData)
        if (response.Status === '200') {
          this.listMember = response?.Data?.Member
        }
      } else {
        this.listMember = []
      }
    },
    async addMemberToDetailBooking_UBK09(item) {
      let newCardCode = null
      let rateId = null
      let golfClassId = null
      // Khi nhập số thẻ
      // Tìm số thẻ phù hợp và trạng thái không bị truất quyền, nếu thấy thì kết quả phù hợp, không thông báo
      if (item.MemberSubscription.filter(x => x.Status == 'VALID' && x.CardCode && this.addMember.CardCode && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).length) {
        newCardCode = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Id
        golfClassId = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).MemberCard.GolfClassId
        rateId = item.MemberSubscription.find(x => x.Status == 'VALID' && x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).MemberCard.RateId[0]
      } else {
        // Không phù hợp thì hiện thông báo, chỉ hiện thông báo khi có giá trị cardCode
        if (this.addMember.CardCode) {
          let error = ''
          if (item.MemberSubscription.filter(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).length) {
            if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Status == 'DEPOSITION') {
              error = 'bị truất quyền'
            } else if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Status == 'EXPIRED') {
              error = 'hết hiệu lực'
            } else if (item.MemberSubscription.find(x => x.CardCode.toUpperCase() == this.addMember.CardCode.toUpperCase()).Status == 'TURNOFF') {
              error = 'hết lượt chơi'
            }
          }
          this.showToast('error', `Mã thẻ ${this.addMember.CardCode} đã ${error} và sẽ được thay thế bằng thẻ khác nếu có.`)
        }

        // Chọn thẻ đầu tiên nếu có, nếu không có thẻ phù hợp
        if (item.MemberSubscription.filter(x => x.Status == 'VALID').length) {
          newCardCode = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].Id
          golfClassId = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].MemberCard.GolfClassId
          rateId = item.MemberSubscription.filter(x => x.Status == 'VALID')[0].MemberCard.RateId[0]
        }
      }

      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          MemberId: item.Id,
          MemberSubscriptionId: newCardCode,
        },
        IsForce: false,
      }
      await booking.api_UBK09(body).then(res => {
        if (res.Status == 200) {
          // Nếu member đã được add
          if (res.Messages.length > 0) {
            const dataConfirm = {
              dataConfirm: {
                title: 'Xác nhận thêm thành viên',
                content: res.Messages[0].MessageText,
                body: {
                  BookingDetail: {
                    Id: this.dataDetail2B.BookingDetail.Id,
                    MemberId: item.Id,
                    MemberSubscriptionId: newCardCode,
                  },
                  IsForce: true,
                },
                golfClassId,
                rateId,
              },
            }
            // Hiển thị xác nhận add member đã có
            this.$refs.confirmDialogAddMemberToDetailBoooking.open(dataConfirm)
          } else {
            this.updateBooking_UBK02(golfClassId, rateId)
            this.showResToast(res)
            this.listMember = []
            this.isRemove = false
            this.addMember = {
              FullName: null,
              FirstName: null,
              LastName: null,
              PhoneNumber: null,
              Email: null,
              SexCode: 'SEX_M',
              CardCode: null,
            }
            // this.getDetailBooking2A()
            // Nếu đã từng vào detail id hay vào từ màn quét bdc
            if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
              // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
              setTimeout(() => {
                this.getDetailBooking2A()
              }, 500)
            } else {
              setTimeout(() => {
                this.getDetailBooking2ASearch()
              }, 500)
            }
            this.$emit('event', { type: 'after-add-member-to-detail-booking' })
          }
        } else {
          this.showResToast(res)
        }
      })
    },
    async updateBooking_UBK02(golfClassId, rateId) {
      // Nếu là member, có MemberSubscriptionId
      if (this.dataDetail2B.BookingDetail.MemberSubscriptionId) {
        const params = {
          BookingDetail: {
            Id: this.dataDetail2B.BookingDetail.Id,
            ClientId: this.dataDetail2B.BookingDetail.ClientId,
            SourceId: this.dataDetail2B.BookingDetail.SourceId,
            // GolfClassId: golfClassId,
            TeeTimeId: this.dataDetail2B.BookingDetail.TeeTimeId,
            // RateId: rateId,
            CardCode4GolfClassRule: null,
            Bdc4golfClassRule: null,
          },
        }
        // Nếu có rate id và golf class id
        if (golfClassId && rateId) {
          params.BookingDetail.GolfClassId = golfClassId
          params.BookingDetail.RateId = rateId
        } else {
        // Nếu không có rate id và golf class id
          params.BookingDetail.GolfClassId = this.dataDetail2B.BookingDetail.GolfClassId
          params.BookingDetail.RateId = -1
        }
        await booking.updateTeeTimeForBooking(params)
        if (!golfClassId || !rateId) {
          this.showToast('error', this.$t('golf_common_please_select_golfclass'))
        }
        this.$emit('event', { type: 'after-change-rate-golf-class-from-select-add-new-member' })
      } else {
        // Nếu là Guest
        // Nếu có rate id và golf class id
        if (golfClassId && rateId) {
          const params = {
            BookingDetail: {
              Id: this.dataDetail2B.BookingDetail.Id,
              ClientId: this.dataDetail2B.BookingDetail.ClientId,
              SourceId: this.dataDetail2B.BookingDetail.SourceId,
              GolfClassId: golfClassId,
              TeeTimeId: this.dataDetail2B.BookingDetail.TeeTimeId,
              RateId: rateId,
              CardCode4GolfClassRule: null,
              Bdc4golfClassRule: null,
            },
          }
          await booking.updateTeeTimeForBooking(params)
          this.$emit('event', { type: 'after-change-rate-golf-class-from-select-add-new-member' })
        }
      }
    },

    confirmAddMemberToDetailBooking_UBK09(dataConfirm) {
      // const body = {
      //   BookingDetail: {
      //     Id: this.dataDetail2B.BookingDetail.Id,
      //     MemberId: item.Id,
      //     MemberSubscriptionId: (item.MemberSubscription[0]) ? item.MemberSubscription[0].Id : null,
      //   },
      // }
      booking.api_UBK09(dataConfirm.dataConfirm.body).then(res => {
        this.showResToast(res)
        this.listMember = []
        this.isRemove = false
        this.addMember = {
          FullName: null,
          FirstName: null,
          LastName: null,
          PhoneNumber: null,
          Email: null,
          SexCode: 'SEX_M',
          CardCode: null,
        }
        if (res.Status == 200) {
          this.updateBooking_UBK02(dataConfirm.dataConfirm.golfClassId, dataConfirm.dataConfirm.rateId)

          // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
          if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
            setTimeout(() => {
              this.getDetailBooking2A()
            }, 500)
          } else {
            setTimeout(() => {
              this.getDetailBooking2ASearch()
            }, 500)
          }
          this.$emit('event', { type: 'after-add-member-to-detail-booking' })
        }
      })
    },
    async removeMemberCard() {
      const body = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          MemberId: this.dataDetail2B.BookingDetail.MemberId,
          MemberSubscriptionId: null,
        },
        IsForce: true,
      }
      await booking.api_UBK09(body).then(res => {
        this.showResToast(res)
        if (res.Status == 200) {
          // Nếu đã từng vào detail id hay vào từ màn quét bdc
          if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
            // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
            setTimeout(() => {
              this.getDetailBooking2A()
            }, 500)
          } else {
            setTimeout(() => {
              this.getDetailBooking2ASearch()
            }, 500)
          }
          this.$emit('event', { type: 'after-add-member-to-detail-booking' })
        }
      })
    },
    cancelAddMember() {
      this.listMember = []
      this.isRemove = false
      this.addMember = {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
      }
    },
    addNewMemberToDetailBooking_IME01() {
      const body = {
        BookingDetailId: this.dataDetail2B.BookingDetail.Id,
        Member: {
          FullName: this.addMember.FullName,
          FirstName: this.addMember.FirstName,
          LastName: this.addMember.LastName,
          CardCode: this.addMember.CardCode,
          SexCode: this.addMember.SexCode,
          PhoneNumber: this.addMember.PhoneNumber,
          Email: this.addMember.Email,
        },
      }
      members.createMember(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.listMember = []
          this.isRemove = false
          this.addMember = {
            FullName: null,
            FirstName: null,
            LastName: null,
            PhoneNumber: null,
            Email: null,
            SexCode: 'SEX_M',
            CardCode: null,
          }
          this.updateBooking_UBK02(null, null)
          if (res.Status == 200) {
            if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
              // Delay lại vì api UBK02 chưa kịp cập nhật khi load lại vẫn lấy theo rate cũ
              setTimeout(() => {
                this.getDetailBooking2A()
              }, 500)
            } else {
              setTimeout(() => {
                this.getDetailBooking2ASearch()
              }, 500)
            }
            this.$emit('event', { type: 'after-add-new-member-to-detail-booking' })
          }
        }
      })
    },
    Preview() {
      const data2ACheckedForPrint = []
      this.dataDetail2A.forEach(x => {
        x.Booking.forEach(y => {
          y.BookingDetail.forEach(z => {
            // only get checkin data
            if (z.checked) {
              data2ACheckedForPrint.push(z.Id)
            }
          })
        })
      })

      if (!data2ACheckedForPrint.length) {
        return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
      }

      if (data2ACheckedForPrint.length > 0) {
        const routeData = this.$router.resolve({
          name: 'bookingPrint',
          query: {
            id: data2ACheckedForPrint,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },
    openSidebarCheckout(fromDetail = true) {
      this.data2ACheckedForCheckOut = []
      if (fromDetail) {
        this.data2ACheckedForCheckOut.push(this.dataDetail2B.BookingDetail.Id)
      } else {
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked) {
                this.data2ACheckedForCheckOut.push(z.Id)
              }
            })
          })
        })
      }

      if (!this.data2ACheckedForCheckOut.length) {
        return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      this.$emit('event', {
        type: 'open-sidebar-checkout',
        bookingDetailData: { bookingDetailId: this.data2ACheckedForCheckOut },
        bookingDetailprops: { Id: this.bookingDetailId, TeeTimeId: this.teeTimeId },
        KeyWordDataProps: {
          KeyWord: this.KeyWord, BookingCode: this.BookingCode, BookingId: this.id ? !this.id.TeeTimeId ? this.id.Id : this.groupName.selected : this.groupName.selected, GroupName: this.groupNameSearch, OpenDate: this.OpenDate, BDC: this.listBDC,
        },
      })
    },
    handleAddService() {
      this.detailBookingId = this.dataDetail2B.BookingDetail.Id
      this.canShowAddService = true
      this.$root.$emit('bv::toggle::collapse', 'add-service-item', { dataDetail2B: this.dataDetail2B, detailBookingId: this.detailBookingId })
    },
    async updateNoteAndLockerBooking_UBK10() {
      const params = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          LockerCode: this.dataDetail2B.BookingDetail.LockerCode,
          BagTagCode: this.dataDetail2B.BookingDetail.BagtagCode,
          Note: this.dataDetail2B.BookingDetail.Note,
        },
      }
      const response = await booking.updateNoteAndLockerForBooking(params)
      this.showResToast(response)
    },
    // async getListGroup_RBK06() {
    //   const response = await booking.api_RBK06({})
    //   if (response.Status === '200') {
    //     this.groupName.list = response?.Data?.Booking
    //   }
    // },
    async getDetailBooking2A() {
      this.isLoading2A = true
      this.isRemove = false
      this.checkedAll = false

      const bookingDetailData = {
        Id: this.bookingDetailId,
        TeeTimeId: this.teeTimeId,
      }

      const data = {
        KeyWord: this.KeyWord,
        BookingCode: this.BookingCode,
        BookingDetail: !this.bookingId ? bookingDetailData : null,
        BookingId: this.bookingId,
      }

      const response = await booking.getDetailBooking2A(data)
      this.isLoading2A = false
      if (response.Status === '200') {
        this.dataDetail2A = response?.Data?.TeeTime
        if (this.dataDetail2A) {
          this.bookingCode = []
          this.dataDetail2A.forEach((item, index) => {
            if (item) {
              item.Booking.forEach((item1, index1) => {
                this.bookingCode.push(item1.BookingCode)
              })
            }
          })
        }
        // Có bookingId thì active người đầu tiên, có bookingDetailId thì active người đó.
        // Có tồn tại bookingDetailId và không tồn bookingId ( View từ màn tee sheet)
        // if (this.bookingDetailId && !this.bookingId) {

        if (this.bookingDetailId) {
          await this.getDetailBooking2B(this.bookingDetailId)
          return
        }

        // Sau khi add new ( có bookingId ), chọn người đầu tiên để active
        if (this.bookingId && this.dataDetail2A[0] && this.dataDetail2A[0].Booking[0] && this.dataDetail2A[0].Booking[0].BookingDetail[0]) {
          // Check điều kiện chỉ thực hiện gán lần đầu tiên, nếu không sẽ luôn active người đầu tiên, không phải người hiện tại
          if (!this.bookingDetailId) {
            this.setActiveUser({ Id: this.dataDetail2A[0].Booking[0].BookingDetail[0].Id })
          }
        }
      }
    },
    async getDetailBooking2ASearch(isActiveLast) {
      this.isLoading2A = true
      this.isRemove = false
      this.checkedAll = false
      const data = {
        KeyWord: this.KeyWord,
        BookingCode: this.BookingCode,
        BookingId: this.id ? !this.id.TeeTimeId ? this.id.Id : this.groupName.selected : this.groupName.selected,
        GroupName: this.groupNameSearch,
        OpenDate: this.OpenDate,
        BDC: this.listBDC,
      }

      const response = await booking.getDetailBooking2A(data)
      if (response.Status === '200') {
        this.dataDetail2A = response?.Data?.TeeTime
        if (this.dataDetail2A) {
          this.bookingCode = []
          this.dataDetail2A.forEach((item, index) => {
            if (item) {
              item.Booking.forEach((item1, index1) => {
                this.bookingCode.push(item1.BookingCode)
              })
            }
          })
        }

        // if (reloadList) {
        //   this.$emit('event', { type: 'reload_list' })
        // }

        // if (this.bookingDetailId) {
        //   await this.getDetailBooking2B(this.bookingDetailId)
        //   return
        // }

        // if (this.bookingId && this.dataDetail2A[0] && this.dataDetail2A[0].Booking[0] && this.dataDetail2A[0].Booking[0].BookingDetail[0]) {
        //   const bookingDetailId = this.dataDetail2A[0].Booking[0].BookingDetail[0].Id
        //   this.bookingDetailId = bookingDetailId
        //   await this.getDetailBooking2B(bookingDetailId)
        // }

        // Có bookingId thì active người đầu tiên, có bookingDetailId thì active người đó.
        // Có tồn tại bookingDetailId và không tồn bookingId ( View từ màn tee sheet)
        // if (this.bookingDetailId && !this.bookingId) {
        //   await this.getDetailBooking2B(this.bookingDetailId)
        //   return
        // }

        // Load lại 2 trường hợp, khi tìm kiếm trực tiếp bdc thì active người cuối, nếu không phải tìm kiếm trực tiếp thì active người hiện tại
        // Sau khi add new( có bookingId ), chọn người cuối cùng để active

        if (this.dataDetail2A.length) {
          const lastBoookingDetail = JSON.parse(JSON.stringify(this.dataDetail2A))

          if (isActiveLast) {
            this.setActiveUser({ Id: lastBoookingDetail.pop().Booking.pop().BookingDetail.pop().Id })
          } else {
            this.setActiveUser({ Id: this.bookingDetailId })
          }
        }
      }
      this.isLoading2A = false
    },
    async getDetailBooking2B(Id) {
      this.isLoading = true
      const resBooking2B = await booking.getDetailBooking2B({ BookingDetail: { Id } })
      if (resBooking2B.Status === '200') {
        this.dataDetail2B = resBooking2B?.Data?.Booking
        this.getListRate_RRA04A()
        this.getListResources_RSO01(this.dataDetail2B.BookingDetail.OpenDateTime)
        this.getCompanies_RCL01(this.dataDetail2B.BookingDetail.OpenDateTime)
        this.getListCaddy_RCA04()
        // if (this.dataDetail2B.BookingDetail.MemberId === -1) {
        //   this.isRemove = true
        // } else {
        //   this.isRemove = false
        // }
      } else {
        this.dataDetail2B = null
      }
      this.isLoading = false
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
      }
    },
    async getListServicesFee_RSV02() {
      const response = await booking.getListServicesFee({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
        Type: 'BOOKING_CADDY_CHARGE_FEE',
        BookingType: 'COURSE',
      })
      if (response.Status === '200') {
        // this.additionalFee.list = response?.Data?.Menu.map(item => item.MenuDetail).flat()
        this.feeCaddyConfig = response?.Data?.Menu.map(item => item.MenuDetail).flat()[0]
        if (response?.Data?.Menu.length > 0) {
          this.feeCaddy = (localStorage.getItem('isDefaultCaddyChargeFee') == '1')
        }
      }
    },
    handleOpenModal(key, id) {
      this.checkPermissionOpenModal(key, id)
    },
    async getListRate_RRA04A() {
      const listCourse = []
      this.dataDetail2B.BookingDetail.BookingDetailCourse.forEach(x => {
        listCourse.push({
          Id: x.CourseId,
          NumberOfHole: x.NumberOfHole,
        })
      })
      const body = {
        Date: this.dataDetail2B.BookingDetail.OpenDate,
        GolfClassId: this.dataDetail2B.BookingDetail.GolfClassId,
        Course: listCourse,
        WeeklyCode: this.weekLyCode,
      }
      await booking.api_RRA04A(body).then(res => {
        this.ListRate_RRA04A = res.Data.Rate
      })
    },
    handleEvent(event) {
      // Chuyển sang màn thu ngân
      if (event.type === 'open-sidebar-checkout') {
        // Đóng sidebar mua dịch vụ
        this.$root.$emit('bv::toggle::collapse', 'add-service-item')
        // Mở sidebar thu ngân
        this.openSidebarCheckout(true)
      }
      // save_success là sự kiện sau khi update thông tin thành viên thành công
      // Sau khi checkin thành công
      if (event.type === 'after-checkin' || event.type === 'after-checkout' || event.type === 'after-cancel-booking' || event.type === 'after-cancel-booking-group' || event.type === 'after-add-member-to-detail-booking' || event.type === 'after-update-booking-package' || event.type === 'after-update-booking-rate-code' || event.type === 'save_success' || event.type === 'after-no-show' || event.type === 'after-add-caddy' || event.type === 'after-update-add-caddy' || event.type === 'after-update-hole') {
        // 2A đã bao gồm gọi 2B
        // this.getDetailBooking2A()
        if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }

      // Sau khi đổi tee time, cập nhật thông tin chơi
      if (event.type === 'after-update-booking-tee-time') {
        this.teeTimeId = event.newTeeTimeId
        // this.getDetailBooking2A()
        if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }
      // Sau khi hủy, gửi email cancel
      if (event.type === 'cancel_email') {
        this.openConfirmEmail('CANCEL')
      }

      // Sau khi tách đoàn và gộp đoàn, đổi thông tin booking
      if (event.type === 'after-combine-group' || event.type === 'after-split-group' || event.type === 'after-update-group-info') {
        // 2A đã bao gồm gọi 2B
        // this.getDetailBooking2A()
        if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
      }

      // Chuyển tiếp sự kiện ra ngoài com cha
      if (event.type != 'open-sidebar-checkout') {
        this.$emit('event', event)
      }
    },
    changeModeTo(mode) {
      this.mode = mode
    },
    hideSideBar() {
      // this.teeTimeId = null
      // this.bookingDetailId = null
      // this.bookingId = null
      // this.bookingDetailprops = null
      // this.KeyWordDataProps = null
      // this.clearData()
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-list')
    },
    openModal(key, id) {
      this.checkPermissionOpenModal(key, id)
    },
    addMoreCustomer() {
      this.hideSideBar()
      this.$emit('event', { type: 'add-more-customer-from-detail-booking', dataDetail2B: this.dataDetail2B })
    },
    addNewBooking() {
      this.hideSideBar()
      this.$emit('event', { type: 'add-new-booking-from-detail-booking' })
    },
    openRIV01(paymentId) {
      const routeData = this.$router.resolve({ name: 'booking-print-invoice', params: { id: paymentId } })
      window.open(routeData.href, '_blank')
    },
    calConThieu(Options) {
      return Options.TotalAmount - Options.ReceivedAmount
    },
    async getRPM01(BookingDetailId) {
      const data = {
        BookingDetailId: [BookingDetailId],
      }
      await booking.getRPM01(data).then(response => {
        if (response.Status === '200') {
          this.Payment = response?.Data?.Payment
        }
      })
    },
    async openCaddyModal() {
      this.openModal('RCAG01', 'modal-update-caddy')
    },
    async getListCaddy_RCA04() {
      const data_request = {
        BookingDetailId: this.dataDetail2B.BookingDetail.Id,
      }

      const response = await booking.getListCaddy(data_request)
      if (response.Status === '200') {
        this.caddy = response.Data.Caddy

        this.caddyOption = response.Data.Caddy.map(e => Object.assign(e, { label: `${e.CaddyCode} - ${e.CaddyName}` }))
      }
    },
    async addCaddyToBooking() {
      const payload = {
        BookingDetailCaddy: {
          Id: null,
          BookingDetailId: this.dataDetail2B.BookingDetail.Id,
          CaddyId: Number(this.dataDetail2B.BookingDetail.CaddyId),
          NumberOfHole: this.dataDetail2B.BookingDetail.TotalNumberOfHole,
          WorkingStatus: 'B',
          Reasons: '',
          IsDeleted: false,
          IsBooked: true,
          BookingDetailServices: [
            {
              BookingDetailId: this.dataDetail2B.BookingDetail.Id,
              ServiceId: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductId),
              UnitPrice: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
              Quantity: Number(!this.feeCaddy ? 0 : 1),
              Amount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
              TotalAmount: Number(!this.feeCaddy ? 0 : this.feeCaddyConfig.ProductPrice),
              TransactionCode: String(!this.feeCaddy ? '' : this.feeCaddyConfig.TransactionCode.TransactionCode),
            },
          ],
        },
      }
      booking.updateCaddy(payload).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
          this.$emit('event', { type: 'after-add-caddy' })
        }
      })
    },
    openModalCombineGroup() {
      this.$root.$emit('bv::show::modal', 'modal-combine-group')
    },
    openConfirmEmail(e) {
      const routeData = this.$router.resolve({ name: 'confirmEmail', params: { id: this.bookingDetailId, type: e } })
      window.open(routeData.href, '_blank')
    },
    openModalSplitGroup(fromDetail = true) {
      if (fromDetail) {
        this.detailBookingId = this.dataDetail2B.BookingDetail.Id
      } else {
        const data2ACheckedForSplitGroup = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked) {
                data2ACheckedForSplitGroup.push(z)
              }
            })
          })
        })

        if (!data2ACheckedForSplitGroup.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
        }
        this.data2ACheckedForSplitGroup = Object.assign([], data2ACheckedForSplitGroup)
      }

      this.$root.$emit('bv::show::modal', 'modal-split-group')
    },
    openModalNoShow(fromDetail = true) {
      if (fromDetail) {
        this.detailBookingId = this.dataDetail2B.BookingDetail.Id
      } else {
        const data2ACheckedForNoShow = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked) {
                data2ACheckedForNoShow.push(z)
              }
            })
          })
        })

        if (!data2ACheckedForNoShow.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
        }
        this.data2ACheckedForNoShow = Object.assign([], data2ACheckedForNoShow)
      }

      this.$root.$emit('bv::show::modal', 'modal-noshow-booking')
    },
    openModalCancelBooking(fromDetail = true) {
      if (fromDetail) {
        this.detailBookingId = this.dataDetail2B.BookingDetail.Id
        this.data2ACheckedForCancel = []
      } else {
        const data2ACheckedForCancel = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              // only get checkin data
              if (z.checked) {
                data2ACheckedForCancel.push(z)
              }
            })
          })
        })

        if (!data2ACheckedForCancel.length) {
          return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
        }
        this.data2ACheckedForCancel = Object.assign([], data2ACheckedForCancel)
        this.detailBookingId = null
      }

      this.$root.$emit('bv::show::modal', 'modal-cancel-booking')
    },
    openModalCancelBookingGroup() {
      this.$root.$emit('bv::show::modal', 'modal-cancel-group-booking')
    },
    openModalUpdateBookingInfo() {
      this.$root.$emit('bv::show::modal', 'modal-update-booking-info')
    },
    async openModalCheckIn(fromDetail = true) {
      if (!this.checkPermission('ICI01')) {
        return
      }
      const data2ACheckedForCheckIn = []

      if (!fromDetail) {
        this.isLoading2A = true
        for (const [index, tee] of this.dataDetail2A.entries()) {
          for (const bookingData of tee.Booking) {
            for (const BookingDetail of bookingData.BookingDetail) {
              // only get checkin data
              if (!BookingDetail.checked || BookingDetail.BookingDetailStatus !== 'BOOKED' || BookingDetail.BookingDetailStatus == 'CANCELLED' || BookingDetail.BookingDetailStatus == 'NO_SHOW') {
                continue
              }

              const resBooking2B = await booking.getDetailBooking2B({ BookingDetail: { Id: BookingDetail.Id } })
              if (resBooking2B.Status === '200') {
                if (!data2ACheckedForCheckIn[index]) {
                  data2ACheckedForCheckIn[index] = {
                    OpenDate: tee.OpenDate,
                    GroupName: tee.Booking[0].GroupName,
                    StartTime: tee.StartTime,
                    TeeName: tee.TeeName,
                    Members: [],
                    Checked: false,
                  }
                }

                const dataDetail2B = resBooking2B?.Data?.Booking.BookingDetail
                data2ACheckedForCheckIn[index].Members.push({
                  BookingDetail,
                  CaddyName: dataDetail2B.CaddyName,
                  CaddyId: dataDetail2B.CaddyId,
                  BagtagCode: dataDetail2B.BagtagCode,
                  LockerCode: dataDetail2B.LockerCode,
                  Checked: false,
                })
              }
            }
          }
        }
        this.isLoading2A = false
        if (!data2ACheckedForCheckIn.length) {
          return this.showToast('error', 'Vui lòng chọn ít nhất một khách để checkin')
        }
      } else {
        const data = {
          BookingId: this.dataDetail2B.Id,
        }
        const response = await booking.getDetailBooking2A(data)
        if (response.Status === '200' && response.Data.TeeTime[0]) {
          const bookingData = response.Data.TeeTime[0]
          data2ACheckedForCheckIn.push({
            OpenDate: bookingData.OpenDate,
            GroupName: bookingData.Booking[0].GroupName,
            StartTime: bookingData.StartTime,
            TeeName: bookingData.TeeName,
            Members: [{
              BookingDetail: this.dataDetail2B.BookingDetail,
              CaddyName: this.dataDetail2B.BookingDetail.CaddyName,
              CaddyId: this.dataDetail2B.BookingDetail.CaddyId,
              BagtagCode: this.dataDetail2B.BookingDetail.BagtagCode,
              LockerCode: this.dataDetail2B.BookingDetail.LockerCode,
            }],
          })
        }
      }

      // Loại bỏ item rỗng
      this.data2ACheckedForCheckIn = Object.assign([], data2ACheckedForCheckIn.filter(x => x))

      this.$root.$emit('bv::show::modal', 'modal-checkin')
    },
    setActiveUser(bookingDetail) {
      this.Selected_Rate_RRA04A = null
      this.bookingDetailId = bookingDetail.Id
      // Cập nhật lại bookingDetailId cho prop để khi load lại ko bị mất trạng thái active người hiện tại trong 2A
      // Không cập nhật prop nếu như đang tìm kiếm, nếu không sẽ lỗi khi xem lại chính detail booking đó sau khi tìm kiếm
      // if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
      this.$emit('event', { type: 'update-current-booking-detail-id', bookingDetailId: this.bookingDetailId })
      // }
      this.getDetailBooking2B(bookingDetail.Id)
    },
    openModalDistributionForSingle() {
      this.bookingDetailId = this.dataDetail2B.BookingDetail.Id
      // Cập nhật phân bổ cho 1 người
      this.$emit('event', { type: 'open-modal-distribution-single', bookingDetailIdDistribution: this.dataDetail2B.BookingDetail.Id })
    },
    getStatusText(status) {
      switch (status) {
        case 'CHECK_IN':
          return 'CheckIn'
        case 'CHECK_OUT':
          return 'CheckOut'
        case 'CANCELLED':
          return 'Cancelled'
        case 'NO_SHOW':
          return 'No-Show'
        default:
          return 'Booked'
      }
    },

    checkAll(value) {
      for (const tee of this.dataDetail2A) {
        tee.checked = value
        this.checkAllDate(tee)
      }
    },

    async showMemberForm() {
      if (!this.checkPermission('UME01')) {
        return
      }
      if (this.dataDetail2B.BookingDetail.MemberId === -1) {
        this.isCreateMember = true
      } else {
        await this.getDetailMember_RME02(this.dataDetail2B.BookingDetail.MemberId)
        this.isCreateMember = false
      }

      this.detailBookingId = this.dataDetail2B.BookingDetail.Id

      this.$root.$emit('bv::toggle::collapse', 'createMember')
    },

    async getCountries_RNA01() {
      const response = await members.getCountries({})
      if (response) {
        this.countries = response.Data.Country
      }
    },

    async getCompanies_RCL01(OpenDateTime) {
      const response = await members.getCompanies({ OpenDateTime })
      if (response) {
        this.companies = response.Data.Client
      }
    },
    async getListResources_RSO01(OpenDateTime) {
      const response = await booking.getListResources({ OpenDateTime })

      if (response.Status === '200') {
        this.sources = response?.Data?.Source
        // this.source.selected = response?.Data?.Source[0]
      }
    },

    async getDetailMember_RME02(id) {
      this.isCreate = false
      const response = await members.detailMember({
        Member: {
          Id: id,
        },
      })
      if (response.Status === '200') {
        this.Member = response?.Data?.Member
      }
    },

    checkAllDate(value) {
      this.dataDetail2A.forEach(x => {
        if (x.OpenDate === value.OpenDate) {
          if (value.checked) {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                if (z.BookingDetailStatus === 'CANCELLED' || z.BookingDetailStatus === 'NO_SHOW') {
                  z.checked = false
                } else {
                  z.checked = true
                }
              })
            })
          } else {
            x.Booking.forEach(y => {
              y.BookingDetail.forEach(z => {
                z.checked = false
              })
            })
          }
        }
      })
      this.$forceUpdate()
    },
    checkItem() {
      let ischeckAll = true
      for (const tee of this.dataDetail2A) {
        tee.checked = true
        for (const booking of tee.Booking) {
          for (const BookingDetail of booking.BookingDetail) {
            if (!BookingDetail.checked) {
              tee.checked = false
              if (this.checkedAll) {
                this.checkedAll = false
              }
              return
            }
          }
        }
        if (!tee.checked) {
          ischeckAll = false
        }
      }
      if (ischeckAll) {
        this.checkedAll = true
      }
    },
    openConfirmEmailGroup(e) {
      if (e == 'BOOKED') {
        const routeData = this.$router.resolve({ name: 'confirmEmailGroupAll', params: { BookingCode: this.dataDetail2B.BookingCode }, query: { BookingDetailId: this.dataDetail2B.BookingDetail.Id } })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({ name: 'confirmEmailGroupAll', params: { BookingCode: this.dataDetail2B.BookingCode }, query: { BookingDetailId: null } })
        window.open(routeData.href, '_blank')
      }
    },
    AfterCloseSidebarCheckout() {
      if (this.KeyWordDataProps.BDC.length > 0 || this.KeyWordDataProps.BookingCode || this.KeyWordDataProps.BookingId || this.KeyWordDataProps.GroupName || this.KeyWordDataProps.KeyWord) {
        this.KeyWord = this.KeyWordDataProps.KeyWord
        this.BookingCode = this.KeyWordDataProps.BookingCode
        this.groupNameSearch = this.KeyWordDataProps.GroupName
        this.OpenDate = this.KeyWordDataProps.OpenDate
        this.listBDC = this.KeyWordDataProps.BDC
        this.getDetailBooking2ASearch()
      } else {
        this.bookingDetailId = this.bookingDetailprops.Id
        this.teeTimeId = this.bookingDetailprops.TeeTimeId
        this.getDetailBooking2A()
      }
    },
    async getDetailBooking2ABookingList() {
      this.isLoading2A = true
      this.isRemove = false
      this.checkedAll = false

      const data = {
        KeyWord: null,
        BookingCode: this.BookingCode,
        BookingId: null,
        GroupName: null,
        OpenDate: this.OpenDate,
        BDC: [],
      }

      const response = await booking.getDetailBooking2A(data)
      this.isLoading2A = false
      if (response.Status === '200') {
        this.dataDetail2A = response?.Data?.TeeTime
        await this.getDetailBooking2B(this.bookingDetailId)
      }
    },
    openDetailBookingList() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-list')
      this.BookingCode = this.KeyWordDataProps.BookingCode
      this.OpenDate = this.KeyWordDataProps.OpenDate
      this.bookingDetailId = this.KeyWordDataProps.BookingDetailId
      this.BookingId = this.KeyWordDataProps.BookingId
      this.getDetailBooking2ABookingList()
      this.getRPM01(this.bookingDetailId)
    },
    undoCheckout() {
      const dataConfirm = {
        dataConfirm: {
          title: this.$t('golf_common_confirm_undo_checkout'),
          content: this.$t('golf_common_confirm_undo_checkout'),
          body: {
            BookingDetailId: [this.dataDetail2B.BookingDetail.Id],
          },
        },
      }
      // Hiển thị xác nhận undo checkout
      this.$refs.confirmUndoCheckout.open(dataConfirm)
    },
    async confirmUndoCheckout_ICI03(dataConfirm) {
      const response = await booking.api_ICI03(dataConfirm.dataConfirm.body)
      this.showResToast(response)
      if (response.Status == 200) {
        if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
          this.getDetailBooking2A()
        } else {
          this.getDetailBooking2ASearch()
        }
        this.$emit('event', { type: 'after-undo-checkout' })
      }
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    clearData() {
      this.dataDetail2A = [],
      this.dataDetail2B = {
        GroupName: '',
        ClientName: '',
        SourceId: '',
        BagtagCode: '',
        TotalNumberOfGolfer: 0,
        ClientId: '',
        TeeName: '',
        RepresentativeNote: '',
        BookingDetail: {
          FullName: '',
          FirstName: '',
          BookingCode: '',
          LastName: '',
          MemberTypeId: '',
          Email: '',
          PhoneNumber: '',
          GolfClassName: '',
          RateName: '',
          PackageName: '',
          CaddyName: '',
          CaddyId: '',
          MemberId: '',
          Avatar: '',
        },
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === P) {
        this.FrintBDCinBookingDetail()
      }
      if (payload.evt.ctrlKey && payload.key === A) {
        if (this.dataDetail2A.length > 0) {
          this.checkedAll = !this.checkedAll
          this.checkAll(this.checkedAll)
        }
      }
      if (payload.evt.ctrlKey && payload.key === F) {
        this.$refs.listBDC.$refs.search.focus()
      }
      if (payload.key === F1) {
        this.isRemove = !this.isRemove
      }
      if (payload.key === F2) {
        const listChekcin = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked === true) {
                listChekcin.push(z.Id)
              }
            })
          })
        })
        if (this.dataDetail2B.Id) {
          if (this.checkedAll || listChekcin.length > 0) {
            this.openModalCheckIn(false)
          } else {
            this.openModalCheckIn(true)
          }
        }
      }
      if (payload.key === F3) {
        const listCheckout = []
        this.dataDetail2A.forEach(x => {
          x.Booking.forEach(y => {
            y.BookingDetail.forEach(z => {
              if (z.checked === true) {
                listCheckout.push(z.Id)
              }
            })
          })
        })
        if (this.dataDetail2B.Id) {
          if (this.checkedAll || listCheckout.length > 0) {
            this.openSidebarCheckout(false)
          } else {
            this.openSidebarCheckout(true)
          }
        }
      }
      if (payload.key === F4) {
        if (this.dataDetail2B.Id) {
          this.openModal('UBK01', 'modal-update-booking')
        }
      }
      if (payload.evt.ctrlKey && payload.key === S) {
        this.addNewMemberToDetailBooking_IME01()
      }
    },
    FrintBDCinBookingDetail() {
      const listBDC = []
      this.dataDetail2A.forEach(x => {
        x.Booking.forEach(y => {
          y.BookingDetail.forEach(z => {
            if (z.checked === true) {
              listBDC.push(z.Id)
            }
          })
        })
      })
      if (listBDC.length > 0) {
        this.PrintBDC(listBDC)
      } else {
        this.showToast('error', 'Vui lòng chọn ít nhất một khách để in BDC')
      }
    },
    printBDCinCheckIn(data) {
      this.PrintBDC(data.data)
    },
    PrintBDC(data) {
      this.isLoadPrintBDC = true
      const boby = {
        BookingDetailId: data,
      }
      bookingPrint
        .getListDetailBooking(boby)
        .then(res => {
          if (res) {
            this.BookingDetailPrint = res.Data.BookingDetail
            setTimeout(() => {
              const divContents = document.getElementById('print-tem').innerHTML
              const data = `<html><head><title>ezCloud_ezGolf</title> <meta charset="UTF-8">
              </head><body style="padding: 0px;margin: 0px;">
              <style >  @media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} p{margin:0;}} </style>
              ${divContents}
              </body></html>
              `
              const newBlob = new Blob([data], {
                type: 'text/html',
              })
              const fileLink = window.URL.createObjectURL(newBlob)
              const iframe = document.createElement('iframe')
              iframe.src = fileLink
              iframe.style.display = 'none'
              iframe.onload = () => {
                iframe.contentWindow.focus()
                iframe.contentWindow.print()
              }
              document.body.appendChild(iframe)
              this.isLoadPrintBDC = false
            }, 2000)
          }
        })
    },
    CopyText(text) {
      document.body.insertAdjacentHTML('beforeend', `<div id="copy" contenteditable>${text}</div>`)
      document.getElementById('copy').focus()
      document.execCommand('selectAll')
      document.execCommand('copy')
      document.getElementById('copy').remove()
    },
    inputRate_RRA04A() {
      this.checkInputRRA04A = true
      this.updateBookingByInputRRA04A_UBK02()
    },
    async updateBookingByInputRRA04A_UBK02() {
      const params = {
        BookingDetail: {
          Id: this.dataDetail2B.BookingDetail.Id,
          ClientId: this.Selected_Rate_RRA04A.ClientId,
          SourceId: this.Selected_Rate_RRA04A.SourceId,
          GolfClassId: this.Selected_Rate_RRA04A.GolfClassId,
          TeeTimeId: this.dataDetail2B.BookingDetail.TeeTimeId,
          RateId: this.Selected_Rate_RRA04A.Id,
          CardCode4GolfClassRule: null,
          Bdc4golfClassRule: null,
        },
      }
      await booking.updateTeeTimeForBooking(params).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          if (!this.listBDC.length > 0 && !this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
            this.getDetailBooking2A()
          } else {
            this.getDetailBooking2ASearch()
          }
        }
      })
    },
  },
}

export const member_params = {
  Avatar: null,
  FirstName: null,
  LastName: null,
  Aliases: null,
  MemberType: "MEMBER",
  MemberCode: null,
  Greeting: null,
  Birthday: null,
  SexCode: null,
  PhoneNumber: null,
  Fax: null,
  NationalityCode: null,
  Zipcode: null,
  Email: null,
  City: null,
  Address: null,
  Address1: null,
  Passport: null,
  Visa: null,
  VisaIssueDate: null,
  VisaCardIssuer: null,
  IdentityCard: null,
  IdentityIssueDate: null,
  IdentityCardIssuer: null,
  CompanyId: 1,
  Job: null,
  Position: null,
  Note: null,
  CreateBy: null,
  CreateDate: null,
  UpdateBy: null,
  UpdateDate: null,
  InActive: false,
  IsDelete: false
}

<template>
  <div>
    <b-modal
      id="modal-combine-group"
      size="md"
      centered
      v-model="isOpen"
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0 text-primary">
            {{ $t('golf_booking_combine_group') }}
          </h4>
          <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer"
            size="24"
            color="#114A9F"
            @click="hideModalCombineGroup"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="names"
                  rules="required"
                >
                  <v-select
                    v-model="groupName.selected"
                    label="Name"
                    :options="listGroup"
                    class="bg-white"
                    placeholder="Group name"
                    :clearable="false"
                  >
                    <!-- <template #selected-option="{ GroupName, BookingCode }">
                      <span style="color: #114A9F">{{ `#${BookingCode}` }}</span> - {{ GroupName }}
                    </template>
                    <template v-slot:option="option">
                      <span style="color: #81d054">{{ `#${option.BookingCode}` }}</span> - {{ option.GroupName }}
                    </template> -->
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="sources.selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="sources.list"
                  :disabled="true"
                  :placeholder="$t('golf_booking_source')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <v-select
                  v-model="companies.selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="companies.list"
                  :disabled="true"
                  :placeholder="$t('golf_booking_ta')"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group>
                <v-select
                  v-model="sales.selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="sales.list"
                  :disabled="true"
                  :placeholder="$t('golf_booking_combine_group_staff_Sale')"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-form-textarea
                  v-model="note"
                  :placeholder="$t('golf_booking_note')"
                  rows="3"
                  no-resize
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="save"
          >
            {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { booking } from '@/api/booking'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
export default {
  name: 'CombineGroup',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ShortKey
  },
  props: [
    'data', // 'listGroup',
  ],
  data() {
    return {
      listGroup: [],
      groupName: {
        list: [],
        selected: null,
      },
      sources: {
        list: [],
        selected: null,
      },
      companies: {
        list: [],
        selected: null,
      },
      sales: {
        list: [],
        selected: null,
      },
      note: '',
      isOpen: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S]
    }
  },
  watch: {
    data(value) {
      if (value.BookingDetail.Id) {
        this.groupName.selected = {
          BookingId: this.data.Id,
          Name: this.data.GroupName,
          BookingDetailId: this.data.BookingDetail.Id,
        }
        this.getListGroup_RBK06()
      }
    },
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-combine-group",IsActive:val});
    }
  },
  async mounted() {
  },
  methods: {
    async getListGroup_RBK06() {
      const response = await booking.api_RBK06({
        KeyWord: null,
        Date: this.data.BookingDetail.OpenDateTime,
        BookingIdMerge: this.data.Id,
      })
      if (response.Status === '200') {
        this.listGroup = response?.Data?.Data
      }
    },
    hideModalCombineGroup() {
      this.$bvModal.hide('modal-combine-group')
    },
    async save() {
      // console.log(this.groupName.selected)
      const requestData = {
        BookingId: this.groupName.selected.BookingId,
        BookingDetailId: this.groupName.selected.BookingDetailId,
        BookingIdMerge: this.data.Id,
      }

      const response = await booking.combineGroup(requestData)
      this.showResToast(response)
      if (response.Status === '200') {
        this.$emit('event', { type: 'after-combine-group' })
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-combine-group')
      })
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.save()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.spacing {
  margin: 4px 0;
}
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 400px;
  }
}

.color-warning {
  color: #114A9F;
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}

.card-body {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5D5F65;
  }
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>

import { render, staticRenderFns } from "./SplitGroup.vue?vue&type=template&id=09db8548&scoped=true"
import script from "./SplitGroup.vue?vue&type=script&lang=js"
export * from "./SplitGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09db8548",
  null
  
)

export default component.exports
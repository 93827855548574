/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_PACKAGE_LIST,
  ADD_MEW_MASTER_PACKAGE,
  GET_DETAIL_PACKAGE,
  ENABLE_PACKAGE,
  DISABLE_PACKAGE,
  UPDATE_MASTER_PACKAGE,
  ADD_NEW_PACKAGE_COURSE,
  UPDATE_PACKAGE_COURSE,
  DELETE_PACKAGE_COURSE,
  ADD_NEW_PACKAGE_ADDITIONAL_FEE,
  UPDATE_PACKAGE_ADDITIONAL_FEE,
  DELETE_PACKAGE_ADDITIONAL_FEE,
  DELETE_PACKAGE,
  SET_DEFAULT_PACKAGE_COURSE,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getPackageList(data) {
  return fetch(ROOT + GET_PACKAGE_LIST, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function andNewMasterPackage(data) {
  return fetch(ROOT + ADD_MEW_MASTER_PACKAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateMasterPackage(data) {
  return fetch(ROOT + UPDATE_MASTER_PACKAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailPackage(data) {
  return fetch(ROOT + GET_DETAIL_PACKAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function enablePackage(data) {
  return fetch(ROOT + ENABLE_PACKAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function disablePackage(data) {
  return fetch(ROOT + DISABLE_PACKAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deletePackage(data) {
  return fetch(ROOT + DELETE_PACKAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewPackageCourse(data) {
  return fetch(ROOT + ADD_NEW_PACKAGE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updatePackageCourse(data) {
  return fetch(ROOT + UPDATE_PACKAGE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deletePackageCourse(data) {
  return fetch(ROOT + DELETE_PACKAGE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewPackageAdditionalFee(data) {
  return fetch(ROOT + ADD_NEW_PACKAGE_ADDITIONAL_FEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updatePackageAdditionalFee(data) {
  return fetch(ROOT + UPDATE_PACKAGE_ADDITIONAL_FEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deletePackageAdditionalFee(data) {
  return fetch(ROOT + DELETE_PACKAGE_ADDITIONAL_FEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function setDefaultPackageCourse(data) {
  return fetch(ROOT + SET_DEFAULT_PACKAGE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const packageSetting = {
  getPackageList,
  andNewMasterPackage,
  getDetailPackage,
  enablePackage,
  disablePackage,
  deletePackage,
  updateMasterPackage,
  addNewPackageCourse,
  updatePackageCourse,
  deletePackageCourse,
  addNewPackageAdditionalFee,
  updatePackageAdditionalFee,
  deletePackageAdditionalFee,
  setDefaultPackageCourse,
}

import { members } from '@/api/members'
import { booking } from '@/api/booking'
import { courseSetting } from '@/api/course-setting'
import { report } from '@/api/report'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import dayjs from 'dayjs'
import { convertUTC } from '@/@core/utils/utils'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'       
import SidebarDetailBooking from './SidebarDetailBooking/SidebarDetailBooking.vue'
import { starter } from '@/api/starter'

const dataGridRef = 'tablePackage'

export default {
  data() {
    return {
      keyWord: {
        StartDate: this.getUTC('start', 'day'),
        EndDate: null,
        BookingCode: null,
        ClientId: null,
        SourceId: null,
        CourseId: null,
        CardCode: null,
        BookingStatus: null,
      },
      companies: [],
      source: [],
      EndDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0)
        .format('YYYY-MM-DDTHH:mm:ss'),
      courses: [],
      listBooking: [],
      listBookingByJson: null,
      caddy: '',
      caddyName: '',
      caddyCode: '',
      isSelect: false,
      obj: null,
      json: null,
      isLoading: false,
      isBusyTable: false,
      currentPage: 1,
      perPage: 50,
      dataGridRef: 'tablePackage',
      optionPerPage: [10, 50, 100],
      configTable: [
        {
          caption: this.$t('golf_common_booking_code'),
          field: 'BookingCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_boongking_group_name'),
          field: 'GroupName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_list_booking_company'),
          field: 'ClientName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_booking_source'),
          field: 'SourceName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'BDC',
          field: 'BDC',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'Locker',
          field: 'LockerCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'BagTag',
          field: 'BagtagCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_booking_starter_group'),
          field: 'StarterGroupCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_member_card_code_2'),
          field: 'CardCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_common_first_and_last_name'),
          field: 'MemberName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Ngày chơi',
          field: 'OpenDate',
          isShow: true,
          cellTemplate: 'open-date',
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'Tee Time',
          field: 'TeeTime',
          isShow: true,
          cellTemplate: 'tee-time',
          alignment: 'center',
          width: '7%',
        },
        {
          caption: this.$t('golf_starter_tee_off'),
          field: 'TeeOff',
          isShow: true,
          cellTemplate: 'tee-off',
          alignment: 'center',
          width: '7%',
        },
        {
          caption: this.$t('golf_starter_finish'),
          field: 'Finish',
          isShow: true,
          cellTemplate: 'Finish',
          alignment: 'center',
          width: '7%',
        },
        {
          caption: 'Round',
          field: 'Round',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'Golf Class',
          field: 'GolfClassName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Trạng thái',
          field: 'BookingStatus',
          isShow: true,
          alignment: 'center',
          width: '10%',
        }, {
          caption: 'Caddy',
          field: 'Caddy',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_starter_syn_status'),
          field: 'SgmsSynchronized',
          isShow: JSON.parse(localStorage.getItem('module')).find(x => x == "SGMS_BUGGY") ? true : false,
          cellTemplate: 'syn_status',
          alignment: 'center',
          allowEditing: false,
        },
        {
          caption: this.$t('golf_starter_syn_date'),
          field: 'SgmsSynDate',
          isShow: JSON.parse(localStorage.getItem('module')).find(x => x == "SGMS_BUGGY") ? true : false,
          cellTemplate: 'syn_date',
          alignment: 'center',
          allowEditing: false,
        },
        {
          caption: 'Ghi chú',
          field: 'Note',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
      ],
      timeZoneOffSet: localStorage.getItem('timeZoneOffset'),
      KeyWordDataProps: {
        BookingId: null,
        BookingDetailId: null,
        KeyWord: null,
        BookingCode: null,
        GroupName: null,
        OpenDate: null,
        BDC: [],
      },
      optionBookingStatus: [
        {
          value: 'BOOKED',
          label: 'Booked',
        },
        {
          value: 'CHECK_IN',
          label: 'Check in',
        },
        {
          value: 'CHECK_OUT',
          label: 'Check out',
        },
        {
          value: 'CANCELLED',
          label: 'Hủy',
        },
        {
          value: 'NO_SHOW',
          label: 'No show',
        },
      ],
      ROLE_OF_SALE: null,
      ROLE_OF_GM: null,
      ROLE_OF_CASHIER: null,
      defaultCheckIn: null,
      defaultPrintBDCinCheckIn: null,
      listUserOfSale: null,
      listUserOfGM: null,
      listUserOfCashier: null,
      windowHeight: 0,
      heightTable: 400
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    SidebarDetailBooking,
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  async created() {
    await this.getListCourse_RCO01()
    this.getRoleId()
    this.getListCompany_RCL01()
    this.getListResources_RSO01()
    this.getListBooking_RF01()
  },
  watch: {
    ROLE_OF_SALE(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_GM(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_CASHIER(value) {
      this.getCashierRole(value)
    },
    windowHeight(value) {
      if(value > 600 ) {
        this.heightTable = value - 330
      }
    }
  },
  mounted() {
    this.windowHeight = window.innerHeight
  },
  methods: {
    dayjs,
    // lấy danh sách TA
    async getListCompany_RCL01() {
      const response = await members.getCompanies()
      if (response.Status === '200') {
        this.companies = response?.Data?.Client
      }
    },
    // lấy danh sách nguồn
    async getListResources_RSO01() {
      const response = await booking.getListResources()
      if (response.Status === '200') {
        this.source = response?.Data?.Source
      }
    },
    // lấy danh sách sân
    async getListCourse_RCO01() {
      const response = await courseSetting.getListCourse()
      if (response.Status === '200') {
        this.courses = response?.Data?.Course
        this.keyWord.CourseId = this.courses.map(x => x.Id)
      }
    },
    // get api RF01
    async getListBooking_RF01() {
      this.keyWord.EndDate = dayjs(this.EndDate).add(1, 'day').add(-this.timeZoneOffSet, 'minute').format('YYYY-MM-DDTHH:mm:ss')
      this.isLoading = true
      const body = this.keyWord
      await report.API_RF01(body).then(response => {
        this.listBooking = response.Data.BookingDetail
        if (response.Status == 200) {
          this.listBooking = response.Data.BookingDetail
        } else {
          this.listBooking = []
        }
      })
      this.isLoading = false
    },
    // function search khi ấn tìm kiếm
    search() {
      this.getListBooking_RF01()
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Booking')
      const timeZone = localStorage.getItem('timeZone')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function(options) {
          const { gridCell, excelCell } = options;
          if(gridCell.rowType === 'data') {
            if(gridCell.column.dataField == 'OpenDate') {
              excelCell.value =  convertUTC(gridCell.value,'DD/MM/YYYY HH:mm')
            }
            if(gridCell.column.dataField == 'TeeOff') {
              excelCell.value =  convertUTC(gridCell.value,'HH:mm')
            }
            if(gridCell.column.dataField == 'Finish') {
              excelCell.value =  convertUTC(gridCell.value,'HH:mm')
            }
      } }
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = `DANH SÁCH BOOKING (từ ${this.convertUTC(this.keyWord.StartDate, 'DD/MM/YYYY')} đến ${this.convertUTC(this.keyWord.EndDate, 'DD/MM/YYYY')})`
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Booking.xlsx')
        })
      })
    },
    viewDetailBooking(item) {
      if(item.column.dataField != 'SgmsSynchronized') {
        this.KeyWordDataProps.BookingId = item.data.BookingId
        this.KeyWordDataProps.BookingDetailId = item.data.Id
        this.KeyWordDataProps.OpenDate = item.data.OpenDate
        this.KeyWordDataProps.BookingCode = item.data.BookingCode
        this.$refs.SidebarDetailBookingList.openDetailBookingList()
      }
    //   console.log(item)
      // this.KeyWordDataProps.BDC.push(item.data.BDC)
      
    },
    async getRoleId() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.ROLE_OF_SALE = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_SALE').KeyValue)
          this.ROLE_OF_GM = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_GM').KeyValue)
          this.ROLE_OF_CASHIER = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_CASHIER').KeyValue)
          this.defaultCheckIn = res?.Data.find(item => item.KeyCode === 'DEFAULT_CHECKIN').KeyValue
          this.defaultPrintBDCinCheckIn = res?.Data.find(item => item.KeyCode === 'PRINT_BDC').KeyValue
        }
      })
    },
    async getCashierRole(Id) {
      const body = {
        RoleId: [Id],
      }
      await booking.api_RCS01(body).then(res => {
        if (res.Status === '200') {
          if (Id === this.ROLE_OF_SALE) {
            this.listUserOfSale = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
          if (Id === this.ROLE_OF_GM) {
            this.listUserOfGM = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
          if (Id === this.ROLE_OF_CASHIER) {
            this.listUserOfCashier = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
        }
      })
    },
    convertUTCRF01(value, format) {
      if (value) {
        if (value.search(/[^.]*$/) === 0) {
          // Không có chữ Z thì mới add Z
          if (value.search(/[^Z]*$/) === 0) {
            value += 'Z'
          }
        } else {
        // Nếu không tìm thấy Z mới add thêm Z
        // eslint-disable-next-line no-lonely-if
          if (value.search(/[^Z]*$/) === 0) {
            value = value.replace(/[^.]*$/, 'ABC').replace('.ABC', 'Z')
          }
        }
    
        const timeZone = localStorage.getItem('timeZone')
    
        if (format) {
        // return this.dayjs(value).utc().tz(timeZone).format()
    
          return dayjs(value).tz(timeZone).format(format)
        }
      }
      // return dayjs(date).toDate()
    },
    printTeeSheet() {
      const routeData = this.$router.resolve({ name: 'PrintTeeSheet', params: { time: this.keyWord.StartDate }, query: { C: JSON.stringify(this.keyWord.CourseId) } })
      window.open(routeData.href, '_blank')
    },
    async reSynSgms(data) {
      const body = {
        MessageId: data
      }
      await starter.api_BG_UBK01S(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.search()
        }
      })
    },
    async SynSgms(data) {
      const body = {
        MessageId: null,
        BookingDetailId: data
      }
      await starter.api_BG_UBK01S(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.search()
        }
      })
    },
  },
}

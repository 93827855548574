<template>
  <div>
    <b-modal
      id="modal-update-hole"
      v-model="isOpen"
      size="lg"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <div class="d-flex justify-content-start align-items-center">
            <p class="mb-0 title-modal color-primary mr-1">
              {{ $t('golf_booking_update_hole') }}
            </p>
          </div>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModalUpdateHole"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <div class="intro-wrapper">
          <p class="mb-0 text-intro">
            {{ $t('golf_booking_list_member_use_package') }}
          </p>
        </div>

        <b-row>
          <b-col md="10">
            <div class="box">
              <div class="header">
                <div class="user-name-wrapper">
                  <div
                    :style="{ background: data.BookingDetail.GolfClassColor }"
                    class="dot-size-10 d-inline-block rounded-circle"
                  />
                  <span>{{ data.BookingDetail.FullName }} </span>
                </div>
                <div>
                  <p class="info-tee">
                    {{ this.showInfoTee() }}
                  </p>
                </div>
              </div>
              <div
                v-if="!isLoading"
                class="body"
              >
                <div class="spacing-4">
                  <b-form-group
                    label-cols="9"
                    :label="$t('golf_starter_total_hole')"
                    label-for="number-of-holes"
                  >
                    <treeselect
                      v-if="detailHole"
                      v-model="numberOfHole"
                      :options="optionHole"
                      :default-expand-level="1"
                      :clearable="false"
                      :multiple="false"
                      :clear-on-select="false"
                      @input="changeHole"
                    />
                  </b-form-group>
                </div>
                <div class="spacing-4">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0">
                      {{ $t('golf_booking_update_hole_line') }}
                      <feather-icon
                        icon="AlertCircleIcon"
                        class="cursor-pointer"
                        size="18"
                      />
                    </p>
                    <div>
                      <div
                        v-if="linePlay.filter(x => x.Id).length > 0"
                        class="value"
                      >
                        <span
                          v-for="item in linePlay"
                          :key="item.Id"
                        >
                          <template v-if="item.Id">
                            {{ item.TeeName }}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="detailHole"
                  class="d-flex mt-1"
                >
                  <div
                    v-for="(i, index) in (numberOfHole / 9)"
                    :key="i"
                    class="d-tee-select mr-1"
                  >
                    <div
                      v-for="tee in detailHole.Tee"
                      :key="tee.Id"
                      class="tee mb-1"
                    >
                      <!-- {{ linePlay[index] }} -->
                      <!-- :variant="linePlay[index].Id && linePlay[index].Id == tee.Id ? 'primary' : 'outline-primary'" -->
                      <b-button
                        class="btn-tee"
                        :variant="linePlay[index].Id && linePlay[index].Id == tee.Id ? 'primary' : 'outline-primary'"
                        @click="chooseTee(tee, index)"
                      >
                        {{ tee.TeeName }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <b-table-simple>
                  <b-thead>
                    <b-tr>
                      <b-th>
                        Sân
                      </b-th>
                      <b-th>
                        Số hố đặt
                      </b-th>
                      <b-th  v-if="showActualNumberOfHole == 1">
                        Số hố thực tế
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(course, i) in detailHole.BookingDetailCourse"
                      :key="i"
                    >
                      <b-th>
                        {{ $t('golf_booking_copy_number_hole') }} {{ course.CourseName }}
                      </b-th>
                      <b-th>
                        {{ course.NumberOfHole }} hole
                      </b-th>
                      <b-th v-if="showActualNumberOfHole == 1">
                        <v-select
                          v-model="course.ActualNumberOfHole"
                          :options="course.ListHole"
                          :multiple="false"
                          :clearable="false"
                          @input="EditActualNumberOfHole(course)"
                          :disabled="CourseIdEdit ? (CourseIdEdit != course.CourseId) : false"
                        >
                          <template #selected-option="{label}">
                            {{ label }} Hole
                          </template>
                          <template v-slot:option="item">
                            {{ item.label }} Hole
                          </template>
                        </v-select>
                      </b-th>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-row v-if="isShowSelectType && showActualNumberOfHole == 1">
                  <b-col md="7">
                    <b-form-group label="Loại phụ phí" label-cols="3">
                      <v-select
                        v-model="AdditionalTypeSelect"
                        :options="listAdditionalType"
                        label="Name"
                        :multiple="false"
                        :clearable="false"
                        :reduce="item => item.Code"
                      >
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- <div
                  v-for="(course, i) in detailHole.BookingDetailCourse"
                  v-if="detailHole.BookingDetailCourse"
                  :key="i"
                  class="d-flex mt-2"
                >
                  <div class="mr-auto">
                    {{ $t('golf_booking_copy_number_hole') }} {{ course.CourseName }}
                  </div>
                  <div>{{ course.NumberOfHole }} hole</div>
                </div> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="save()"
          >
          {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>

      <!-- <div
        class="divider my-0"
        style="background: rgba(193, 193, 193, 0.12);"
      >
&nbsp;
      </div>

      <b-card-body>
        <div class="intro-wrapper">
          <p class="mb-0 text-intro">
            {{ $t('golf_booking_use_package') }}
          </p>
        </div>

        <b-row>
          <b-col
            md="12"
            class="p-0"
          >
            <b-table
              bordered
              responsive="sm"
              :items="items"
              thead-class="d-none"
            />
          </b-col>
        </b-row>
      </b-card-body> -->
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
    <confirm-dialog
      ref="confirmUpdateCaddy"
      @confirmDialog="updateCaddy"
    />
    <confirm-dialog
      ref="confirmUpdateHole"
      @confirmDialog="save(true)"
    />
  </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import VueSlider from 'vue-slider-component'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import {
  BTable, BCardHeader, BFormGroup, BFormInput, BModal, BCardBody, BForm, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { booking } from '@/api/booking'
import { starter } from '@/api/starter'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
export default {
  name: 'UpdateHole',
  components: {
    BModal,
    BCardHeader,
    BFormInput,
    BFormGroup,
    BCardBody,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    BTable,
    vSelect,
    VueSlider,
    Treeselect,
    ShortKey,
    'confirm-dialog': ConfirmDialog,
  },
  props: [
    'data',
    'autoUpdateCaddy',
    'showActualNumberOfHole',
    'listAdditionalType'
  ],
  data() {
    return {  
      value: 10,
      items: [
        { id: '#1', teeName: '18 Hole King, 9 Hole Queen' },
      ],
      isOpen: false,
      // Chi khi model UpdateHole duoc mo ra moi co gia tri
      detailHole: {
        BookingDetailCourse: [],
      },
      optionHole: [],
      linePlay: [],
      numberOfHole: 9,
      isLoading: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      CourseIdEdit: null,
      AdditionalTypeSelect: null,
      isShowSelectType: false
    }
  },
  watch: {
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-update-hole",IsActive:val});
      this.CourseIdEdit = null
      this.isShowSelectType = false
    },
    // numberOfHole(val) {
    //   if (val) {
    //     this.linePlay = []
    //     for (let index = 0; index < (val / 9); index++) {
    //       this.linePlay.push(
    //         index,
    //         // {
    //         //   Id: -1,
    //         //   TeeName: '',
    //         // },
    //       )
    //     }
    //     this.detailHole.BookingDetailCourse = []
    //   }
    // },
  },
  async mounted() {
    this.isLoading = true
    for (let index = 1; index <= 6; index++) {
      this.optionHole.push({
        id: index * 9,
        label: index * 9,
      })
    }
    this.doubleRaf(async () => {
      this.isLoading = false
    })
  },
  methods: {
    open() {
      if (!this.checkPermission('UBK02B')) {
        return
      }
      this.getDetailHole()
      this.isOpen = true
      this.AdditionalTypeSelect = null
    },
    async getDetailHole() {
      this.isLoading = true
      const data_request = {
        BookingDetailId: this.data.BookingDetail.Id,
      }

      await booking.api_RBK09(data_request).then(res => {
        if (res.Status === '200') {
          this.detailHole = res.Data
          this.numberOfHole = this.detailHole.Line.length * 9
          this.linePlay = []
          this.CourseIdEdit = null
          this.isShowSelectType = false
          this.detailHole.Line.forEach(item => {
            this.linePlay.push({
              Id: item,
              TeeName: this.detailHole.Tee.find(x => x.Id == item).TeeName,
              CourseName: this.detailHole.Tee.find(x => x.Id == item).CourseName,
              CourseId: this.detailHole.Tee.find(x => x.Id == item).CourseId,
            })
          })

          const BookingInfoCourse = []
          this.linePlay.forEach(x => {
            if (BookingInfoCourse.filter(y => y.CourseId == x.CourseId).length == 0) {
              BookingInfoCourse.push({
                CourseId: x.CourseId,
                NumberOfHole: 9,
                CourseName: x.CourseName,
              })
            } else {
              BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole = BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole + 9
            }
          })
          var checkEdit = false
          BookingInfoCourse.forEach(x => {
            const listHole = []
            for(let i = 0; i < x.NumberOfHole; i++) {
              listHole.push(i + 1)
            }
            x.ListHole = listHole
            x.ActualNumberOfHole = this.detailHole.BookingDetailCourse.find(y => y.CourseId == x.CourseId) ? (this.detailHole.BookingDetailCourse.find(y => y.CourseId == x.CourseId).ActualNumberOfHole ? this.detailHole.BookingDetailCourse.find(y => y.CourseId == x.CourseId).ActualNumberOfHole : x.NumberOfHole )  : x.NumberOfHole
            if(x.NumberOfHole != x.ActualNumberOfHole) {
              checkEdit = true
              this.isShowSelectType = true
              this.CourseIdEdit = x.CourseId
            }
          })
          if(!checkEdit) {
            this.isShowSelectType = false
              this.CourseIdEdit = null
          }
          this.detailHole.BookingDetailCourse = BookingInfoCourse
          this.AdditionalTypeSelect = res.Data.RateAdditionalType4ActualHole
        }
      })
      this.isLoading = false
    },
    changeHole() {
      this.linePlay = []
      for (let index = 0; index < (this.numberOfHole / 9); index++) {
        this.linePlay.push(
          index,
        )
      }
      this.detailHole.BookingDetailCourse = []
      this.CourseIdEdit = null
      this.isShowSelectType = false
    },
    chooseTee(tee, index) {
      this.linePlay[index] = tee

      const BookingInfoCourse = []
      this.linePlay.forEach(x => {
        if (BookingInfoCourse.filter(y => y.CourseId == x.CourseId).length == 0) {
          BookingInfoCourse.push({
            CourseId: x.CourseId,
            NumberOfHole: 9,
            CourseName: x.CourseName,
          })
        } else {
          BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole = BookingInfoCourse.find(y => y.CourseId == x.CourseId).NumberOfHole + 9
        }
      })
      BookingInfoCourse.forEach(x => {
        const listHole = []
        for(let i = 0; i < x.NumberOfHole; i++) {
          listHole.push(i + 1)
        }
        x.ListHole = listHole
        x.ActualNumberOfHole = x.NumberOfHole
      })
      this.detailHole.BookingDetailCourse = BookingInfoCourse
      this.CourseIdEdit = null
      this.isShowSelectType = false


      this.$forceUpdate()
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    hideModalUpdateHole() {
      this.$bvModal.hide('modal-update-hole')
    },
    save(checkLock) {
      if (!this.detailHole.BookingDetailCourse.length || this.linePlay.filter(x => x.Id).length != this.numberOfHole / 9) {
        this.showToast('error', this.$t('golf_booking_please_select_line_ball'))
        return
      }
      if(this.showActualNumberOfHole == 1 && this.CourseIdEdit && !this.AdditionalTypeSelect) {
        this.showToast('error', this.$t('golf_booking_please_select_additional_type'))
        return
      } 
      const BookingDetailCourse = this.detailHole.BookingDetailCourse.map(x => ({
        Id: x.Id,
        CourseId: x.CourseId,
        NumberOfHole: +x.NumberOfHole,
        ActualNumberOfHole: +x.ActualNumberOfHole,
      }))
      const body = {
        BookingDetail: {
          Id: this.data.BookingDetail.Id,
          Line: this.linePlay.map(x => x.Id),
          BookingDetailCourse
        },
		RateAdditionalType: this.AdditionalTypeSelect
      }
      if(checkLock) {
        body.IsLock = false
      }
      starter
        .api_UBK02B(body)
        .then(res => {
          if (res) {
            this.$nextTick(() => {
              if(res.Status === '200') {
                  if(this.data.BookingDetail.BookingDetailCaddy.length === 1) {
                    const dataConfirm = {
                        dataConfirm: {
                          title: 'Cập nhật Caddy',
                          content: 'Bạn có muốn cập nhật số hố caddy?',
                          data: this.data.BookingDetail.BookingDetailCaddy[0]
                        },
                      }
                    if(this.autoUpdateCaddy == 0) {
                      this.$refs.confirmUpdateCaddy.open(dataConfirm)
                    } 
                } else if (this.data.BookingDetail.BookingDetailCaddy.length > 1) {
                  this.showToast('error', `Có ${this.data.BookingDetail.BookingDetailCaddy.length} caddy phục vụ. Vui lòng cập nhật số hố caddy`)
                  this.showToast('error', this.$t('golf_booking_error_caddy_working_in_booking').replace('{caddy}',this.data.BookingDetail.BookingDetailCaddy.length))
                } 
              } else if (res.Status == '400' && res.Messages.length > 0) {
                 if (res.Messages[0].Code == "E.UBK02B.C18") {
                  const dataConfirm = {
                      dataConfirm: {
                        title: 'Cập nhật TeeTime',
                        content: 'Bạn có muốn bỏ qua khóa hố không?',
                        data: body
                      },
                    }
                    this.$refs.confirmUpdateHole.open(dataConfirm)
                }
                // this.updateBooking_UBK02(true)
              }
              this.showResToast(res)
              this.$bvModal.hide('modal-update-hole')
              this.$emit('event', { type: 'after-update-hole' })
            })
          }
        })
    },
    updateCaddy(item) {
      let payload
      {
        payload = {
          BookingDetailCaddy: {
            Id: item.dataConfirm.data.Id,
            BookingDetailId: this.data.BookingDetail.Id,
            CaddyId: Number(item.dataConfirm.data.CaddyId),
            NumberOfHole: this.numberOfHole,
            WorkingStatus: item.dataConfirm.data.WorkingStatus,
            Reasons: item.dataConfirm.data.Reasons,
            IsDeleted: item.dataConfirm.data.IsDeleted,
            BookingDetailServices: [],
          },
        }
      }

      booking.updateCaddy(payload).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-update-add-caddy' })
        }
      })
    },
    showInfoTee() {
      if (this.detailHole != null && this.detailHole.PlayerPosition) {
        return `${this.utcConvertToLocalTimeZone(this.detailHole.PlayerPosition.OpenDateTime, 'DD/MM/YYYY - HH:mm')}-${this.detailHole.PlayerPosition.TeeName}`
      }
      return ''
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.save()
      }
    },
    EditActualNumberOfHole(data) {
      if(data.ActualNumberOfHole != data.NumberOfHole) {
        this.CourseIdEdit = data.CourseId
        this.isShowSelectType = true
      } else {
        this.CourseIdEdit = null
        this.isShowSelectType = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/variables';
@import '@core/scss/vue/libs/vue-slider.scss';
#modal-update-hole {
  .modal-md {
    max-width: 800px !important;
  }
}
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    // width: 650px;
  }
}

.banned-background-color-primary {
  background: rgba(17, 74, 159, 0.12);
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.color-primary {
  color: $primary;
}

.intro-wrapper {
  margin-bottom: 18px;

  .text-intro {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #424244;
  }
}

.box {
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(17, 74, 159, 0.38);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(17, 74, 159, 0.2);
    padding: 10px 14px;
    border-radius: 6px 6px 0 0;

    .user-name-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dot {
        width: 15px;
        height: 15px;
        background-color: $primary;
        border-radius: 100%;
        margin-right: 5px;
      }

      .user-name {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }

    .info-tee {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .body {
    padding: 14px;

    .spacing-4 {
      margin: 4px 0;

      .block {
        margin: 5px 0;
      }

      .play-line-block {
        width: 34px;
        height: 28px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #D6D9E1;
        box-sizing: border-box;
        cursor: pointer;

        p {
          color: #114A9F;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .btn-tee {
    padding: 6px;
  }

  .d-select-hole {
    width: 92px;
  }
}
</style>

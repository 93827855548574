<template>
  <div>
    <b-modal
      id="modal-cancel-group-booking"
      v-model="isOpen"
      size="md"
      centered
      hide-header
      ok-only
      :ok-title="$t('golf_common_confirm')"
      ok-variant="danger"
      :no-close-on-backdrop="true"
      @ok="confirmCancel_DBK01"
    >
      <b-card-header
        class="banned-background-color"
      >
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0 text-danger">
            {{ $t('golf_booking_cancel_booking') }}
          </h4>
          <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer"
            size="24"
            color="#EA5455"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>

        <b-form-group :label="$t('golf_booking_cancel_booking_reason')">
          <b-form-group>
            <b-form-textarea
              v-model="reason"
              :placeholder="$t('golf_booking_cancel_booking_reason')"
              rows="2"
              no-resize
            />
          </b-form-group>
        </b-form-group>

        <b-form-group v-if="isShowEmail == 1">
          <b-form-checkbox v-model="sendEmail">
            <p class="mb-0">
              {{ $t('golf_booking_cancel_booking_send_email') }}
            </p>
          </b-form-checkbox>
        </b-form-group>

      </b-card-body>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>
<script>
import { booking } from '@/api/booking'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
export default {
  props: [
    'bookingId','isShowEmail'
  ],
  data() {
    return {
      sendEmail: false,
      reason: null,
      isOpen: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S]
    }
  },
  components: {
    ShortKey
  },
  watch: {
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-update-booking",IsActive:val});
      if(!val) {
        this.sendEmail = false
        this.reason = null
      }
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-cancel-group-booking')
    },
    confirmCancel_DBK01() {
      if (this.reason) {
        const dataRequest = {
          BookingId: this.bookingId,
          SendMail: this.sendMail,
          Reason: this.reason,
        }

        booking.api_DBK01(dataRequest).then(response => {
          this.showResToast(response)
          if (response.Status == '200') {
            this.$emit('event', { type: 'after-cancel-booking-group' })
            if(this.sendEmail == true) {
              this.$emit('event', { type: 'cancel_email' })
            }
          }
          this.$nextTick(() => {
            this.$bvModal.hide('modal-cancel-group-booking')
          })
        })
      } else {
        this.showToast('error', this.$t('golf_booking_cancel_please_input_reason'))
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.confirmCancel_DBK01()
      }
    }
  },
}
</script>

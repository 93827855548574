<template>
  <div>
    <b-modal
      id="modal-update-caddy"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      ok-only
      :ok-title="$t('update')"
      ok-variant="primary"
      v-model="isOpen"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <div class="d-flex justify-content-start align-items-center">
            <p class="mb-0 title-modal color-primary mr-1">
              {{ $t('golf_booking_update_caddy') }}
            </p>
          </div>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModalUpdateCaddy"
          />
        </div>
      </b-card-header>
      <div class="py-1">
        <b-table
          :items="data.BookingDetail.BookingDetailCaddy"
          :fields="fieldsCaddy"
          class="mb-0 table-update-caddy"
        >
          <template #cell(CaddyId)="data">

            <!-- <b-dropdown
              variant="outline-secondary"

              class="bg-status "
              :class="`bg-status-` + caddyList.find(e => e.Id === data.item.CaddyId).Status"
            >
              <template #button-content>
                <span>{{ caddyList.find(e => e.Id === data.item.CaddyId).CaddyCode }} - {{ caddyList.find(e => e.Id === data.item.CaddyId).CaddyName }}</span>
              </template>
              <b-dropdown-text style="right: 0">
                Chọn caddy -->
            <v-select
              v-if="keyCheckSelectDupcaddy == 0"
              v-model="data.item.CaddyId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :reduce="item => item.Id"
              :options="caddyOption"
              :selectable="(option) => option.Status == 'A' || option.Status == 'CAN BOOK'"
            >
              <template v-slot:option="item">
                <div
                  title="Member"
                  class="dot-size-10 d-inline-block rounded-circle mr-0"
                  :style="(item.Status == 'A') ? 'background-color: #29b869' : (item.Status == 'CAN BOOK') ? 'background-color: #29b869' : (item.Status == 'BUSY') ? 'background-color: #00cfe8' : 'background-color: #f8afaf'"
                />
                {{ item.label }}
              </template>
            </v-select>
            <v-select
              v-if="keyCheckSelectDupcaddy == 1"
              v-model="data.item.CaddyId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :reduce="item => item.Id"
              :options="caddyOption"
            >
              <template v-slot:option="item">
                <div
                  title="Member"
                  class="dot-size-10 d-inline-block rounded-circle mr-0"
                  :style="(item.Status == 'A') ? 'background-color: #29b869' : (item.Status == 'CAN BOOK') ? 'background-color: #29b869' : (item.Status == 'BUSY') ? 'background-color: #00cfe8' : 'background-color: #f8afaf'"
                />
                {{ item.label }}
              </template>
            </v-select>
            <!-- </b-dropdown-text>
            </b-dropdown> -->
          </template>
          <template #cell(WorkingStatus)="data">
            <v-select
              v-model="data.item.WorkingStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :reduce="item => item.key"
              :options="optionStatus"
              style="min-width: 130px"
            />
          </template>
          <template #cell(NumberOfHole)="data">
            <v-select
              v-model="data.item.NumberOfHole"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :reduce="item => item.id"
              :options="optionHole"
              style="min-width: 100px"
            />
          </template>
          <template #cell(BookingDetailService)="data">
            <div class="d-flex justify-content-center align-items-center">
              <b-form-checkbox
                v-model="data.item.IsBooked"
                :value="true"
                :disabled="caddyRaw.find( x => x.Id == data.item.Id) ? caddyRaw.find( x => x.Id == data.item.Id).IsBooked : false"
                @change="(data.item.IsBooked) ? data.item.BookingDetailServiceTotalAmount = additionalFee.list[0].ProductPrice : data.item.BookingDetailServiceTotalAmount = 0"
              />
              <cleave
                v-model="data.item.BookingDetailServiceTotalAmount"
                class="form-control"
                :raw="true"
                :options="inputMaskCurrency"
                placeholder="1,000,000"
                :disabled="true"
              />

            </div>
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="updateCaddy(data.item, false,false)"
            >
              <ez-icon
                v-if="data.item.Id"
                icon="ezGolf-icon-save"
                :size="14"
              />
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon"
              @click="updateCaddy(data.item, true,false)"
            >
              <ez-icon
                icon="ezGolf-icon-trash-2"
                :size="14"
              />
            </b-button>
          </template>
        </b-table>
        <div class="text-right p-2">
          <span>
            <span class="pr-2">
              <div
                title="Member"
                class="dot-size-10 d-inline-block rounded-circle mr-0"
                style="background-color: #29b869;"
              />
              {{ $t('golf_caddy_calendar_ready') }}
            </span>

            <span class="pr-2">
              <div
                title="Member"
                class="dot-size-10 d-inline-block rounded-circle mr-0"
                style="background-color: #3c9c67;"
              />
              {{ $t('golf_caddy_calendar_can_book') }}
            </span>

            <span class="pr-2">
              <div
                title="Member"
                class="dot-size-10 d-inline-block rounded-circle mr-0"
                style="background-color: #00cfe8"
              />
              {{ $t('golf_caddy_calendar_serve') }}
            </span>
            <span class="pr-2">
              <div
                title="Member"
                class="dot-size-10 d-inline-block rounded-circle mr-0"
                style="background-color: #f8afaf;"
              />
              {{ $t('golf_caddy_calendar_not_shift') }}
            </span>
          </span>
          <b-button
            variant="outline-primary"
            @click="visible = !visible"
          >
            <ez-icon
              icon="ezGolf-icon-user-plus"
              :size="14"
            />
            {{ $t('golf_update_add_caddy') }}
          </b-button>
          <b-collapse
            id="collapse-1"
            v-model="visible"
            class="mt-2"
          >
            <b-card class="border mb-0">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label-cols="4"
                      :label="$t('golf_booking_caddy_name')"
                      class="text-left"
                    >
                      <v-select
                        v-if="keyCheckSelectDupcaddy == 0"
                        v-model="caddy.selected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :options="caddyOption"
                        :selectable="(option) => option.Status == 'A' || option.Status == 'CAN BOOK'"
                      >
                        <template v-slot:option="item">
                          <div
                            title="Member"
                            class="dot-size-10 d-inline-block rounded-circle mr-0"
                            :style="(item.Status == 'A') ? 'background-color: #29b869' : (item.Status == 'CAN BOOK') ? 'background-color: #29b869' : (item.Status == 'BUSY') ? 'background-color: #00cfe8' : 'background-color: #f8afaf'"
                          />
                          {{ item.label }}
                        </template>
                      </v-select>
                      <v-select
                        v-if="keyCheckSelectDupcaddy == 1"
                        v-model="caddy.selected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :options="caddyOption"
                      >
                        <template v-slot:option="item">
                          <div
                            title="Member"
                            class="dot-size-10 d-inline-block rounded-circle mr-0"
                            :style="(item.Status == 'A') ? 'background-color: #29b869' : (item.Status == 'CAN BOOK') ? 'background-color: #29b869' : (item.Status == 'BUSY') ? 'background-color: #00cfe8' : 'background-color: #f8afaf'"
                          />
                          {{ item.label }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="3"
                    class="d-flex align-items-center"
                  >
                    <b-form-group class="w-100">
                      <v-select
                        v-model="NumberOfHole"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :reduce="item => item.id"
                        :options="optionHole"
                        class="w-100"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    md="3"
                    class="d-flex align-items-center"
                  >
                    <b-form-group label="">
                      <b-form-checkbox
                        v-model="feeCaddy"
                        :value="true"
                        :disabled="additionalFee.disabled"
                      >
                        <p class="text-checkbox mb-0">
                          {{ $t('golf_booking_caddy_additional_fee') }}
                        </p>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="feeCaddy">
                  <b-col md="6">
                    <b-form-group label="">
                      <v-select
                        v-model="additionalFee.selected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="ProductName"
                        :options="additionalFee.list"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="">
                      <b-form-input
                        id="basicInput"
                        v-model="amount"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-textarea
                        v-model="note"
                        :placeholder="$t('golf_booking_note')"
                        rows="2"
                        no-resize
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      variant="primary"
                      @click="addNewCaddy(false)"
                    >
                    {{ $t('golf_common_confirm') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-collapse>
        </div>
      </div>
      <confirm-dialog ref="confirmPopupUpdateDupCaddy" @confirmDialog="confirmDialogUpdateCaddy" />
    </b-modal>
  </div>
</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { booking } from '@/api/booking'
import Cleave from 'vue-cleave-component'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
export default {
  name: 'UpdateCaddy',
  components: {
    ValidationProvider, ValidationObserver, vSelect, Cleave,'confirm-dialog': ConfirmDialog,
  },
  props: ['caddyList', 'data', 'service', 'keyCheckSelectDupcaddy'],
  data() {
    return {
      caddy: {
        list: [],
        selected: null,
      },
      config: {},
      additionalFee: {
        selected: null,
        list: [],
        disabled: true,
      },
      feeCaddy: false,
      amount: 0,
      note: '',
      zoneId: [],
      optionHole: [],
      NumberOfHole: 9,
      visible: false,
      inputMaskCurrency: this.inputMaskByCurrency(),
      caddyRaw: [],
      isCheckAddtionalFeeFromRate: null,
      isOpen: false
    }
  },
  computed: {
    fieldsCaddy() {
      return [
        {
          key: 'CaddyId', label: this.$t('golf_update_caddy_caddy_name'), thStyle: { width: '20%' }, class: 'text-left',
        },
        {
          key: 'NumberOfHole', label: this.$t('golf_booking_copy_number_hole'), thStyle: { width: '10%' }, class: 'text-left',
        },
        {
          key: 'WorkingStatus', label: this.$t('golf_update_caddy_status'), thStyle: { width: '10%' }, class: 'text-left',
        },
        {
          key: 'BookingDetailService', label: this.$t('golf_update_caddy_add_fee'), thStyle: { width: '10%' }, class: 'text-left',
        },
        // {
        //   key: 'Reason', label: 'Ghi chú', thStyle: { width: '10%' },
        // },
        {
          key: 'action', label: '', class: 'text-right', thStyle: { width: '10%' },
        },
      ]
    },
    optionStatus() {
      return [
        {
          key: 'B', label: this.$t('golf_update_caddy_status_book'),
        },
        {
          key: 'W', label: this.$t('golf_update_caddy_status_working'),
        },
        {
          key: 'OUT', label: this.$t('golf_update_caddy_status_out'),
        },
      ]
    },
    caddyOption() {
      return this.caddyList.map(e => Object.assign(e, { label: `${e.CaddyCode} - ${e.CaddyName}` }))
    },
  },
  watch: {
    async isOpen(value) {
      if(value) {
        if(this.isCheckAddtionalFeeFromRate == 1) {
          const res = await this.getServiceAddtional(this.data.BookingDetail.Id, 'ADDITIONALFEE_BOOK_CADDY')
          if(res.Status == 200) {
            if(res.Data.RateAdditionalType[0].Service.length > 0) {
              this.additionalFee.list = []
              res.Data.RateAdditionalType[0].Service.forEach(x => {
                this.additionalFee.list.push({
                  ...x,
                  ...{
                    ProductPrice: x.Amount,
                    ProductName: x.ServiceName,
                    ProductId: x.ServiceId,
                    UnitId: x.UnitId,
                    TransactionCode: {
                      TransactionCode: x.TransactionCode
                    }
                  }
                })
              })
              this.additionalFee.selected = this.additionalFee.list[0]
            }
          }

        }
      }
    },
    async data(value) {
      if (value.BookingDetail.Id) {
        await this.getConfigInfo_RCOM01()
        await this.getListServicesFee_RSV02()
        if(this.isCheckAddtionalFeeFromRate == 1) {
          const res = await this.getServiceAddtional(this.data.BookingDetail.Id, 'ADDITIONALFEE_BOOK_CADDY')
          if(res.Status == 200) {
            if(res.Data.RateAdditionalType[0].Service.length > 0) {
              this.additionalFee.list = []
              res.Data.RateAdditionalType[0].Service.forEach(x => {
                this.additionalFee.list.push({
                  ...x,
                  ...{
                    ProductPrice: x.Amount,
                    ProductName: x.ServiceName,
                    ProductId: x.ServiceId,
                    UnitId: x.UnitId,
                    TransactionCode: {
                      TransactionCode: x.TransactionCode
                    }
                  }
                })
              })
              this.additionalFee.selected = this.additionalFee.list[0]
              this.additionalFee.disabled = false
            }
          }

        }
        this.NumberOfHole = this.data.BookingDetail.TotalNumberOfHole
        // Tính toán lại select hố theo cấu hình
        this.optionHole = []
        if (localStorage.getItem('numberOfHole4Caddy') == 'BY_PLAYER') {
          for (let index = 1; index <= this.data.BookingDetail.TotalNumberOfHole; index++) {
            this.optionHole.push({
              id: index,
              label: `${index} hole`,
            })
          }
        } else {
          for (let index = 1; index <= 10; index++) {
            this.optionHole.push({
              id: index * 9,
              label: `${index * 9} hole`,
            })
          }
        }

        this.caddyRaw = JSON.parse(JSON.stringify(this.data.BookingDetail.BookingDetailCaddy))
        this.$forceUpdate()
      }
    },
    'additionalFee.selected': function () {
      this.amount = this.additionalFee?.selected?.ProductPrice
    },
    amount(newValue, _) {
      if (this.additionalFee.selected) {
        this.additionalFee.selected.ProductPrice = Number(newValue)
      }
    },
  },
  created() {
    for (let index = 1; index <= 10; index++) {
      this.optionHole.push({
        id: index * 9,
        label: `${index * 9} hole`,
      })
    }
  },
  methods: {
    addCaddy() {
      this.data.BookingDetail.BookingDetailCaddy.push({
        Id: null,
        CaddyId: this.caddyList[0].Id,
        NumberOfHole: 9,
        WorkingStatus: 'B',
        BookingDetailServiceId: null,
        TotalAmount: 0,
        Reasons: null,
        IsDeleted: false,
      })
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    hideModalUpdateCaddy() {
      this.$bvModal.hide('modal-update-caddy')
      this.visible = false
      this.additionalFee.selected = false
      this.caddy.selected = null
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.isCheckAddtionalFeeFromRate = response?.Data.find(x => x.KeyCode === 'BOOKING_GET_ADDITIONAL_FEE_FROM_RATE').KeyValue
        this.config = response?.Data.find(item => item.KeyCode === 'BOOKING_CADDY_CHARGE_FEE')
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
      }
    },
    async getServiceAddtional(bkId, type) {
       const response =  await booking.api_RBK15({
        BookingDetailId: bkId, AdditionalFeeType: [type]
      })
      if(response.Status == 400) {
      this.showResToast(response)
      }
      return response
    },
    async getListServicesFee_RSV02() {
      const response = await booking.getListServicesFee({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
        Type: 'BOOKING_CADDY_CHARGE_FEE',
        BookingType: 'COURSE',
      })
      if (response.Status === '200') {
        this.additionalFee.list = response?.Data?.Menu.map(item => item.MenuDetail).flat()
        this.additionalFee.selected = response?.Data?.Menu.map(item => item.MenuDetail).flat()[0]
        if (response?.Data?.Menu.length > 0) {
          this.additionalFee.disabled = false
          this.feeCaddy = (localStorage.getItem('isDefaultCaddyChargeFee') == '1')
        }
      }
    },
    async addNewCaddy(isCheckUpdateDupCaddy) {
      if (!this.caddy.selected) {
        return
      }
      const payload = {
          BookingDetailCaddy: {
            Id: null,
            BookingDetailId: this.data.BookingDetail.Id,
            CaddyId: Number(this.caddy.selected.Id),
            NumberOfHole: this.NumberOfHole,
            WorkingStatus: 'B',
            Reasons: this.note,
            IsDeleted: false,
            IsBooked: this.feeCaddy,
            BookingDetailServices: [
              {
                BookingDetailId: this.data.BookingDetail.Id,
                ServiceId: Number(!this.feeCaddy ? 0 : this.additionalFee.selected.ProductId),
                UnitPrice: Number(!this.feeCaddy ? 0 : this.additionalFee.selected.ProductPrice),
                Quantity: Number(!this.feeCaddy ? 0 : 1),
                Amount: Number(!this.feeCaddy ? 0 : this.additionalFee.selected.ProductPrice),
                TotalAmount: Number(!this.feeCaddy ? 0 : this.additionalFee.selected.ProductPrice),
                TransactionCode: String(!this.feeCaddy ? '' : this.additionalFee.selected.TransactionCode.TransactionCode),
                RateAdditionalId: !this.feeCaddy ? null : this.additionalFee.selected.RateAdditionalId,
                RateAdditionalType: !this.feeCaddy ? null : this.additionalFee.selected.RateAdditionalType,
                RetailBy: 'RECEPTIONIST'
              },
            ],
          },
        }
     
      if(isCheckUpdateDupCaddy) {
        payload.IsForceUpdateCaddie = true
      }
      booking.updateCaddy(payload).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-add-caddy' })
          this.visible = false
          this.additionalFee.selected = false
          this.caddy.selected = null
        } else if(response.Status === '400' && response.Messages[0].Code === "E.UBK05.C07") {
          const dataConfirm = {
            dataConfirm: {
              title:  this.$t('golf_booking_update_caddy'),
              content: this.$t('golf_check_update_dup_caddy'),
              isCheckAddCaddy: true,
              body: {
                data: [this.data],
              },
            },
          }
          this.$refs.confirmPopupUpdateDupCaddy.open(dataConfirm)
        }
      })
    },
    updateCaddy(item, value, isCheckUpdateDupCaddy) {
      let payload
      if (item.IsBooked && !this.caddyRaw.find(x => x.Id == item.Id).IsBooked) {
        payload = {
          BookingDetailCaddy: {
            Id: item.Id,
            BookingDetailId: this.data.BookingDetail.Id,
            CaddyId: Number(item.CaddyId),
            NumberOfHole: item.NumberOfHole,
            WorkingStatus: item.WorkingStatus,
            Reasons: item.Reasons,
            IsDeleted: value,
            BookingDetailServices: [
              {
                BookingDetailId: this.data.BookingDetail.Id,
                ServiceId: this.additionalFee.list[0].ProductId,
                UnitPrice: this.additionalFee.list[0].ProductPrice,
                Quantity: 1,
                Amount: this.additionalFee.list[0].ProductPrice,
                TotalAmount: this.additionalFee.list[0].ProductPrice,
                TransactionCode: this.additionalFee.list[0].TransactionCode.TransactionCode,
                RateAdditionalId: this.additionalFee.list[0].RateAdditionalId,
                RateAdditionalType: this.additionalFee.list[0].RateAdditionalType,
                RetailBy: 'RECEPTIONIST'
              },
            ],
          },
        }
      } else {
        payload = {
          BookingDetailCaddy: {
            Id: item.Id,
            BookingDetailId: this.data.BookingDetail.Id,
            CaddyId: Number(item.CaddyId),
            NumberOfHole: item.NumberOfHole,
            WorkingStatus: item.WorkingStatus,
            Reasons: item.Reasons,
            IsDeleted: value,
            BookingDetailServices: [],
          },
        }
      }
      if(isCheckUpdateDupCaddy) {
        payload.IsForceUpdateCaddie = true
      }
      var dataConfirm = {
            dataConfirm: {
              title:  this.$t('golf_booking_update_caddy'),
              content: this.$t('golf_check_update_dup_caddy'),
              isCheckAddCaddy: false,
              data: item,
              checkDelete: value
            },
          }
      booking.updateCaddy(payload).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-update-add-caddy' })
        } else if(response.Status === '400' && response.Messages[0].Code === "E.UBK05.C07") {
          this.$refs.confirmPopupUpdateDupCaddy.open(dataConfirm)
        }
      })
    },
    confirmDialogUpdateCaddy(data) {
      if(data.dataConfirm.isCheckAddCaddy) {
        this.addNewCaddy(true)
      } else {
        this.updateCaddy(data.dataConfirm.data,data.dataConfirm.checkDelete,true)
      }
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/variables';

// ::v-deep {
//   .modal-content {
//     background: #FFFFFF;
//     box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
//     border-radius: 6px;
//     width: 400px;
//   }

//   .vs__selected-options {
//     flex-wrap: inherit !important;
//     max-width: 100%;
//     overflow: hidden;
//     .vs__selected {
//       overflow: hidden;
//       max-width: 100%;
//       white-space: nowrap;
//     }
//   }
//   .vs__dropdown-menu {
//     max-height: 150px!important;
//   }
// }

.banned-background-color-primary {
  background: rgba(17, 74, 159, 0.12);
}

// .title-modal {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   color: #114A9F;
//   line-height: 21px;
// }

// .content-body-modal {
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 18px;
// }

// .color-primary {
//   color: $primary;
// }

// .header-table-custom {
//   th {
//     background: #F2F3F7;
//     border-bottom: unset;
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 14px;
//     letter-spacing: 1px;
//     text-transform: uppercase;
//     padding: 10px;
//   }
// }

// .body-table-custom {
//   .header-board td {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 18px;
//     color: #5D5F65;
//     padding: 10px;
//   }
// }

// .disabled {
//   background: rgba(193, 193, 193, 0.12)
// }

.table-update-caddy {
  th, td {
    padding: 0.72rem;
  }
}
</style>

import flatPickr from 'vue-flatpickr-component'
// import 'flatpickr/dist/flatpickr.css'
import ezIcon from '@/@core/components/ez-icon/ezIcon.vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { members } from '@/api/members'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { flatPickrConfig } from '@/assets/js/flatPickrConfig'
import dayjs from 'dayjs'
import { booking } from '@/api/booking'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import DatePicker from 'vue2-datepicker'
import Subscription from './tabs/Subscription.vue'
import Combo from './tabs/Combo.vue'
import HistoryPlaying from './tabs/HistoryPlaying.vue'
// import 'vue2-datepicker/index.css'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  props: ['countries', 'Member', 'companies', 'isCreate', 'MemberCard', 'BookingId','isOpen'],
  components: {
    required,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Combo,
    Subscription,
    HistoryPlaying,
    flatPickr,
    ezIcon,
    DatePicker,
    ShortKey
  },
  data() {
    return {
      isActive: null,
      submitted: false,
      tabs: true,
      // Birthday: null,
      daysActive: {
        mon: true,
        tue: true,
        wed: true,
        thu: false,
        fri: true,
        sat: false,
        sun: true,
      },
      flatPickrConfig,
      selectedCourseId: [],
      actionData: null,
      sexCode: 'SEX_M',
      option: [
        { Aliases_code: 1, label: 'Mr.' },
        { Aliases_code: 2, label: 'Mrs.' },
        { Aliases_code: 3, label: 'Ms.' },
      ],
      Aliases: { Aliases_code: 1, label: 'Mr.' },
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [
        {
          ComponentId: 'subscription-detail',
          IsActive: false,
        }
      ],
      isCheckEdit: null
      // VisaIssueDate: this.Member.VisaIssueDate ? dayjs(this.Member.VisaIssueDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      // Birthday: null,
    }
  },
  watch: {
    isOpen(value) {
      if(value) {
        this.sexCode = this.Member.SexCode
      } else {
        this.sexCode = 'SEX_M'
      }
    }
  },
  // computed: {
  //   brday() {
  //     return new Date(this.Member.Birthday ? this.Member.Birthday : '')
  //   },
  // },
  // mounted() {
  //   // this.Birthday = this.Member.Birthday == null ? '' : dayjs(this.Member.Birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
  // },
  created() {
    this.getConfig()
  },
  methods: {
    async saveMember() {
      const self = this
      self.$refs.simpleRules.validate().then(async success => {
        if (success) {
          self.$refs.simpleRules.reset()
          await self.formatDataBeforSubmit()
          if (self.Member.Id) {
            const mess = 'Thay đổi khách thành công'
            const response = await members.updateMember({
              Member: self.Member,
              BookingDetailId: this.BookingId,
            })
            await self.showSuccess(response, mess)
          } else {
            const mess = 'Tạo mới khách thành công'
            const response = await members.createMember({
              Member: self.Member,
              BookingDetailId: this.BookingId,
            })
            await self.showSuccess(response, mess)
          }
        }
      })
    },
    async showSuccess(response, mess) {
      if (response.Status === '200') {
        this.showResToast(response)
        this.$emit('event', { type: 'save_success' })
        this.hideSidebar()
      } else {
        this.showResToast(response)
      }
    },
    hideSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'createMember')
    },
    handleAction(type) {
      this.hideSidebar()
      this.$emit('event', { type: `${type}Member`, Member: this.Member })
    },
    async formatDataBeforSubmit() {
      this.Member.Aliases = this.Aliases.label
      this.Member.SexCode = this.sexCode
    },
    uploadImage(isAddNew) {
      let file = ''
      if (isAddNew) {
        file = document.querySelector('#upload-new-image').files[0]
      } else {
        file = document.querySelector('#upload-image').files[0]
      }
      const reader = new FileReader()
      reader.onload = e => {
        const dataURI = e.target.result
        if (dataURI) {
          this.Member.Avatar = dataURI.replace(/^data:image\/(png|jpg|gif|jpeg);base64,/, '')
        }
      }
      reader.readAsDataURL(file)
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.saveMember()
      }
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    async getConfig() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.isCheckEdit = res?.Data.find(x => x.KeyCode === 'BOOKING_NOT_ALLOW_EDIT_MEMBER_INFO') ? JSON.parse(res?.Data.find(x => x.KeyCode === 'BOOKING_NOT_ALLOW_EDIT_MEMBER_INFO').KeyValue) : []
        } 
      })
    },
  },
}

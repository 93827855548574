import {
  BFormInput, BRow, BCol,
  BFormGroup, BButton, BFormCheckbox, BFormCheckboxGroup, BFormRadioGroup, VBTooltip,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, between, alpha, integer, min, max, digits, alphaDash, length,
} from '@validations'

import Cleave from 'vue-cleave-component'

import GolfService from '@/views/modules/ultils/GolfService/GolfService.vue'
import SpecialDay from '@/views/modules/ultils/SpecialDay/SpecialDay.vue'
import Weekly from '@/views/modules/ultils/Weekly/Weekly.vue'

import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { createPopper } from '@popperjs/core'
import { packageSetting } from '@/api/package-setting'

const stringMath = require('string-math')

export default {
  name: 'GolfFee',
  components: {
    Weekly,
    GolfService,
    SpecialDay,
    BFormRadioGroup,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    listCourse: {
      type: Array,
      required: true,
    },
    listTransaction: {
      type: Array,
      required: true,
    },
    packagePrice: {
      type: Number,
      required: false,
    },
    inActive: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      inputMask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      isOpen: false,
      packageId: null,
      detailGolfFee: {
        Package: {
          Id: null,
          PackageGolfFee: [{
            CourseId: this.listCourse.filter(x => !x.InActive)[0].Id,
            NumberOfHole: 9,
            PackageGolfFeeService: [
              {
                ServiceId: null,
                Quantity: 1,
                SpecialDayId: null,
                WeeklyCode: ['MON', 'TUE', 'THU', 'WED', 'FRI', 'SAT', 'SUN'],
                DiscountText: '100%',
                Amount: 0,
                PackageDiscountPercent: 0,
                TransactionCode: null,
                DistributionType: null,
              },
            ],
          }],
        },
      },
      listAllotment: [
        {
          text: this.$t('golf_common_money'),
          value: 'FIX',
        },
        {
          text: this.$t('golf_common_percent'),
          value: 'PERCENT',
        },
      ],
      listNumberOfHoles: [
        {
          value: 9,
          title: '9 Hole',
        },
        {
          value: 18,
          title: '18 Hole',
        },
        {
          value: 27,
          title: '27 Hole',
        },
        {
          value: 36,
          title: '36 Hole',
        },
        {
          value: 45,
          title: '45 Hole',
        },
        {
          value: 54,
          title: `54 ${this.$t('hole')}`,
        },
      ],
      listService: [
        {
          text: 'Caddy Fee',
          value: 12,
        },
        {
          text: 'Green Fee',
          value: 13,
        },
        {
          text: 'Bugy Fee',
          value: 14,
        },
      ],
      distributionType: 'FIX',
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      isAddNew: true,
      isValidDistribution: true,
      totalDistribution: 0,
      isValidPackageDiscountPercent: true,
      totalPackageDiscountPercent: 0,
      copyCoursePosition: null,
      distributionRound: 1,
      servicePosition: [],
    }
  },
  methods: {
    async open(packageId, packageGolfFee) {
      this.isOpen = true
      this.packageId = packageId

      // Nếu xem chi tiết 1 golffee
      if (packageGolfFee) {
        this.isAddNew = false
        this.detailGolfFee = JSON.parse(JSON.stringify(packageGolfFee))
        this.distributionType = this.detailGolfFee.PackageGolfFee[0].PackageGolfFeeService[0].DistributionType
        this.distributionRound = this.detailGolfFee.DistributionRound
      } else {
        this.isAddNew = true
        // Nếu thêm mới 1 golffee
        this.distributionRound = 1
        this.detailGolfFee = {
          // Package: {
          //   Id: packageId,
          PackageGolfFee: [{
            CourseId: this.listCourse.filter(x => !x.InActive)[0].Id,
            NumberOfHole: 9,
            PackageGolfFeeService: [
              {
                ServiceId: null,
                Quantity: 1,
                SpecialDayId: null,
                WeeklyCode: ['MON', 'TUE', 'THU', 'WED', 'FRI', 'SAT', 'SUN'],
                DiscountText: '100%',
                Amount: 0,
                PackageDiscountPercent: 0,
                TransactionCode: null,
                DistributionType: null,
              },
            ],
          }],
        }
        // }
      }
    },
    afterUpdatePackageCourse(res) {
      this.$emit('afterUpdatePackageCourse', res)
    },
    addNewPackageCouse_RPA01_AC9() {
      this.$refs.rateCourseForm.validate().then(success => {
        if (success && this.validateDistribution() && this.validatePackageDiscountPercent()) {
          this.detailGolfFee.PackageGolfFee.forEach(x => {
            x.PackageGolfFeeService.forEach(y => {
              y.Quantity = parseInt(y.Quantity)
              // y.Discount = parseInt(y.DiscountText)
              y.Amount = parseInt(y.Amount)
              y.PackageDiscountPercent = (y.PackageDiscountPercent) ? parseFloat(y.PackageDiscountPercent) : 0
              y.DistributionType = this.distributionType
            })
          })

          const body = {
            Package: {
              Id: this.packageId,
              PackageInfo: {
                DistributionRound: parseFloat(this.distributionRound),
                // IsDefault: this.detailGolfFee.
                PackageGolffee: this.detailGolfFee.PackageGolfFee,
              },
            },
          }

          packageSetting
            .addNewPackageCourse(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                // Đóng popup thêm rate
                this.isOpen = false
                // Load lại thông tin detail giá
                this.afterUpdatePackageCourse()
              }
            })
        }
        if (!this.validateDistribution() || !this.validatePackageDiscountPercent()) {
          this.showToast('error', this.$t('golf_package_golffee_distribution_invalid'))
        }
      })
    },
    updatePackageCourse_RPA01_AC10() {
      this.$refs.rateCourseForm.validate().then(success => {
        if (success && this.validateDistribution() && this.validatePackageDiscountPercent()) {
          const tmp = this.detailGolfFee

          tmp.PackageGolfFee.forEach(x => {
            x.PackageGolfFeeService.forEach(y => {
              y.Quantity = parseInt(y.Quantity)
              y.Amount = parseInt(y.Amount)
              y.PackageDiscountPercent = (y.PackageDiscountPercent) ? parseFloat(y.PackageDiscountPercent) : 0
              y.DistributionType = this.distributionType
            })
          })

          const body = {
            Package: {
              Id: this.packageId,
              PackageInfo: {
                Id: tmp.Id,
                DistributionRound: parseFloat(this.distributionRound),
                PackageGolffee: tmp.PackageGolfFee,
              },
            },
          }
          packageSetting
            .updatePackageCourse(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                // Đóng popup thêm rate
                this.isOpen = false
                // Load lại thông tin detail giá
                this.afterUpdatePackageCourse()
              }
            })
        }
        if (!this.validateDistribution() || !this.validatePackageDiscountPercent()) {
          this.showToast('error', this.$t('golf_package_golffee_distribution_invalid'))
        }
      })
    },
    // Thêm khối sân
    addCourse() {
      this.detailGolfFee.PackageGolfFee.push(
        {
          CourseId: null,
          NumberOfHole: null,
          PackageGolfFeeService: [
            {
              ServiceId: null,
              Quantity: null,
              SpecialDayId: null,
              WeeklyCode: [],
              DiscountText: null,
              Amount: null,
              PackageDiscountPercent: null,
              TransactionCode: null,
              DistributionType: null,
            },
          ],
        },
      )
    },
    deleteCourse(i) {
      this.detailGolfFee.PackageGolfFee.splice(i, 1)
    },
    addService(i) {
      this.detailGolfFee.PackageGolfFee[i].PackageGolfFeeService.push(
        {
          ServiceId: null,
          Quantity: null,
          SpecialDayId: null,
          WeeklyCode: [],
          DiscountText: null,
          Amount: null,
          PackageDiscountPercent: null,
          TransactionCode: null,
          DistributionType: null,
        },
      )
    },
    deleteService(i, j) {
      this.detailGolfFee.PackageGolfFee[i].PackageGolfFeeService.splice(j, 1)
    },
    validateDistribution() {
      let isValid = false
      this.totalDistribution = 0
      this.detailGolfFee.PackageGolfFee.forEach(x => {
        x.PackageGolfFeeService.forEach(y => {
          const price = (y.Amount) ? parseFloat(y.Amount) : 0
          this.totalDistribution += price
        })
      })

      if (this.distributionType == 'PERCENT') {
        if (this.totalDistribution == 100) {
          isValid = true
        }
      } else if (this.totalDistribution == this.packagePrice * this.distributionRound) {
        isValid = true
      }
      console.log(this.totalDistribution)
      this.isValidDistribution = isValid
      return isValid
    },
    validatePackageDiscountPercent() {
      let isValid = false
      this.totalPackageDiscountPercent = 0
      this.detailGolfFee.PackageGolfFee.forEach(x => {
        x.PackageGolfFeeService.forEach(y => {
          const tmp = (y.PackageDiscountPercent) ? parseFloat(y.PackageDiscountPercent) : 0
          this.totalPackageDiscountPercent += tmp
        })
      })

      if (this.totalPackageDiscountPercent == 100) {
        isValid = true
      }

      this.isValidPackageDiscountPercent = isValid

      if (this.distributionType == 'PERCENT') {
        isValid = true
      }
      return isValid
    },
    copyCourse(i) {
      this.copyCoursePosition = i
    },
    pasteCourse(i) {
      this.detailGolfFee.PackageGolfFee.splice(i, 1, JSON.parse(JSON.stringify(this.detailGolfFee.PackageGolfFee[this.copyCoursePosition])))
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = '400px'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    setPosition(i, j) {
      this.servicePosition = [i, j]
    },
    selectService(service) {
      // Set transaction code mặc định khi chọn dịch vụ
      this.detailGolfFee.PackageGolfFee[this.servicePosition[0]].PackageGolfFeeService[this.servicePosition[1]].TransactionCode = service.TransactionCode.TransactionCode
    },
  },
}

<template>
  <div>
    <b-card-body style="margin-bottom: 20%">
      <div class="d-flex justify-content-start align-items-center" style="margin-bottom: 20px">
        <p class="mb-0 text-bill" style="margin-right: 6px">Khách quan tâm</p>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {BCardBody} from "bootstrap-vue";

export default {
  components: {
    BCardBody
  }
}
</script>

<template>
  <div class="history-playing">
    <b-container>
      <b-row style="height: 72px">
        <b-col
          cols="9"
          class="d-flex align-items-center"
        >
          <div class="item">
            {{ $t('golf_member_member_all') }}
            <span style="background: #114A9F">{{ totalSubscriptionStatusStatistic() }}</span>
          </div>
          <div
            v-for="(item, index) in SubscriptionStatusStatistic"
            :key="index"
            class="item"
          >
            {{ item.Title }}
            <span style="background: #29B869">{{ item.Value }}</span>
          </div>
          <!-- <div class="item">
            Hết hạn
            <span style="background: #7367F0">152</span>
          </div>
          <div class="item">
            Truất quyền
            <span style="background: #EA5455">15</span>
          </div> -->
        </b-col>
        <b-col
          cols="3"
          class="text-right my-auto"
        >
          <div
            class="_button-no-bg text-nowrap d-inline-flex align-items-center"
            @click="showCreateSub()"
          >
            <plus-circle-icon
              size="1.5x"
              class="mr-1"
            />
            <span> {{ $t('golf_member_member_add_subscription') }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="p-0"
        >
          <b-table
            :hover="true"
            :sticky-header="true"
            :items="MemberSubscription"
            :fields="fieldsSubscriptionTable"
            :tbody-tr-class="rowClass"
            responsive
            @row-clicked="memberSubscription_rowClick"
          >

            <!-- Tuỳ chỉnh hiển thị ID thẻ -->
            <template #cell(Id)="{item}">
              <div class="text-nowrap">
                #{{ item.Id }}
              </div>
            </template>

            <!-- Tuỳ chỉnh hiển thị loại thẻ -->
            <template #cell(MemberCard)="{item}">
              <div class="text-nowrap">
                {{ item.MemberCard.CardTypeName }}
              </div>
            </template>

            <!-- Tuỳ chỉnh hiển thị turn -->
            <template #cell(NumberOfPlays)="{item}">
              <div class="text-nowrap text-center pr-3">
                {{ item.NumberOfPlays }}
              </div>
            </template>

            <!-- Tuỳ chỉnh hiển thị amount -->
            <template #cell(ContractValue)="{item}">
              <div class="text-nowrap text-right pr-3">
                {{ item.ContractValue | formatNumber }}
              </div>
            </template>

            <!-- Tuỳ chỉnh thời gian áp dụng -->
            <template #cell(EndDate)="{item}">
              <div class="text-nowrap">
                {{ _dayjs(item.OpenDate).format('DD/MM/YYYY') }} ~ {{ _dayjs(item.EndDate).format('DD/MM/YYYY') }}
              </div>
            </template>
            <template #cell(TransferTurn)="{item}">
              <div class="text-nowrap text-center pr-3">
                {{ item.TransferTurn }}
              </div>
            </template>

            <template #cell(actions)="{item}">
              <div class="d-flex align-items-center">
                <b-dropdown
                  no-caret
                  variant="link"
                  toggle-class="text-decoration-none"
                  class="table-dropdown"
                >
                  <template #button-content>
                    <more-vertical-icon
                      class="action-icon"
                      size="1.5x"
                    />
                  </template>
                  <b-dropdown-item-button @click="getDetailSubscription(item)">
                    {{ $t('golf_common_detail') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button>
                    {{ $t('golf_member_member_print_bill') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                    class="delete"
                    @click="handleAction(item, 'delete-subscription')"
                  >
                    <span class="require">{{ $t('golf_common_delete') }}</span>
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          class="p-10"
        >
          <div class="footer-wrapper d-flex align-items-center justify-content-between">
            <span>Hiển thị {{ MemberSubscription.length }} trên tổng số {{ MemberSubscription.length }} kết quả</span>
            <!--<b-pagination class="m-0" v-model="currentPage" pills :total-rows="rows"></b-pagination>-->
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- modal confirm -->
    <DeleteSubscription
      :data="modalData"
      @event="handleEvent"
    />
    <SubscriptionDetail
      :is-create="isCreate"
      :data="modalData"
      :data-history="modalHistoryMemberSubcriptionPaymentData"
      :member="Member"
      :member-card="MemberCard"
      :countries="countries"
      @event="handleEvent"
      @is-open="SidebarOpenOrCloseHandler"
    />
  </div>
</template>

<script>
import { PlusCircleIcon, MoreVerticalIcon } from 'vue-feather-icons'
import { members } from '@/api/members'
import dayjs from 'dayjs'
import DeleteSubscription from '../modals/DeleteSubscription.vue'
import SubscriptionDetail from '../modals/SubscriptionDetail.vue'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)
export default {
  name: 'Subscription',
  components: {
    PlusCircleIcon,
    MoreVerticalIcon,
    DeleteSubscription,
    SubscriptionDetail,
  },
  props: ['Member', 'MemberCard','countries'],

  data() {
    return {
      modalData: {},
      MemberSubscription: [],
      SubscriptionStatusStatistic: [],
      isCreate: false,
      modalHistoryMemberSubcriptionPaymentData: null,
    }
  },
  computed: {
    fieldsSubscriptionTable() {
      return [
        { key: 'CardCode', label: this.$t('golf_member_subscription_card') },
        { key: 'MemberCard', label: this.$t('golf_member_subscription_card_type') },
        { key: 'ContractCode', label: this.$t('golf_member_subscription_contract_code') },
        { key: 'NumberOfPlays', label: this.$t('golf_member_subscription_number_of_plays') },
        { key: 'ContractValue', label: this.$t('golf_member_subscription_contract_value') },
        { key: 'EndDate', label: this.$t('golf_member_subscription_contract_term') },
        { key: 'TransferTurn', label: this.$t('golf_member_subscription_transfer_turn') },
        { key: 'Note', label: this.$t('golf_member_subscription_note') },
        { key: 'actions', label: '' },
      ]
    },
  },
  watch: {
    Member: {
      immediate: true,
      handler(newVal, oldVal) {
        this.getSubscriptions_RSU03()
      },
    },
  },
  methods: {
    showCreateSub() {
      this.isCreate = true
      this.refreshDataModal()
      this.$bvModal.show('subscription-detail')
    },
    totalSubscriptionStatusStatistic() {
      var check = 0
      this.SubscriptionStatusStatistic.forEach(x => {
        check += x.Value
      })
      return check
    },
    async getSubscriptions_RSU03() {
      const requestData = {
        Member: { Id: this.Member.Id },
      }
      const response = await members.getSubscriptions(requestData)

      if (response.Status === '200') {
        this.MemberSubscription = response?.Data?.MemberSubscription
        this.SubscriptionStatusStatistic = response?.Data?.SubscriptionStatusStatistic
      }
    },
    handleEvent(result) {
      if (result.type === 'action_success') {
        this.getSubscriptions_RSU03()
      }
    },
    refreshDataModal() {
      return this.modalData = {
        MemberId: this.Member.Id,
        CardCode: null,
        ContractCode: null,
        ContractValue: null,
        NumberOfPlays: null,
        OpenDate: this.getUTC('start', 'day'),
        EndDate: this.getUTC('start', 'day', 1),
        Note: '',
        ContractFile: [],
      }
    },
    handleAction(item, id) {
      this.modalData = item
      this.$bvModal.show(id)
    },
    _dayjs: dayjs,
    async getDetailSubscription(item, id = 'subscription-detail') {
      this.isCreate = false
      const requestData = {
        MemberSubscription:
          {
            Id: item.Id,
          },
      }
      const response = await members.getSubscriptionDetail(requestData)
      if (response.Status === '200') {
        // this.getHistoryMemberSubcriptionPayment_RMEH02(item)
        this.modalData = response.Data.MemberSubscription
        this.$bvModal.show(id)
      }
    },
    async getHistoryMemberSubcriptionPayment_RMEH02(item) {
      const body = {
        StartDate: null,
        EndDate: null,
        MemberSubscriptionId: item.Id,
      }
      await members.api_RMEH02(body).then(res => {
        this.modalHistoryMemberSubcriptionPaymentData = res.Data.MemberSubcriptionPayment
      })
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.InActive === true) {
        return 'isOver'
      }
    },
    async memberSubscription_rowClick(record) {
      await this.getDetailSubscription(record)
    },
    SidebarOpenOrCloseHandler(result) {
      this.$emit('is-open', result);
    }
  },
}
</script>

<style lang="scss">
.history-playing {
  ._button-no-bg {
    border-radius: 5px;
    border: 1px solid #114A9F;
    padding: 5px 10px;
    color: #114A9F;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #5d5f65;
    font-weight: 500;

    span {
      margin-left: 8px;
      margin-right: 22px;
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      justify-content: center;
      height: 20px;
      min-width: 20px;
      padding: 0 6px;
      border-radius: 4px;
      color: #FFFFFF;
    }
  }

  th.table-b-table-default {
    min-width: 100px;
  }

  .action-icon {
    color: black;
  }

  .table-dropdown {
    ul {
      width: 160px;
      padding: 0;

      li {
        margin: 0;

        button {
          width: 100%;
        }
      }
    }
  }
}

.delete {
  border-top: 1px solid #ccc;
}

.item:first-child {
  margin-left: 16px;
}

tbody {
  border-bottom: 1px solid rgb(234, 232, 232);
}

.b-table-sticky-header {
  overflow-y: unset;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-playing"},[_c('b-container',[_c('b-row',{staticStyle:{"height":"72px"}},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"9"}},[_c('div',{staticClass:"item"},[_vm._v(" "+_vm._s(_vm.$t('golf_member_member_all'))+" "),_c('span',{staticStyle:{"background":"#114A9F"}},[_vm._v(_vm._s(_vm.totalSubscriptionStatusStatistic()))])]),_vm._l((_vm.SubscriptionStatusStatistic),function(item,index){return _c('div',{key:index,staticClass:"item"},[_vm._v(" "+_vm._s(item.Title)+" "),_c('span',{staticStyle:{"background":"#29B869"}},[_vm._v(_vm._s(item.Value))])])})],2),_c('b-col',{staticClass:"text-right my-auto",attrs:{"cols":"3"}},[_c('div',{staticClass:"_button-no-bg text-nowrap d-inline-flex align-items-center",on:{"click":function($event){return _vm.showCreateSub()}}},[_c('plus-circle-icon',{staticClass:"mr-1",attrs:{"size":"1.5x"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('golf_member_member_add_subscription')))])],1)])],1),_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('b-table',{attrs:{"hover":true,"sticky-header":true,"items":_vm.MemberSubscription,"fields":_vm.fieldsSubscriptionTable,"tbody-tr-class":_vm.rowClass,"responsive":""},on:{"row-clicked":_vm.memberSubscription_rowClick},scopedSlots:_vm._u([{key:"cell(Id)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" #"+_vm._s(item.Id)+" ")])]}},{key:"cell(MemberCard)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.MemberCard.CardTypeName)+" ")])]}},{key:"cell(NumberOfPlays)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap text-center pr-3"},[_vm._v(" "+_vm._s(item.NumberOfPlays)+" ")])]}},{key:"cell(ContractValue)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap text-right pr-3"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.ContractValue))+" ")])]}},{key:"cell(EndDate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._dayjs(item.OpenDate).format('DD/MM/YYYY'))+" ~ "+_vm._s(_vm._dayjs(item.EndDate).format('DD/MM/YYYY'))+" ")])]}},{key:"cell(TransferTurn)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap text-center pr-3"},[_vm._v(" "+_vm._s(item.TransferTurn)+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-dropdown',{staticClass:"table-dropdown",attrs:{"no-caret":"","variant":"link","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('more-vertical-icon',{staticClass:"action-icon",attrs:{"size":"1.5x"}})]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.getDetailSubscription(item)}}},[_vm._v(" "+_vm._s(_vm.$t('golf_common_detail'))+" ")]),_c('b-dropdown-item-button',[_vm._v(" "+_vm._s(_vm.$t('golf_member_member_print_bill'))+" ")]),_c('b-dropdown-item-button',{staticClass:"delete",on:{"click":function($event){return _vm.handleAction(item, 'delete-subscription')}}},[_c('span',{staticClass:"require"},[_vm._v(_vm._s(_vm.$t('golf_common_delete')))])])],1)],1)]}}])})],1)],1)],1),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"p-10",attrs:{"cols":"12"}},[_c('div',{staticClass:"footer-wrapper d-flex align-items-center justify-content-between"},[_c('span',[_vm._v("Hiển thị "+_vm._s(_vm.MemberSubscription.length)+" trên tổng số "+_vm._s(_vm.MemberSubscription.length)+" kết quả")])])])],1)],1),_c('DeleteSubscription',{attrs:{"data":_vm.modalData},on:{"event":_vm.handleEvent}}),_c('SubscriptionDetail',{attrs:{"is-create":_vm.isCreate,"data":_vm.modalData,"data-history":_vm.modalHistoryMemberSubcriptionPaymentData,"member":_vm.Member,"member-card":_vm.MemberCard,"countries":_vm.countries},on:{"event":_vm.handleEvent,"is-open":_vm.SidebarOpenOrCloseHandler}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
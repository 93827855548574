import { commonServices } from '@/api/common-services'

export default {
  name: 'SelectDebitAccount',
  components: {},
  props: {
    listClient: {
      type: Array,
    },
  },
  data() {
    return {
      idSelfModal: 'modal-select-ar',
      textFilter: '',
      arAccountList: [],
      arAccountListFilter: [],
      rowDataSelected: null,
      isBusyTable: false,
    }
  },
  computed: {
    fieldsARAccount() {
      return [
        { key: 'Id', label: this.$t('golf_ar_code'), sortable: true },
        { key: 'ClientName', label: this.$t('golf_ar_ar_name'), sortable: true },
        { key: 'InvoiceName', label: this.$t('golf_ar_invoice_name'), sortable: true },
        { key: 'Arbalance', label: this.$t('golf_ar_ledger_balance'), sortable: true },
      ]
    },
  },
  methods: {
    async openForm() {
      this.resetForm()
      this.$nextTick(() => {
        this.$refs.arAccountTable.clearSelected()
      })
      this.isBusyTable = true

      if (this.listClient) {
        this.arAccountList = [...this.listClient]
        this.arAccountListFilter = [...this.listClient]
      } else {
        await commonServices
          .getCompany()
          .then(res => {
            if (res.Status === '200') {
              const arAccountResponse = res?.Data?.Client || []
              this.arAccountList = [...arAccountResponse]
              this.arAccountListFilter = [...arAccountResponse]
            } else {
              this.showResToast(res)
            }
          })
      }

      this.isBusyTable = false
    },
    resetForm() {
      this.textFilter = ''
      this.isBusyTable = false
      this.arAccountList = []
      this.arAccountListFilter = []
      this.rowDataSelected = null
    },
    onChangeTextFilter(ev) {
      const self = this
      const textSearch = (ev.target.value || '').trim().toLowerCase()
      this.$delayInput({
        onChange() {
          if (textSearch) {
            self.arAccountListFilter = [...self.arAccountList].filter(s => {
              if (isNaN(textSearch)) {
                return (s.ClientName || '').toLowerCase().indexOf(textSearch) >= 0
              }
              return s.Id === parseInt(textSearch, 10)
            })
          } else {
            self.arAccountListFilter = [...self.arAccountList]
          }
        },
      })
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', this.idSelfModal)
    },
    goBack() {
      this.hideModal()
    },
    selectAR() {
      if (this.rowDataSelected) {
        this.$emit('ar-account-selected', this.rowDataSelected)
        this.$root.$emit('bv::hide::modal', this.idSelfModal)
      }
    },
    onRowClick(record) {
      this.rowDataSelected = { ...record }
    },
    /**
     * Tạo độ trễ khi nhập dữ liệu Input text
     * @param {*}
     * - timerKey (string):
     *  + Tên biến (data component) ghi nhận kích hoạt thời gian trễ
     *  + Không khai báo sẽ mặc định "systemTimerDelay"
     * - timerTimeOut (number): Thời gian trễ. Mặc định "200"
     * - onChange (function): Sự kiện sau khi hết thời gian trễ
     */
    $delayInput({ timerDelayKey = 'systemTimerDelay', timeDelay = 200, onChange }) {
      const self = this
      if (this[timerDelayKey]) {
        clearTimeout(this[timerDelayKey])
      }
      this[timerDelayKey] = setTimeout(() => {
        self[timerDelayKey] = null
        if (onChange && {}.toString.call(onChange) === '[object Function]') {
          onChange()
        }
      }, timeDelay)
    },
  },
}

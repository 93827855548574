import { commonServices } from '@/api/common-services'
import { formatPrice } from '@/@core/utils/utils'

export default {
  name: 'GolfService',
  components: {},
  props: {
    value: {
      type: Number,
      required: false,
    },
    area: {
      type: String,
      required: true,
    },
    error: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      listService: [],
      zone: [],
      hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      keyWord: null,
      productName: 'Chọn dịch vụ',
      activePosition: {
        ProductMenuId: null,
        ProductId: null,
      },
      isLoading: false,
      allServices: [],
    }
  },
  watch: {
    value(data) {
      if (data) {
        this.getServiceNameById(data)
      }
    },
  },
  created() {
    this.getListZone(this.area)
  },
  methods: {
    handleSearch() {
      const search = new RegExp(this.keyWord, 'i')
      const tmpAllService = []
      this.allServices.forEach(x => {
        const menuDetail = []
        x.MenuDetail.forEach((y) => {
          if (y.ProductNameUpperCase.includes(this.keyWord.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
        })

        if (menuDetail.length) {
          tmpAllService.push({
            ProductMenuId: x.ProductMenuId,
            MenuCode: x.MenuCode,
            MenuName: x.MenuName,
            IsChildOfBar: x.IsChildOfBar,
            ParentMenuId: x.ParentMenuId,
            IsActive: x.IsActive,
            HotelId: x.HotelId,
            MenuDetail: menuDetail,
          })
        }
      })

      this.listService = tmpAllService
    },
    async getListZone(area) {
      // const bodyHotel = {
      //   KeyGroup: 'HOTEL',
      // }
      // await commonServices
      //   .getCommon(bodyHotel)
      //   .then(res => {
      //     if (res.Status === '200') {
      //       this.hotelId = res.Data.find(x => x.KeyCode === 'HOTEL_ID_LINK').KeyValue
      //     }
      //   })

      const bodyArea = {
        KeyGroup: area,
      }
      await commonServices
        .getCommon(bodyArea)
        .then(res => {
          if (res.Status === '200') {
            res.Data.forEach(x => {
              this.zone.push(x.KeyValue)
            })
            this.getListService()
          } else {
            this.showResToast(res)
          }
        })
    },
    async getListService() {
      this.isLoading = true
      const body = {
        HotelId: this.hotelId,
        ZoneId: this.zone,
      }
      await commonServices
        .getListService(body)
        .then(res => {
          if (res.Status === '200') {
            this.listService = res.Data.Menu
            this.allServices = res.Data.Menu
            if (res.Data.Menu) {
              this.allServices.forEach(x => {
                x.MenuDetail.forEach(y => {
                  y.ProductNameUpperCase = y.ProductName.split(' ').join('').toUpperCase()
                })
              })
            }
            // Hiển thị tên service đã được chọn
            this.getServiceNameById(this.value)
          } else {
            this.showResToast(res)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    selectSevice(selectSevice) {
      this.isOpen = false
      this.value = selectSevice.ProductId
      this.$emit('input', this.value)
      this.$emit('selectSevice', selectSevice)
    },
    async getServiceNameById(serviceId) {
      await this.listService.find(x => {
        x.MenuDetail.find(y => {
          if (y.ProductId == serviceId) {
            this.productName = y.ProductName
            this.activePosition.ProductMenuId = x.ProductMenuId
            this.activePosition.ProductId = y.ProductId
          }
        })
      })
    },
    formatPrice,
  },
}

<template>
  <b-modal
    id="bans-subscription"
    size="sm"
    centered
    hide-header
    ok-only
    cancel-variant="none"
    ok-title="Cập nhật"
    ok-variant="danger"
    @ok="bansSubscription_USU02"
  >
    <b-card-header class="banned-background-color bans">
      <div class="d-flex justify-content-between align-items-center header-modal">
        <p class=" mb-0 title-modal">
          {{ $t('golf_member_member_bans') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`red`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <validation-observer ref="simpleRules">
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-row align-v="center">
                  <b-col cols="6">
                    {{ $t('golf_member_subscription_deposition_from') }}
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <date-range-picker
                        ref="picker"
                        v-model="MemberSubscriptionDeposition.FromDate"
                        opens="right"
                        :single-date-picker="true"
                        :ranges="false"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                          format: 'DD/MM/YYYY HH:mm',
                          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('golf_member_require') }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row
                  class="mt-1"
                  align-v="center"
                >
                  <b-col cols="6">
                    {{ $t('golf_member_subscription_deposition_to') }}
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <date-range-picker
                        ref="picker"
                        v-model="MemberSubscriptionDeposition.ToDate"
                        opens="right"
                        :single-date-picker="true"
                        :ranges="false"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                          format: 'DD/MM/YYYY HH:mm',
                          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          firstDay: 0
                        }"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('golf_member_require') }}</small>

                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-form-textarea
                    v-model="MemberSubscriptionDeposition.Note"
                    class="mt-1"
                    placeholder="Nhập lý do truất quyền"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </validation-observer>
  </b-modal>
</template>

<script>
import { members } from '@/api/members'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    flatPickr,
  },
  props: [
    'data',
  ],
  data() {
    return {
      dateDefault: null,
      MemberSubscriptionDeposition: {
        MemberSubscriptionId: this.data.Id,
        FromDate: this.getUTC('start', 'day'),
        ToDate: this.getUTC('start', 'day', 1),
        DepositionDate: null,
        UnDepositionDate: null,
        Note: null,
      },
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('bans-subscription')
    },

    async bansSubscription_USU02(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.$refs.simpleRules.reset()
          this.setMemberSubscriptionId()
          this.hideModal()
          // await this.formatDataBeforeSubmit()
          const response = await members.bansSubscription({
            MemberSubscriptionDeposition: this.MemberSubscriptionDeposition,
          })

          await this.showSuccess(response)
        }
      })
    },
    async showSuccess(response) {
      this.showResToast(response)

      if (response.Status === '200') {
        this.$emit('event', { type: 'bans_success' })
        this.hideModal()
      }
    },
    // formatDataBeforeSubmit() {
    //   this.MemberSubscriptionDeposition.FromDate = dayjs(this.MemberSubscriptionDeposition.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD').toString()
    //   this.MemberSubscriptionDeposition.ToDate = dayjs(this.MemberSubscriptionDeposition.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD').toString()
    // },
    setMemberSubscriptionId() {
      this.MemberSubscriptionDeposition.MemberSubscriptionId = this.data.Id
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.avatar {
  height: 60px;
  width: 60px;
  background-color: #ccc;
  border-radius: 50%;
}

.title {
  font-size: 14px;
  color: #424242;
  font-weight: 500;
  padding-bottom: 10px;
}

.banned-background-color.bans {
  width: 100%;
  padding: 0 18px;
  height: 40px;
  background: rgba(234, 84, 85, 0.12) !important;
  border-radius: 6px 6px 0px 0px;

  .title-modal {
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
    color: red;
  }
}

.header-modal {
  height: 48px;
}
</style>

import { render, staticRenderFns } from "./Weekly.vue?vue&type=template&id=5445ea70"
import script from "./Weekly.js?vue&type=script&lang=js&external"
export * from "./Weekly.js?vue&type=script&lang=js&external"
import style0 from "./Weekly.vue?vue&type=style&index=0&id=5445ea70&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <b-modal
    id="un-bans-subscription"
    size="sm"
    centered
    hide-header
    ok-only
    cancel-variant="none"
    :ok-title="$t('golf_member_unban_subscription_update')"
    ok-variant="danger"
    @ok="unBansSubscription_USU03()"
  >
    <b-card-header class="banned-background-color bans">
      <div class="d-flex justify-content-between align-items-center unbans-modal">
        <p class=" mb-0 title-modal">
          {{ $t('golf_member_member_un_bans') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`#29B869`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-form-textarea
                  :placeholder="$t('golf_member_enter_the_reason_for_disqualification')"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</template>

<script>
import { members } from '@/api/members'
import dayjs from 'dayjs'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
  },
  props: [
    'data',
  ],
  data() {
    return {
      dateDefault: null,
      MemberSubscriptionDeposition:
        {
          MemberSubscriptionId: this.data.Id,
          DepositionDate: dayjs(new Date()).format('YYYY-MM-DD').toString(),
          FromDate: null,
          ToDate: null,
          UnDepositionDate: null,
          Note: null,
        },
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('un-bans-subscription')
    },

    async unBansSubscription_USU03() {
      this.setMemberSubscriptionId()
      const response = await members.unBansSubscription({
        MemberSubscriptionDeposition: this.MemberSubscriptionDeposition,
      })

      this.showResToast(response)
      if (response.Status === '200') {
        this.$emit('event', { type: 'unbans_success' })
      }
    },
    setMemberSubscriptionId() {
      this.MemberSubscriptionDeposition.MemberSubscriptionId = this.data.Id
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.avatar {
  height: 60px;
  width: 60px;
  background-color: #ccc;
  border-radius: 50%;
}
.title {
  font-size: 14px;
  color: #424242;
  font-weight: 500;
  padding-bottom: 10px;
}
.banned-background-color.unbans {
  width: 100%;
  padding: 0 18px;
  height: 40px;
  background: rgba(41, 184, 105, 0.12) !important;
  border-radius: 6px 6px 0px 0px;
}
.title-modal {
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
  color: #29B869;
}
.unbans-modal {
  height: 48px;
}
</style>

import { packageSetting } from '@/api/package-setting'
import GolfFee from '../GolfFee/GolfFee.vue'

export default {
  name: 'SidebarDetailRate',
  components: {
    GolfFee,
  },
  props: [
    'currentPackage',
    'listCourse',
    'listGolfClass',
    'listCompany',
    'listTransaction',
    'isDetail'
  ],
  data() {
    return {
      detailPackage: {},
      packageId: {},
      listWeeklyCode: [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ],
      isLoading: false,
      tabIndex: null,
    }
  },
  created() {
    this.packageId = this.currentPackage
    if (this.packageId) {
      this.getDetailPackage_RPA02_AC2()
    }
  },
  methods: {
    viewDetailMasterPackage() {
      this.$emit('viewDetailMasterPackage', this.packageId)
    },
    viewAdditionFee(detail, course, rateId, rateParentId) {
      this.$emit('viewAdditionFee', detail, course, rateId, rateParentId)
    },
    // Chi tiết Package
    async getDetailPackage_RPA02_AC2() {
      this.isLoading = true
      const body = {
        Package: {
          Id: this.packageId,
        },
      }
      await packageSetting
        .getDetailPackage(body)
        .then(res => {
          if (res.Status === '200') {
            this.detailPackage = res.Data.Package
          } else {
            this.showResToast(res)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    async deletePackageCourse_RPA01_AC13(PackageInfoId) {
      const body = {
        PackageInfo: {
          Id: PackageInfoId,
        },
      }
      await packageSetting
        .deletePackageCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.getDetailPackage_RPA02_AC2()
          }
        })
    },
    async deleteAdditionalFee_RPA01_AC14(PackageAdditionalFeeId) {
      const body = {
        PackageAdditionalFee: {
          Id: PackageAdditionalFeeId,
        },
      }
      await packageSetting
        .deletePackageAdditionalFee(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.getDetailPackage_RPA02_AC2()
          }
        })
    },
    async setDefaultGolfFee_RPA01_AC15(PackageInfoId) {
      const body = {
        PackageInfo: {
          Id: PackageInfoId,
        },
      }
      await packageSetting
        .setDefaultPackageCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.getDetailPackage_RPA02_AC2()
          }
        })
    },
    afterUpdatePackageCourse(res) {
      this.getDetailPackage_RPA02_AC2()
      this.$emit('afterUpdatePackageCourse', res)
    },
  },
}

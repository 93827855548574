<template>
  <div>
    <b-modal
      id="modal-update-booking-info"
      v-model="isOpen"
      size="md"
      centered
      ok-only
      :ok-title="$t('golf_common_update')"
      ok-variant="primary"
      :no-close-on-backdrop="true"
      @ok="updateInfoGroup_UBK01"
    >
      <template #modal-header="{ close }">
        <h4 class="mb-0 text-primary">
          {{ $t('golf_common_edit_booking_info') }}
        </h4>
        <ez-icon
          icon="ezGolf-icon-x"
          class="cursor-pointer"
          size="24"
          color="#114A9F"
          @click="close"
        />
      </template>
      <b-card-body>
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <b-form-input
                  v-model="Booking.GroupName"
                  :placeholder="$t('golf_boongking_group_name')"
                  maxlength="100"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <div class="text-right">
                  {{ $t('golf_common_booking_code') }}
                  <span class="text-primary font-weight-bold">#{{ data.BookingCode }}</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group>
                <b-form-input
                  v-model="Booking.RepresentativeName"
                  :placeholder="$t('golf_boongking_group_representative_name')"
                  maxlength="100"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="max:20|integer"
                  :name="$t('golf_boongking_group_representative_phone')"
                >
                  <b-form-input
                    v-model="Booking.RepresentativePhoneNumber"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('golf_boongking_group_representative_phone')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="12">
              <b-form-input
                v-model="Booking.RepresentativeEmail"
                :placeholder="$t('golf_booking_group_representative_email')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <v-select
                  v-model="Booking.SaleId"
                  class="custom-select-bdc"
                  :options="listUserOfSale"
                  :placeholder="$t('golf_boongking_sale_man')"
                  label="label"
                  :clearable="true"
                  :reduce="label => label.id"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-form-textarea
                  v-model="Booking.RepresentativeNote"
                  :placeholder="$t('golf_booking_note')"
                  rows="3"
                  no-resize
                  maxlength="250"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import {
  required, length, max, integer, double, email,
} from '@validations'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { booking } from '@/api/booking'
import { commonServices } from '@/api/common-services'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
export default {
  name: 'UpdateGroup',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ShortKey
  },
  props: ['data','listUserOfSale'],
  data() {
    return {
      listSource: [],
      listCompany: [],
      listSale: [],
      isLoading: true,
      isOpen: false,
      Booking: {
        Id: null,
        GroupName: null,
        RepresentativeName: null,
        RepresentativePhoneNumber: null,
        RepresentativeEmail: null,
        SaleId: null,
        RepresentativeNote: null,
      },
      isOpen: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S]
    }
  },
  watch: {
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-update-booking",IsActive:val});
      if (val) {
        this.Booking = {
          Id: this.data.Id,
          GroupName: this.data.GroupName,
          RepresentativeName: this.data.RepresentativeName,
          RepresentativePhoneNumber: this.data.RepresentativePhoneNumber,
          RepresentativeEmail: this.data.RepresentativeEmail,
          SaleId: (this.data.SaleId) ? this.data.SaleId : null,
          RepresentativeNote: this.data.RepresentativeNote,
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true
    this.doubleRaf(async () => {
      await this.getListSource_RSO01()
      await this.getListCompany_RCL01()
      // await this.getListCashier_RCS01()
      this.isLoading = false
    })
  },
  methods: {
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    // async getListCashier_RCS01() {
    //   await booking.api_RCS01().then(response => {
    //     if (response.Status === '200') {
    //       this.listSale = response?.Data?.Cashier.map(x => ({
    //         id: x.Id,
    //         label: x.CashierName,
    //       }))
    //     }
    //   })
    // },
    async updateInfoGroup_UBK01() {
      const body = { Booking: this.Booking , BookingDetailId: this.data.BookingDetail.Id}
      const response = await booking.updateBookingInfo(body)
      this.showResToast(response)
      if (response.Status == 200) {
        this.$emit('event', { type: 'after-update-group-info' })
      }
      this.$bvModal.hide('modal-update-booking-info')

    },
    // async getListGroup_RBK03() {
    //   const response = await booking.getListGroup({})
    //   if (response.Status === '200') {
    //     this.groupName.list = response?.Data?.Booking
    //     this.groupName.selected = response?.Data?.Booking[0]
    //   }
    // },
    async getListSource_RSO01() {
      const response = await commonServices.getListSource({})
      if (response.Status === '200') {
        this.listSource = response?.Data?.Source
      } else {
        this.showResToast(response)
      }
    },
    async getListCompany_RCL01() {
      const response = await commonServices.getCompany({})
      if (response.Status === '200') {
        this.listCompany = response?.Data?.Client
      } else {
        this.showResToast(response)
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.updateInfoGroup_UBK01()
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <b-modal
    id="combo-detail"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    ok-title="Cập nhật"
    ok-only
    ok-variant="primary"
    @ok="updateCombo"
    @shown="showFormPackageDetail"
  >
    <b-card-header class="banned-background-color">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 pt-1 pb-1 title-modal color-primary">
          {{ $t('golf_member_package_detail') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`#114a9f`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <div class="information">
        <b-row class="mt-1">
          <b-col cols="4">
            <span class="text">{{ $t('golf_member_combo_name') }}:</span>
          </b-col>
          <b-col cols="8">
            <span class="text" />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <span class="text">{{ $t('golf_member_combo_desc') }}:</span>
          </b-col>
          <b-col cols="8">
            <span class="text" />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <span class="text">{{ $t('golf_member_turn_leave') }}:</span>
          </b-col>
          <b-col cols="8">
            <span class="text" />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <span class="text">{{ $t('golf_member_start_time') }}:</span>
          </b-col>
          <b-col cols="8" />
        </b-row>
        <b-row class="mt-1">
          <b-col cols="4">
            <span class="text">{{ $t('golf_member_end_time') }}:</span>
          </b-col>
          <b-col cols="8" />
        </b-row>
      </div>
      <div class="list-persons mt-2">
        <div class="heading d-flex align-items-center justify-content-between">
          {{ $t('golf_member_beneficiary_list') }}
          <plus-circle-icon
            size="1.5x"
            @click="isOpen = !isOpen"
          />
        </div>
        <div
          v-if="isOpen"
          class="toggle-form mt-1"
        >
          <b-row>
            <b-col cols="5">
              <b-form-input :placeholder="$t('golf_member_lastname')" />
            </b-col>
            <b-col cols="7">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between"
                >
                  <b-form-input
                    :placeholder="$t('golf_member_firstname')"
                    style="width: 48%"
                  />
                  <div class="sex">
                    <div
                      :class="{ active: sexCode == 1 }"
                      class="day"
                      @click="sexCode = 1"
                    >
                      <ez-icon
                        icon="ezGolf-icon-male"
                        :size="18"
                      />
                    </div>
                    <div
                      :class="{ active: sexCode == 2 }"
                      class="day"
                      @click="sexCode = 2"
                    >
                      <ez-icon
                        icon="ezGolf-icon-female"
                        :size="18"
                      />
                    </div>
                    <div
                      :class="{ active: sexCode == 3 }"
                      class="day"
                      @click="sexCode = 3"
                    >
                      <ez-icon
                        icon="ezGolf-icon-other-gender"
                        :size="18"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="5">
              <b-form-input :placeholder="$t('golf_member_input_phone_number')" />
            </b-col>
            <b-col cols="7">
              <b-form-input :placeholder="$t('golf_member_input_email')" />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="5">
              <b-form-input :placeholder="$t('golf_member_card_code_2')" />
            </b-col>
            <b-col
              cols="7"
              class="text-right"
            >
              <button
                class="btn btn-default mr-1"
                @click="closeAddBeneficiary"
              >
                {{ $t('golf_common_cancel') }}
              </button>
              <button class="_button">
                {{ $t('golf_member_add_beneficiary') }}
              </button>
            </b-col>
          </b-row>
        </div>
        <div class="person-list">
          <b-row
            v-for="(beneficiary, idxB) in beneficiaryList"
            :key="idxB"
            class="align-items-center person-item"
          >
            <b-col cols="2">
              <div class="avatar" />
            </b-col>
            <b-col cols="10">
              <b-row>
                <b-col cols="12">
                  <div class="text text-nowrap overflow-hidden">
                    Mr. <span class="name">Nguyễn Văn Phương</span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="3"
                  class="mt-6"
                >
                  <div class="phone">
                    023480923
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="text-right mt-6"
                >
                  <div class="email text">
                    email@gmail.com
                  </div>
                </b-col>
                <b-col cols="1" />
                <b-col
                  cols="3"
                  class="mt-4px"
                >
                  <div class="status text d-flex align-items-center">
                    <div
                      :style="{background: '#114A9F'}"
                      class="dot"
                    />
                    <span class="text">Basic</span>
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="text-right mt-6"
                >
                  <span class="id">#202</span>
                </b-col>
                <b-col cols="1" />
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card-body>
  </b-modal>
</template>

<script>
import {
  BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { PlusCircleIcon } from 'vue-feather-icons'
import { members } from '@/api/members'

export default {
  components: {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    PlusCircleIcon,
  },
  data() {
    return {
      isOpen: false,
      sexCode: null,
      beneficiaryList: [],
    }
  },
  methods: {
    async showFormPackageDetail() {
      this.resetForm()
      const dataPost = {}
      await members.getPackageRetailDetail(dataPost).then(response => {
        if (response.Status === '200') {
        } else {
          this.showResToast(response)
        }
      })
    },
    resetForm() {
      this.isOpen = false
    },
    hideModal() {
      this.$bvModal.hide('combo-detail')
    },
    closeAddBeneficiary() {
      this.isOpen = false
    },
    updateCombo() {
    },
  },
}
</script>

<style lang="scss">
  #combo-detail {
    ._button {
    background-color: #114A9F;
    border-radius: 5px;
    padding: 9px 18px;
    color: #FFFFFF;
    border: none;
    outline: none;
    height: 32px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .mt-6 {
    margin-top: 6px;
  }
  .mt-4px {
    margin-top: 4px;
  }
    .banned-background-color {
      background: rgba(17, 74, 159, 0.12) !important;
      .color-primary {
        color: #114A9F;
      }

      border-radius: 6px 6px 0px 0px;

      .title-modal {
        padding: 10px 0;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
      }
    }
    .text {
      color: #5D5F65;
      font-size: 12px;
      line-height: 18px;
    }
    .card-body {
      padding: 10px 18px;
    }
    .heading {
      font-size: 14px;
      font-weight: 500;
      color: #424242;
      user-select: none;
      svg {
        width: 18px;
        height: 18px;
        color: #114A9F;
        cursor: pointer;
      }
    }
    .toggle-form {
      margin-left: -18px;
      margin-right: -18px;
      background: rgba(193, 193, 193, 0.12);
      padding: 10px 18px;
      .sex {
        display: inline-flex;
        align-items: center;
        .day {
          height: 32px;
          border: 1px solid #ccc;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #5d5f65;
          user-select: none;
          cursor: pointer;
        }
        .day:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-right-color: transparent;
        }
        .day:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-left-color: transparent;
        }
        .day.active {
          border: 1px solid #114a9f;
          background: rgba(17, 74, 159, 0.2);
          color: #114a9f;
        }
      }
    }
    .person-list {
      margin-top: 8px;
      overflow-y: scroll;
      max-height: 200px;
      overflow-x: hidden;
      .person-item {
        padding: 8px 0;
        .avatar {
          height: 54px;
          width: 54px;
          background: #114A9F;
          border-radius: 50%;
        }
        .name {
          font-weight: 500;
          font-size: 14px;
          white-space: nowrap;
        }
        .status {
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 6px;
          }
        }
      }
      .person-item:hover {
        background: rgba(193, 193, 193, 0.12);
      }
    }
  }
</style>

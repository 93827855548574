<template>
  <div>
    <b-modal
      id="modal-update-rate-code"
      v-model="isOpen"
      size="lg"
      centered
      :no-close-on-backdrop="true"
    >
      <template #modal-header="{ close }">
        <h4 class="mb-0 text-primary">
          {{ $t('golf_booking_change_ratecode') }}
        </h4>
        <ez-icon
          icon="ezGolf-icon-x"
          class="cursor-pointer"
          size="24"
          color="#114A9F"
          @click="close"
        />
      </template>
      <b-overlay :show="isLoading" :opacity="0.3" rounded="sm">
        <b-row>
        <b-col md="6">
          <b-form-group
            label-cols="3"
            label="Rate code"
            class="m-1"
          >
            <v-select
              v-model="rateCodes.selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="RateName"
              :options="rateCodes.list"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <div>
            <b-table-simple
              v-if="!isReRenderTable"
              hover
              small
              bordered
              class="rate-table"
            >
              <b-thead head-variant="light">
                <b-tr class="header-table-custom">
                  <b-th class="service-name" />
                  <b-th class="service-price text-center">
                    {{ $t('golf_booking_update_rate_unit_price') }}
                  </b-th>
                  <b-th class="discount-type text-center">
                    {{ $t('golf_booking_update_rate_dis_type') }}
                  </b-th>
                  <b-th class="discount-amount text-center">
                    {{ $t('golf_booking_update_rate_dis_amout') }}
                  </b-th>
                  <b-th class="discount-amount text-center">
                    {{ $t('golf_booking_update_rate_dis_code') }}
                  </b-th>
                  <b-th class="reason text-center">
                    {{ $t('golf_booking_update_rate_person') }}
                  </b-th>
                  <b-th class="reason text-center">
                    {{ $t('golf_booking_update_rate_reason') }}
                  </b-th>
                  <b-th
                    class="total-amount text-center"
                    style="width: 95px"
                  >
                  {{ $t('golf_booking_update_rate_total') }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody
                v-for="(course, index) in listCourse"
                v-if="listCourse[0]"
                :key="index"
                class="body-table-custom"
              >
                <b-tr class="header-board">
                  <b-td>{{ course.CourseName }}</b-td>
                  <b-td class="text-center disabled">
                    {{ computeSumOf(course.Hole.map(item => item.ServiceList).flat(), 'UnitPrice') | formatNumber }}
                  </b-td>
                  <!-- <b-td
                    class="text-center"
                    colspan="5"
                  /> -->
                  <b-td class="text-center">
                    <b-form-group class="mb-0">
                      <v-select
                        v-model="course.DiscountType"
                        label="title"
                        :reduce="title => title.value"
                        :options="discountType"
                        :clearable="false"
                        @input="handleChangeDiscountCourse(index, course.DiscountType, null, null, null, null)"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-form-group class="mb-0">
                      <cleave
                        v-model="course.Amount"
                        class="form-control"
                        :raw="true"
                        :options="number"
                        placeholder=""
                        @input="handleChangeDiscountCourse(index, null, course.Amount, null, null, null)"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-form-group class="mb-0">
                      <b-form-input
                        v-model="course.DiscountCode"
                        @input="handleChangeDiscountCourse(index, null, null, course.DiscountCode, null, null)"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center p-0">
                    <b-form-group class="mb-0">
                      <v-select
                        v-model="course.SignerCode"
                        label="label"
                        :reduce="item => item.id"
                        :options="listUserOfGM"
                        class="discount-code"
                        @input="handleChangeDiscountCourse(index, null, null, null, course.SignerCode, null)"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center p-0">
                    <b-form-group class="mb-0">
                      <v-select
                        v-model="course.ReasonCode"
                        label="Description"
                        :reduce="Description => Description.ReasonCode"
                        :options="reason.list"
                        class="discount-code"
                        @input="handleChangeDiscountCourse(index, null, null, null, null, course.ReasonCode)"
                      />
                    </b-form-group>
                  </b-td>

                  <b-td class="text-center disabled">
                    {{ computeSumOf(course.Hole.map(item => item.ServiceList).flat(), 'TotalAmount') | formatNumber }}
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(service, indexService) in course.Hole.map(item => item.ServiceList).flat()"
                  :key="indexService"
                  class=""
                >
                  <!-- <pre>{{ service }}</pre> -->
                  <b-td>{{ service.ServiceName }}</b-td>
                  <b-td class="text-center disabled">
                    {{ service.UnitPrice | formatNumber }}
                  </b-td>
                  <b-td class="text-center">
                    <b-form-group class="mb-0">
                      <v-select
                        v-model="service.DiscountType"
                        label="title"
                        :reduce="title => title.value"
                        :options="discountType"
                        :clearable="false"
                        @input="handleChangeDiscount(service)"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-form-group class="mb-0">
                      <cleave
                        v-model="service.Amount"
                        class="form-control"
                        :raw="true"
                        :options="number"
                        placeholder=""
                        @input="handleChangeDiscount(service)"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-form-group class="mb-0">
                      <b-form-input
                        v-model="service.DiscountCode"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center p-0">
                    <b-form-group class="mb-0">
                      <v-select
                        v-model="service.SignerCode"
                        label="label"
                        :reduce="item => item.id"
                        :options="listUserOfGM"
                        class="discount-code"
                        @input="reRenderTable()"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center p-0">
                    <b-form-group class="mb-0">
                      <v-select
                        v-model="service.ReasonCode"
                        label="Description"
                        :reduce="Description => Description.ReasonCode"
                        :options="reason.list"
                        class="discount-code"
                        @input="reRenderTable()"
                      />
                    </b-form-group>
                  </b-td>
                  <b-td class="text-center">
                    {{ Number(service.TotalAmount) | formatNumber }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </b-row>
      </b-overlay>
      
      <b-row>
        <b-col md="12">
          <b-form-group class="mx-1">
            <b-form-checkbox
              v-model="isIncludedTax"
              :disabled="true"
            >
              {{ $t('golf_ratecode_is_taxable') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- <b-col md="12">
          <b-form-group class="mx-1">
            <div class="d-flex align-center">
              <b-form-checkbox
                v-model="isFreeTax"
                :value="true"
                class="mt-50"
              >
                {{ $t('golf_common_free') }}
              </b-form-checkbox>

              <v-select
                v-model="reason.selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="Description"
                :placeholder="$t('golf_booking_reason_discount')"
                :reduce="Description => Description.ReasonCode"
                :options="reason.list"
                style="width: 300px"
              />
            </div>
          </b-form-group>
        </b-col> -->
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="save_UKB03"
          >
          {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :is-active="isOpen"
      :all-child-component-closed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
    <confirm-dialog
      ref="confirmDeleteService"
      @confirmDialog="DeleteService"
    />
  </div>
</template>

<script>
import Decimal from 'decimal.js'
import vSelect from 'vue-select'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { booking } from '@/api/booking'
import { paramsUpdateRateCode } from '@/views/modules/booking/modal/params/paramsUpdateRateCode'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Cleave from 'vue-cleave-component'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'

dayjs.extend(customParseFormat)

export default {
  name: 'UpdateRateCode',
  components: {
    vSelect,
    Cleave,
    ShortKey,
    'confirm-dialog': ConfirmDialog
  },
  props: ['data', 'listUserOfGM'],
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        // numeralDecimalMark: '',
        // delimiter: '',
        // numericOnly: true,
        numeralDecimalScale: 0,
      },
      rateCodes: {
        list: [],
        selected: null,
      },
      reason: {
        list: [],
        selected: null,
      },
      isIncludedTax: null,
      isFreeTax: false,
      oldDiscount: 0,
      isLoading: true,
      listCourse: [
        {
          Hole: [],
        },
      ],
      listWeeklyCode: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      isReRenderTable: false,
      errorDiscountReason: false,
      errorSignerCode: false,
      errorDiscountCode: false,
      errorDiscountType: false,
      discountType: [
        {
          title: '%',
          value: 'PERCENT',
        },
        {
          title: this.$t('golf_common_money'),
          value: 'FIX',
        },
      ],
      Cashiers: [],
      isOpen: false,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [],
      isLoading: false
    }
  },
  watch: {
    'rateCodes.selected': async function (newValue, _) {
      if(this.rateCodes.selected) {
        if (this.data.BookingDetail.Id) {
          if (this.rateCodes.selected.Id != -1) {
            await this.getListServices_RBK05()
            this.isIncludedTax = this.rateCodes.selected.IsTaxable
          }
        }
      }
    },
    'reason.selected': async function (newValue, _) {
      this.changeReason()
    },
    // data(value) {
    //   console.log('update rate code',value)
    // },
    async isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-update-rate-code",IsActive:val});
      if (val) {
        if (this.data.BookingDetail.Id) {
          await this.getRateCodes_RRA04()
          await this.getListReasonDiscount_RRC01()
        }
      } else {
        this.clearData()
      }
    },
    // async data(value) {
    //   if (value.BookingDetail.Id) {
    //     await this.getRateCodes_RRA04()
    //     await this.getListReasonDiscount_RRC01()
    //     this.rateCodes.selected = {
    //       Id: this.data.BookingDetail.RateId,
    //       RateName: this.data.BookingDetail.RateName,
    //     }
    //   }
    // },
    isFreeTax() {
      this.free()
    },
  },
  async created() {
    this.isLoading = true
    // this.getListCashier_RCS01()
    this.doubleRaf(async () => {
      this.isLoading = false
    })
  },
  methods: {
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(async response => {
        if (response.Status === '200') {
          this.Cashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    clearData() {
      this.rateCodes = {
        list: [],
        selected: null,
      }
    },
    reRenderTable() {
      this.isReRenderTable = true
      this.isReRenderTable = false
    },
    free() {
      for (const course of this.listCourse) {
        for (const hole of course.Hole) {
          for (const service of hole.ServiceList) {
            service.oldDiscount = service.DiscountPercent || 0
            if (this.isFreeTax) {
              service.DiscountPercent = 100
              service.DiscountAmount = 0
            } else {
              service.DiscountPercent = service.oldDiscount != 100 ? service.oldDiscount : 0
              service.DiscountAmount = 0
            }

            this.handleChangeDiscount(service, 'Percent')
          }
        }
      }
      this.$forceUpdate()
    },
    changeReason() {
      for (const course of this.listCourse) {
        for (const hole of course.Hole) {
          for (const service of hole.ServiceList) {
            service.ReasonCode = this.reason.selected
            // service.DiscountCode = this.reason.selected
            // service.DiscountType = 'FIX'
          }
        }
      }
    },
    computeBookingDetailServices(Hole) {
      this.errorDiscountReason = false
      this.errorDiscountCode = false
      this.errorSignerCode = false
      this.errorDiscountType = false
      const list = Hole.map(hole => hole.ServiceList.map(service => {
        const data = {
          BookingDetailId: Number(hole.BookingDetailCourseId),
          ServiceId: Number(service.ServiceId),
          RateId: Number(service.RateId),
          UnitPrice: Number(service.UnitPrice),
          Quantity: Number(service.Quantity),
          Amount: Number(service.UnitPrice) * Number(service.Quantity),
          TotalAmount: Number(service.TotalAmount),
          BookingDetailCourseId: hole.BookingDetailCourseId,
          TransactionCode: service.TransactionCode,
          RateAdditionalType: service.RateAdditionalType,
          RateAdditionalId: service.RateAdditionalId,
         //31/1/2023 - Fix bug Add ServiceId4eInvoice
          ServiceId4eInvoice: service.ServiceId4eInvoice,
        }
        if (service.Amount) {
          data.Discount = [
            {
              DiscountAmount: Number(service.DiscountAmount),
              DiscountType: service.DiscountType,
              ReasonCode: service.ReasonCode ? service.ReasonCode : null,
              Amount: Number(service.Amount),
              SignerCode: service.SignerCode,
              DiscountCode: service.DiscountCode,
            },
          ]

          if (!service.ReasonCode) {
            this.errorDiscountReason = true
          }
          if (!service.DiscountType) {
            this.errorDiscountType = true
          }
          if (!service.SignerCode) {
            this.errorSignerCode = true
          }
          if (!service.DiscountCode) {
            this.errorDiscountCode = true
          }
        } else {
          data.Discount = null
        }

        return data
      }))
      return list.flat()
    },
    computeSumOf(listServices, type) {
      let sum = 0

      listServices.map(item => {
        sum += (type === 'UnitPrice' ? item.UnitPrice : item.TotalAmount)
      })
      return (type === 'UnitPrice' ? sum.toFixed(0) : sum.toFixed(0))
    },
    computeBookingDetailCourse(BookingDetailCourse) {
      return BookingDetailCourse.map(item => ({ Id: item.CourseId, NumberOfHole: item.NumberOfHole }))
    },
    handleChangeDiscount(service) {
      if (service.DiscountType == 'PERCENT') {
        // Giá trị lớn hơn 100 thì set về 100
        if (Math.abs(service.Amount) > 100) {
          service.Amount = 100
        }
        service.DiscountAmount = (service.Amount * (service.UnitPrice * service.Quantity)) / 100
      } else if (service.DiscountType == 'FIX') {
        if (Math.abs(service.Amount) > service.UnitPrice * service.Quantity) {
          service.Amount = service.UnitPrice * service.Quantity
        }
        service.DiscountAmount = service.Amount
      } else {
        service.DiscountAmount = 0
      }
      service.TotalAmount = service.UnitPrice * service.Quantity - Math.abs(service.DiscountAmount)
      this.$forceUpdate()
    },
    handleChangeDiscountCourse(index, discountType, amount, discountCode, signerCode, reasonCode) {
      this.listCourse.forEach((x,i) => {
        // Giá trị lớn hơn 100 thì set về 100
        if(i === index) {
          x.Hole.map(item => item.ServiceList).flat().forEach(service => {
            if (amount) {
              service.Amount = amount
            }
            if (discountCode) {
              service.DiscountCode = discountCode
            }
            if (signerCode) {
              service.SignerCode = signerCode
            }
            if (reasonCode) {
              service.ReasonCode = reasonCode
            }
            if (discountType == 'PERCENT') {
              service.DiscountType = 'PERCENT'

              if (Math.abs(x.Amount) > 100) {
                x.Amount = 100
              }

              // Giá trị lớn hơn 100 thì set về 100
              if (Math.abs(service.Amount) > 100) {
                service.Amount = 100
              }

              service.DiscountAmount = (service.Amount * (service.UnitPrice * service.Quantity)) / 100
              service.TotalAmount = service.UnitPrice * service.Quantity - Math.abs(service.DiscountAmount)
            } else if (discountType == 'FIX') {
              service.DiscountType = 'FIX'
              if (Math.abs(service.Amount) > service.UnitPrice * service.Quantity) {
                service.Amount = service.UnitPrice * service.Quantity
              }
              service.DiscountAmount = service.Amount
              service.TotalAmount = service.UnitPrice * service.Quantity - Math.abs(service.DiscountAmount)
            } 

          })
        }
        
      })

      this.$forceUpdate()
    },
    async save_UKB03() {

      if (!this.rateCodes.selected) {
        return this.$toast.error(this.$t('please_select_new_rate'))
      }

      const params = paramsUpdateRateCode
      params.BookingDetail.Id = this.data.BookingDetail.Id
      params.BookingDetail.RateId = this.rateCodes.selected.Id
      params.BookingDetail.Reasons = (this.reason.selected) ? this.reason.selected.name : null
      if (this.listCourse.length) {
        params.BookingDetail.BookingDetailServices = this.computeBookingDetailServices(this.listCourse.map(item => item.Hole).flat())
      }

      if (this.errorDiscountType) {
        return this.showToast('error', this.$t('please_select_discount_type'))
      }

      // if (this.errorDiscountCode) {
      //   return this.showToast('error', this.$t('please_enter_discount_code'))
      // }

      // if (this.errorSignerCode) {
      //   return this.showToast('error', this.$t('please_enter_signer_code'))
      // }

      if (this.errorDiscountReason) {
        return this.showToast('error', this.$t('please_select_reason'))
      }

      const response = await booking.updateRateCode(params)
      this.showResToast(response)
      if(response.Status === '400' && response.Messages[0].Code === 'E.UBK03.C06') {
        const dataConfirm = {
          dataConfirm: {
            title: 'Xóa dịch vụ',
            content: 'Bạn có muốn xóa dịch vụ khỏi hóa đơn?',
          },
        }
        this.$refs.confirmDeleteService.open(dataConfirm)
      } else {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-update-rate-code')
        })
        this.$emit('event', { type: 'after-update-booking-rate-code' })
      }
      
    },
    async DeleteService(item) {
      let dataBookingDetailServiceId = []
      for (const course of this.listCourse) {
        for (const hole of course.Hole) {
          for (const service of hole.ServiceList) {
            dataBookingDetailServiceId.push(service.Id)
          }
        }
      }
      
      const body = {
        BookingDetailServiceId: dataBookingDetailServiceId,
      }
      await booking.api_DPM07(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.save_UKB03()
        }
      })
    },
    async getRateCodes_RRA04() {
      this.isLoading = true
      const weeklyCode = this.listWeeklyCode[new Date().getDay()]
      const response = await booking.getRateCode({
        BookingDetailId: this.data?.BookingDetail?.Id,
        Date: dayjs(this.data.BookingDetail.OpenDate).format('YYYY-MM-DD'),
        Course: this.computeBookingDetailCourse(this.data.BookingDetail.BookingDetailCourse),
        GolfClassId: this.data.BookingDetail.GolfClassId,
        WeeklyCode: String(weeklyCode),
        ClientId: (this.data.BookingDetail.ClientId) ? this.data.BookingDetail.ClientId : null,
        SourceId: (this.data.BookingDetail.SourceId) ? this.data.BookingDetail.SourceId : null,
        MemberSubscriptionId: (this.data.BookingDetail.MemberSubscriptionId) ? this.data.BookingDetail.MemberSubscriptionId : null
      })

      if (response.Status === '200') {
        this.rateCodes.list = response?.Data?.Rate
        this.rateCodes.selected = {
          Id: this.data.BookingDetail.RateId,
          RateName: this.data.BookingDetail.RateName,
        }
      }
    },
    async getListServices_RBK05() {
      const params = {
        BookingDetailId: this.data.BookingDetail.Id,
        RateId: this.rateCodes.selected.Id,
      }
      const response = await booking.getListServices(params)
      if (response.Status === '200') {

        for (const course of response?.Data?.Course) {
          let check = true
          for (const hole of course.Hole) {
            for (const service of hole.ServiceList) {
              if (service.Discount.length) {
                check = false
                service.Amount = service.Discount[0].Amount
                service.DiscountAmount = service.Discount[0].DiscountAmount
                service.DiscountCode = service.Discount[0].DiscountCode
                service.DiscountType = service.Discount[0].DiscountType
                service.ReasonCode = service.Discount[0].ReasonCode
                service.SignerCode = service.Discount[0].SignerCode
                this.handleChangeDiscount(service)
              } else {
                service.Amount = null
                service.DiscountType = 'PERCENT'
              }
            }
          }
          if(check) {
            course.DiscountType = 'PERCENT'
          }
        }
        this.listCourse = response?.Data?.Course
        this.isFreeTax = false
        this.isLoading = false
      }
      this.$forceUpdate()
    },
    async getListReasonDiscount_RRC01() {
      const response = await booking.getListReasonDiscount({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      })
      if (response.Status === '200') {
        this.reason.list = response?.Data?.Reason
        // this.reason.selected = response?.Data?.Reason[0].ReasonCode
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.save_UKB03()
      }
    }
  },
}
</script>

<style lang="scss">
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 900px;
  }

}

.rate-table {
  .vs__dropdown-toggle {
      border: none!important;
      border-radius: 0!important;
  }
  .header-table-custom {
    th {
      background: #F2F3F7;
      border-bottom: unset;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 10px;
      td {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #5D5F65;
        padding: 10px;
      }
      &.service-name {
        width: 22%;
      }
      &.service-price {
        width: 10%;
      }
      &.discount-type {
        width: 10%;
      }
      &.discount-amount {
        width: 9%;
      }
      &.reason {
        width: 16%;
      }
      &.total-amount {
        width: 20%;
      }
    }
  }
  .body-table-custom {
    tr.header-board {
      td {
        font-weight: 500;
      }
    }
    td {
      height: 32px;
      padding: 0;
      &:first-child {
        padding: 0 10px;
      }
      input {
        border: none;
        border-radius: 0;
      }
      &.disabled {
        background: rgba(193, 193, 193, 0.12)
      }
      .discount-code {
        span.vs__selected {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            width: 84px;
        }
      }
    }
  }
}

</style>

<template>
  <div>
    <b-modal
      id="modal-update-booking"
      size="lg"
      :no-close-on-backdrop="true"
      v-model="isOpen"
    >
      <template #modal-header="{ close }">
        <h4 class="mb-0 text-primary">
          {{ $t('golf_common_edit_play_info') }}
        </h4>
        <ez-icon
          icon="ezGolf-icon-x"
          class="cursor-pointer"
          size="24"
          color="#114A9F"
          @click="close"
        />
      </template>
      <b-card-body>
        <b-row>
          <b-col cols="4">
            <p class="player-info">
              {{ $t('golf_booking_play_info') }}
            </p>
            <b-form-group :label="$t('golf_boongking_course')">
              <v-select
                label="CourseName"
                @input="changeDateTime()"
                v-model="CourseSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listCourse"
                :multiple="false"
                :clearable="false"
              >
                <template #selected-option="{ CourseName, Color }">
                  <ez-icon
                    icon="ezGolf-icon-golf-course"
                    :color="Color"
                    :size="18"
                  />
                  {{ CourseName }}
                </template>
                <template v-slot:option="item">
                  <ez-icon
                    icon="ezGolf-icon-golf-course"
                    :size="18"
                    :color="item.Color"
                  />
                  {{ item.CourseName }}
                </template>
              </v-select>
            </b-form-group>
            <b-form-group :label="$t('golf_boongking_play_date')">
              <date-range-picker
                ref="picker"
                v-model="openDate"
                :single-date-picker="true"
                :ranges="false"
                :time-picker="false"
                :time-picker-increment="1"
                opens="right"
                :show-dropdowns="true"
                :auto-apply="true"
                :locale-data="{
                  format: 'DD/MM/YYYY',
                  daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                  firstDay: 1
                }"
                @update="changeDateTime()"
              />
            </b-form-group>

            <b-form-group :label="$t('golf_golf_class_name')">
              <v-select
                v-model="golfClasses.selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="golfClasses.list"
                :clearable="false"
                label="GolfClassName"
                @input="changeDateTime()"
              >
                <template #selected-option="{ GolfClassName, Color }">
                  <div
                    class="dot-size-10 d-inline-block rounded-circle"
                    :style="{backgroundColor: Color}"
                    style="margin-top: -2px;"
                  />
                  {{ GolfClassName }}
                </template>
                <template v-slot:option="{ GolfClassName, Color }">
                  <div
                    class="dot-size-10 d-inline-block rounded-circle"
                    :style="{backgroundColor: Color}"
                    style="margin-top: -2px;"
                  />
                  {{ GolfClassName }}
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              v-if="!isLoadingRate"
              :label="$t('golf_booking_new_rate')"
            >
              <v-select
                v-model="rates.new.selected"
                :options="rates.new.list"
                label="RateName"
                :clearable="false"
              />
            </b-form-group>
            <div
              v-if="rates.new.selected && rates.new.selected.RateGolfClassRule && rates.new.selected.RateGolfClassRule.length"
              class="row"
            >
              <b-col>
                <b-form-group :label="$t('golf_booking_member_card_code')">
                  <b-form-input
                    :autofocus="rates.new.selected && rates.new.selected.RateGolfClassRule && rates.new.selected.RateGolfClassRule.length"
                    v-model="rateGolfClassRule.cardCode"
                    @change="getListBDCByCardCode(true)"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="BDC">
                  <v-select
                    v-model="rateGolfClassRule.BDC"
                    :options="rateGolfClassRule.BDCList"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </div>

            <b-form-group :label="$t('golf_booking_old_rate')">
              <b-form-input
                v-model="data.BookingDetail.RateName"
                :disabled="true"
              />
            </b-form-group>

            <b-form-group :label="$t('golf_booking_copy_number_hole')">
              <div class="hover-editable disable">
                <span
                  v-for="(hole, index) in data.BookingDetail.BookingDetailCourse"
                  :key="hole.id"
                >
                  {{ hole.NumberOfHole }} Hole - {{ hole.Course.CourseName }}
                  <template v-if="data.BookingDetail.BookingDetailCourse.length > 1 && (data.BookingDetail.BookingDetailCourse.length - 1) != index">
                    <span class="mx-50">+</span>
                  </template>
                </span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('golf_common_company')">
              <b-form-input
                v-model="data.BookingDetail.ClientName"
                :disabled="true"
              />
            </b-form-group>
            <b-form-group :label="$t('golf_common_source')">
              <b-form-input
                v-model="data.BookingDetail.SourceName"
                :disabled="true"
              />
            </b-form-group>

            <!-- <div class="mb-1">
              <b-form-checkbox v-model="keepOldRate.value">
                {{ $t('golf_booking_keep_old_rate') }}
              </b-form-checkbox>

              <div
                v-if="keepOldRate.value === true"
                class="my-1"
              >
                <b-form-input
                  v-model="keepOldRate.reason"
                  :placeholder="$t('golf_booking_enter_reason')"
                />
              </div>
            </div>

            <b-form-checkbox v-model="getAnotherFee.value">
              Thu phụ phí chuyển Teetime
            </b-form-checkbox>

            <div
              v-if="getAnotherFee.value === true"
              class="my-1"
            >
              <b-form-input
                v-model="getAnotherFee.reason"
                placeholder="Nhập lý do"
                class="mb-1"
              />

              <b-form-input
                v-model="getAnotherFee.amount"
                placeholder=" Nhập số tiền"
              />
            </div> -->
          </b-col>
          <b-col cols="8">
            <b-table-simple
              responsive
              bordered
              sticky-header
            >
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="(item, index) in fields"
                    :key="index"
                    class=""
                    style="vertical-align: middle"
                  >
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      {{ item }}
                    </div>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <b-td style="width: 100px">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <p class="mb-0 time-label">
                        {{ utcConvertToLocalTimeZone(item.Time, 'HH:mm' ) }}
                      </p>
                    </div>
                  </b-td>
                  <b-td
                    v-for="(tee, indexTeeTime) in item.TeeTime"
                    :key="indexTeeTime"
                    :class="data.BookingDetail.TeeTimeId && data.BookingDetail.TeeTimeId === tee.Id ? 'selected-background-color cursor-pointer' :
                      Number(tee.NumberPlayer) === 0 ? 'blocked-background-color cursor-not-allowed' : 'bg-white cursor-pointer'"
                    class="p-0 position-relative"
                    @click="(!tee.IsBlock && !tee.IsDeleted) ? chooseTee(tee) : ''"
                  >
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <div
                        v-if="!tee.IsBlock && !tee.IsDeleted"
                        class="d-flex justify-content-center align-items-center"
                        :class="tee.Id === data.BookingDetail.TeeTimeId ? 'text-primary font-weight-bold' : ''"
                      >
                        <div class="text-center">
                          <div class="text-center">
                            <span>{{ tee.Id === data.BookingDetail.TeeTimeId ? tee.NumberPlayer - 1 : tee.NumberPlayer }}</span>
                          </div>
                        </div>
                        <div v-if="tee.Id === data.BookingDetail.TeeTimeId">
                          <ez-icon
                            icon="ezGolf-icon-check"
                            size="18"
                            class="position-absolute"
                            style="top: 7px; right: 8px;"
                          />
                        </div>
                      </div>
                      <div
                        v-else
                        class="text-center cursor-not-allowed"
                      >
                        <ez-icon
                          :icon="(tee.IsBlock) ? 'ezGolf-icon-lock' : 'ezGolf-icon-slash'"
                          :size="18"
                        />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-card-body>
      <template #modal-footer>
      <b-button size="md" variant="primary" @click="updateBooking_UBK02()">
        {{ $t('golf_common_confirm') }}
      </b-button>
    </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
    <confirm-dialog
      ref="confirmUpdateCaddy"
      @confirmDialog="updateBookingDulicate_UBK02()"
    />
    <confirm-dialog
      ref="confirmUpdateHole"
      @confirmDialog="updateBookingLockTee_UBK02"
    />
  </div>
  
</template>

<script>
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'

import vSelect from 'vue-select'
import { golfClassSetting } from '@/api/golf-class-setting'
import { booking } from '@/api/booking'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
export default {
  name: 'SidebarUpdateBooking',
  components: { vSelect,ShortKey, 'confirm-dialog': ConfirmDialog },
  props: ['data', 'chooseDate', 'listCourse'],
  data() {
    return {
      openDate: '',
      golfClasses: {
        selected: null,
        list: [],
      },
      hole: {
        selected: 0,
        list: [],
      },
      company: {
        selected: '',
        list: [],
      },
      rates: {
        old: {
          selected: '',
          list: [],
        },
        new: {
          selected: '',
          list: [],
        },
      },
      keepOldRate: {
        value: false,
        reason: '',
      },
      getAnotherFee: {
        value: false,
        reason: '',
        amount: '',
      },
      listWeeklyCode: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      fields: [],
      items: [],
      selectedTeeTime: [],
      hovering: {
        indexTeeTime: '',
        indexPlayer: [],
      },
      additionalFee: {
        selected: null,
        list: [],
      },
      zoneId: [],
      teeTimeWasPicked: '',
      rateGolfClassRule: {
        cardCode: null,
        BDCList: [],
        BDC: null,
      },
      isLoadingRate: false,
      isOpen: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      CourseSelected: null,
    }
  },
  watch: {
    data(value) {
      if(value.BookingDetail.Id) {
        this.openDate = this.data.BookingDetail.OpenDate
      this.doubleRaf(async () => {
        await this.getGolfClass_RGC01()
        await this.getRateCodes_RRA04()
        await this.getListTeeTimeBooking_RTB01()
        await this.getConfigInfo_RCOM01()
        await this.getListServicesFee_RSV02()

        const bookingDetailCourse = this.data?.BookingDetail?.BookingDetailCourse
        this.CourseSelected = this.listCourse.find(x => x.Id == bookingDetailCourse[0].CourseId)
        this.hole.list = bookingDetailCourse.map(item => item.NumberOfHole)
        this.hole.selected = bookingDetailCourse.map(item => item.NumberOfHole)[0]
        this.company.selected = this.data.ClientName

        this.rateGolfClassRule.cardCode = this.data.BookingDetail.CardCode4GolfClassRule
        this.getListBDCByCardCode(false)
        this.rateGolfClassRule.BDC = this.data.BookingDetail.BDC4GolfClassRule
      })
      }
    },
    'rates.new.selected': async function (newValue, _) {
      this.rateGolfClassRule.cardCode = null
      this.rateGolfClassRule.BDCList = []
      this.rateGolfClassRule.BDC = null
    },
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-update-booking",IsActive:val});
    },
  },
  methods: {
    chooseTee(tee) {
      if (tee.NumberPlayer > 0) {
        this.data.BookingDetail.TeeTimeId = tee.Id
      }
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    hideSideBar() {
      this.$root.$emit('bv::hide::modal', 'modal-update-booking')
    },
    handleCleanTeeTime(_teeTime) {
      let teeTimeRep = this.selectedTeeTime
      teeTimeRep = teeTimeRep.filter(item => item !== _teeTime.Id)
      this.$store.commit('booking/SELECTED_TEE', { teeTimeRep })
      this.selectedTeeTime = teeTimeRep
      this.hovering = {
        indexTeeTime: '',
        indexPlayer: [],
      }
    },
    handleClickTeeTimeCell(_teeTime) {
      const teeTimeRep = this.selectedTeeTime
      teeTimeRep.push(_teeTime.Id)
      this.$store.commit('booking/SELECTED_TEE', { teeTimeRep })
      this.selectedTeeTime = teeTimeRep
      this.teeTimeWasPicked = _teeTime
    },
    addPlayerToTee(tee, value, indexItems, indexTeeTime) {
      const players = this.hovering.indexPlayer
      const oldValue = this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer
      this.handleClickTeeTimeCell(tee)

      if ((oldValue === value && oldValue !== 0) || (oldValue > value)) {
        this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value - 1
        if (value - 1 === 0) {
          this.handleCleanTeeTime(tee)
        }
      } else {
        this.items[indexItems].TeeTime[indexTeeTime].BookedNumberPlayer = value
      }

      this.hovering.indexTeeTime = tee.Id
      if (players.includes(value)) {
        this.hovering.indexPlayer = players.filter(item => item !== value).filter(item => item < value)
        return
      }

      for (let i = 0; i < value; i++) {
        this.hovering.indexPlayer.push(i + 1)
      }
    },
    autoRang(start, end, step = 1, offset = 0) {
      const len = (Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1
      const direction = start < end ? 1 : -1
      const startingPoint = start - (direction * (offset || 0))
      const stepSize = direction * (step || 1)

      return Array(len).fill(0).map((_, index) => startingPoint + (stepSize * index))
    },
    computeBookingDetailCourse(BookingDetailCourse, check) {
      const bkDetailCourse = []
      const data = JSON.parse(JSON.stringify(BookingDetailCourse))
      if(this.CourseSelected) {
        data[0].CourseId = this.CourseSelected.Id
      }
      if(check) {
        data.forEach(x => {
          if(!bkDetailCourse.find(y => y.Id == x.CourseId)) {
            let sum = 0
            let sumActual = 0
            data.forEach(item => {
              if(item.CourseId == x.CourseId) {
                sum += item.NumberOfHole
                sumActual += item.ActualNumberOfHole
              }
            })
            bkDetailCourse.push(
              {
                CourseId: x.CourseId,
                NumberOfHole: sum,
                ActualNumberOfHole: sumActual
              }
            )
          }
        })
      } else {
        data.forEach(x => {
          if(!bkDetailCourse.find(y => y.Id == x.CourseId)) {
            let sum = 0
            let sumActual = 0
            data.forEach(item => {
              if(item.CourseId == x.CourseId) {
                sum += item.NumberOfHole
                sumActual += item.ActualNumberOfHole
              }
            })
            bkDetailCourse.push(
              {
                Id: x.CourseId,
                NumberOfHole: sum,
                ActualNumberOfHole: sumActual
              }
            )
          }
        })
      }
      
      return bkDetailCourse
    },
    async getGolfClass_RGC01() {
      const CourseId = this.data?.BookingDetail?.BookingDetailCourse.map(item => item.CourseId)
      const response = await golfClassSetting.getListGolfClass({ CourseId })
      if (response.Status === '200') {
        this.golfClasses.list = response?.Data?.GolfClass
        this.golfClasses.selected = response?.Data?.GolfClass.find(
          item => item.GolfClassName === this.data.BookingDetail.GolfClassName,
        )
      }
    },
    async changeDateTime() {
      await this.getRateCodes_RRA04(true)
      await this.getListTeeTimeBooking_RTB01()
    },
    async getRateCodes_RRA04(setNewRate) {
      this.isLoadingRate = true
      const weeklyCode = this.listWeeklyCode[new Date().getDay()]
      await booking.getRateCode({
        BookingDetailId: this.data?.BookingDetail?.Id,
        Date: this.openDate, // this.data.BookingDetail.OpenDate,
        Course: this.computeBookingDetailCourse(this.data.BookingDetail.BookingDetailCourse),
        // GolfClassId: this.data.BookingDetail.GolfClassId,
        GolfClassId: (this.golfClasses.selected.Id) ? this.golfClasses.selected.Id : null,
        WeeklyCode: String(weeklyCode),
        ClientId: (this.data.BookingDetail.ClientId) ? this.data.BookingDetail.ClientId : null,
        SourceId: (this.data.BookingDetail.SourceId) ? this.data.BookingDetail.SourceId : null,
        MemberSubscriptionId: (this.data.BookingDetail.MemberSubscriptionId) ? this.data.BookingDetail.MemberSubscriptionId : null
      }).then(response => {
        if (response.Status === '200') {
          this.rates.new.list = response?.Data?.Rate
          if (setNewRate) {
            this.rates.new.selected = (response?.Data?.Rate[0]) ? response?.Data?.Rate[0] : null
          } else {
            this.rates.new.selected = response?.Data?.Rate.find(item => item.Id === this.data.BookingDetail.RateId)
          }
        }
        this.isLoadingRate = false
      })
    },
    async getListTeeTimeBooking_RTB01() {
      this.fields = []
      this.items = []
      const weeklyCode = this.listWeeklyCode[new Date().getDay()]

      const params = {
        Date: this.openDate, // this.data.BookingDetail.OpenDate,
        // GolfClassId: this.data.BookingDetail.GolfClassId,
        GolfClassId: (this.golfClasses.selected) ? this.golfClasses.selected.Id : null,
        RateId: (this.rates.new.selected) ? this.rates.new.selected.Id : null, // ) ? this.rates.new.selected.Id : this.data.BookingDetail.RateId, // this.data.BookingDetail.RateId,
        Course: this.computeBookingDetailCourse(this.data.BookingDetail.BookingDetailCourse),
        BookingDetailId: this.data.BookingDetail.Id,
        WeeklyCode: String(weeklyCode),
      }
      if (this.rates.new.selected.Id == -1) {
        return
      }
      const response = await booking.getListTeeTimeBooking(params)
      if (response.Status === '200') {
        const teeNames = response.Data?.TeeSheet[0].TeeTime.map(tee => (tee.TeeName))
        this.fields = ['TEETIME', ...teeNames]
        this.items = response.Data?.TeeSheet
      } else {
        this.showResToast(response)
      }
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
      }
    },
    async getListServicesFee_RSV02() {
      const response = await booking.getListServicesFee({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
        Type: 'BOOKING_TEETIME_CHARGE_FEE',
        BookingType: 'COURSE',
      })
      if (response.Status === '200') {
        this.additionalFee.list = response?.Data?.Menu.map(item => item.MenuDetail).flat()
        this.additionalFee.selected = response?.Data?.Menu.map(item => item.MenuDetail).flat()[0]
      }
    },
    async getListBDCByCardCode(isResetBDC) {
      const response = await booking.api_RBK10({
        Date: this.openDate,
        CardCode: this.rateGolfClassRule.cardCode,
      })
      if (response.Status === '200') {
        if (isResetBDC) {
          this.rateGolfClassRule.BDC = null
        }
        this.rateGolfClassRule.BDCList = response.Data.BDC
        if(this.rateGolfClassRule.BDCList.length > 0) {
          this.rateGolfClassRule.BDC = response.Data.BDC[0]
        } else {
          this.rateGolfClassRule.BDC = null
        }
      }
    },
    async updateBooking_UBK02(isdupcaddy) {
      if (this.rates.new.selected.RateGolfClassRule.length && !this.rateGolfClassRule.cardCode || this.rates.new.selected.RateGolfClassRule.length && !this.rateGolfClassRule.BDC) {
        this.showToast('error', this.$t('golf_error_enter_card_code_bdc'))
        return
      }

      const bookingDetailServices = this.additionalFee.list.map(feeDetail => ({
        BookingDetailId: this.data.Id,
        ServiceId: feeDetail.ProductId,
        UnitPrice: Number(feeDetail.ProductPrice),
        Quantity: 1,
        Amount: Number(this.getAnotherFee.amount),
        TotalAmount: Number(this.getAnotherFee.amount),
        TransactionCode: String(feeDetail.TransactionCode.TransactionCode),
      }))

      const params = {
        BookingDetail: {
          Id: this.data.BookingDetail.Id,
          ClientId: this.data.BookingDetail.ClientId,
          SourceId: this.data.BookingDetail.SourceId,
          GolfClassId: (this.golfClasses.selected.Id) ? this.golfClasses.selected.Id : null,
          TeeTimeId: this.data.BookingDetail.TeeTimeId,
          RateId: (this.rates.new.selected.Id) ? this.rates.new.selected.Id : null,
          CardCode4GolfClassRule: this.rateGolfClassRule.cardCode,
          Bdc4golfClassRule: this.rateGolfClassRule.BDC,
          BookingDetailServices: this.getAnotherFee.value ? bookingDetailServices : undefined,
          BookingDetailCourse: this.computeBookingDetailCourse(this.data.BookingDetail.BookingDetailCourse, true),
        },
        IsForceUpdateCaddie: isdupcaddy ? true : false
      }

      const response = await booking.updateTeeTimeForBooking(params)
      this.showResToast(response)
      if (response.Status == '200') {
        this.$nextTick(() => {
          this.$emit('event', { type: 'after-update-booking-tee-time', newTeeTimeId: this.data.BookingDetail.TeeTimeId })
          this.$bvModal.hide('modal-update-booking')
        })
      } else if (response.Status == '400' && response.Messages.length > 0) {
        if(response.Messages[0].Code == "E.UBK02.C37") {
          const dataConfirm = {
              dataConfirm: {
                title: 'Cập nhật Caddy',
                content: 'Bạn có muốn cập nhật trùng caddy?',
                data: params
              },
            }
            this.$refs.confirmUpdateCaddy.open(dataConfirm)
        } else if (response.Messages[0].Code == "E.UBK02.C18") {
          const dataConfirm = {
              dataConfirm: {
                title: 'Cập nhật TeeTime',
                content: 'Bạn có muốn bỏ qua khóa hố không?',
                data: params
              },
            }
            this.$refs.confirmUpdateHole.open(dataConfirm)
        }
        // this.updateBooking_UBK02(true)
      }
    },
    async updateBookingDulicate_UBK02(data) {
      const params = data.dataConfirm.data
      params.IsForceUpdateCaddie = true
      const response = await booking.updateTeeTimeForBooking(params)
      this.showResToast(response)
      if (response.Status == '200') {
        this.$nextTick(() => {
          this.$emit('event', { type: 'after-update-booking-tee-time', newTeeTimeId: this.data.BookingDetail.TeeTimeId })
          this.$bvModal.hide('modal-update-booking')
        })
      } else if (response.Status == '400' && response.Messages.length > 0) {
        if(response.Messages[0].Code == "E.UBK02.C37") {
          const dataConfirm = {
              dataConfirm: {
                title: 'Cập nhật Caddy',
                content: 'Bạn có muốn cập nhật trùng caddy?',
                data: params
              },
            }
            this.$refs.confirmUpdateCaddy.open(dataConfirm)
        } else if (response.Messages[0].Code == "E.UBK02.C18") {
          const dataConfirm = {
              dataConfirm: {
                title: 'Cập nhật TeeTime',
                content: 'Bạn có muốn bỏ qua khóa hố không?',
                data: params
              },
            }
            this.$refs.confirmUpdateHole.open(dataConfirm)
        }
        // this.updateBooking_UBK02(true)
      }
    },
    async updateBookingLockTee_UBK02(data) {
      const params = data.dataConfirm.data
      params.IsLock = false
      const response = await booking.updateTeeTimeForBooking(params)
      this.showResToast(response)
      if (response.Status == '200') {
        this.$nextTick(() => {
          this.$emit('event', { type: 'after-update-booking-tee-time', newTeeTimeId: this.data.BookingDetail.TeeTimeId })
          this.$bvModal.hide('modal-update-booking')
        })
      } else if (response.Status == '400' && response.Messages.length > 0) {
        if(response.Messages[0].Code == "E.UBK02.C37") {
          const dataConfirm = {
              dataConfirm: {
                title: 'Cập nhật Caddy',
                content: 'Bạn có muốn cập nhật trùng caddy?',
                data: params
              },
            }
            this.$refs.confirmUpdateCaddy.open(dataConfirm)
        } else if (response.Messages[0].Code == "E.UBK02.C18") {
          const dataConfirm = {
              dataConfirm: {
                title: 'Cập nhật TeeTime',
                content: 'Bạn có muốn bỏ qua khóa hố không?',
                data: params
              },
            }
            this.$refs.confirmUpdateHole.open(dataConfirm)
        }
        // this.updateBooking_UBK02(true)
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.updateBooking_UBK02()
      }
      // if(payload.key === F4) {
      //   setTimeout(() => {
      //     this.hideSideBar()
      //   },500)
      // } 
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/variables';
#modal-update-booking{
  .selected-background-color{
    background: rgba(17, 74, 159, 0.04);;
  }
  .blocked-background-color{
    background: rgba(193, 193, 193, 0.12);
  }
}
.bg-linear-blue {
  background: $linear-blue;
}
.bg-primary {
  background-color: $primary;
}
.bg-light-gray {
  background-color: $light-grey;
}
.bg-grey {
  background-color: $grey;
}
.color-primary {
  color: $primary;
}
.color-gray {
  color: $light-grey;
}
.header-sidebar-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $primary;
  }
}

.block {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 16px;
}

.player-info {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
}

.wrapper {
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .block-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 6px 0;
  }
}
.text-checkbox {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}
::v-deep {
  .vs__selected-options {
    flex-wrap: inherit !important;
    max-width: 100%;
    overflow: hidden;
    .vs__selected {
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
    }
  }
}

::v-deep {
  .b-table-sticky-header {
    overflow: auto;
    max-height: 429px;
    text-align: center;

  }

  tbody > tr > td {
    text-align: center;
  }

}
.scroll-area {
  position: relative;
  margin: auto;
  height: 50vh;
}
</style>

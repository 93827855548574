<template>
  <div>
    <b-modal
      id="modal-delete-payment-detail"
      size="sm"
      centered
      hide-header
      cancel-variant="none"
      :ok-title="$t('golf_common_delete')"
      :cancel-title="$t('golf_common_back')"
      ok-variant="danger"
      @ok="deletePaymentDetail"
    >
      <b-card-header class="banned-background-color">
        <div class="d-flex justify-content-between align-items-center block-header">
          <p class="mb-0 title-modal color-red">
            {{ $t('golf_payment_delete_payment') }}
          </p>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-red"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <p class="mb-0">
          {{ $t('golf_payment_delete_payment_confirm') }}
        </p>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>

import { booking } from '@/api/booking'

export default {
  props: [
    'paymentRetailId',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-delete-payment-detail')
    },
    async deletePaymentDetail(bvModalEvent) {
      bvModalEvent.preventDefault()
      const data = {
        PaymentRetailDetail: {
          Id: this.paymentRetailId,
        },
        // Transaction: {
        //   TransactionCode: '1',
        //   TransactionType: 'DPM02',
        //   TransactionDate: '2022-03-04',
        //   TransactionFiscalDate: '2022-03-04',
        //   Note: '11111',
        // },
      }
      await booking.api_DPM06(data).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-delete-payment-detail', response })
          this.$nextTick(() => {
            this.$bvModal.hide('modal-delete-payment-detail')
          })
        } else {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-delete-payment-detail')
          })
        }
      }).catch(error => {
        this.showToast('error', this.$t('golf_common_error'))
        this.$nextTick(() => {
          this.$bvModal.hide('modal-delete-payment-detail')
        })
      })
    },
  },
}

</script>

<style lang="scss">
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 400px;
  }
}

.color-red {
  color: red;
}

.color-warning {
  color: #114A9F;
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}

.card-body {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5D5F65;
  }
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>

import { commonServices } from '@/api/common-services'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  required,
} from '@validations'

export default {
  name: 'SpecialDay',
  components: {

    ValidationProvider,
    ValidationObserver,

  },

  props: {
    value: {
      type: Number,
      required: false,
    },
    error: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      listSpecialDay: [],
      listSpecialDayTemplate: [],
      selectedItem: {
        NormalDayDetail: [
          {
            OpenDate: this.getUTC('start', 'day'),
            ExpirationDate: this.getUTC('end', 'day'),
            Note: '',
          },
        ],
      },
      selectedSpecialItem: {
        SpecialDayDetail: [
          
        ],
      },
      itemOperator: [
        {
          label: this.$t('rate_code_operator_in'),
          value: 'IN'
        },
        {
          label: this.$t('rate_code_operator_not_in'),
          value: 'NOT_IN'
        }
      ],
    }
  },
  created() {
    // Lấy danh sách template
    this.getListSpecialDay_RSD01_AC1()
    if (this.value) {
      this.getDetailSpecialDayNormal()
    }
  },
  watch: {
    value(x) {
      if(x) {
        this.getDetailSpecialDayNormal()
      }
    }
  },
  methods: {
    async getDetailSpecialDayNormal() {
      const bodyNormal = {
        SpecialDay: {
          Id: this.value,
          SpecialDayType: 'NORMAL',
        },
      }
      await commonServices
        .getDetailSpecialDay(bodyNormal)
        .then(res => {
          if (res.Status === '200') {
            this.selectedItem = res.Data.SpecialDay
            this.selectedSpecialItem = res.Data.SpecialDay
          } else {
            this.showResToast(res)
          }
        })
    },
    async getListSpecialDay_RSD01_AC1() {
      const bodyTemplate = {
        SpecialDayType: 'TEMPLATE',
      }
      await commonServices
        .getListSpecialDay(bodyTemplate)
        .then(res => {
          if (res.Status === '200') {
            this.listSpecialDayTemplate = res.Data.SpecialDay
          } else {
            this.showResToast(res)
          }
        })
    },
    async viewTemplate(Id) {
      const bodyTemplate = {
        SpecialDay: {
          Id,
          SpecialDayType: 'TEMPLATE',
        },

      }
      await commonServices
        .getDetailSpecialDay(bodyTemplate)
        .then(res => {
          if (res.Status === '200') {
            this.selectedItem = res.Data.SpecialDay
            this.selectedSpecialItem = res.Data.SpecialDay
            this.selectedItem.SampleDateName = res.Data.SpecialDay.SampleDateName
            this.value = res.Data.SpecialDay.Id
            this.$refs.specialDay.reset()
          } else {
            this.showResToast(res)
          }
        })
    },
    updateTemplate_RSD01_AC3 () {
      if(!this.selectedItem.SampleDateName) {
        return this.showToast('error', this.$t('rate_code_please_input_template_name'))
      }
      this.$refs.specialDay.validate().then(success => {
        if (success) {
          const tmp = []
          this.selectedItem.NormalDayDetail.forEach(x => {
            tmp.push({
              OpenDate: x.OpenDate,
              ExpirationDate: x.ExpirationDate,
              Note: x.Note,
              Operator: 'IN',
              SpecialDayType: 'NORMAL'
            })
          })
          const SpecialDaytmp = []
          if(this.selectedSpecialItem.SpecialDayDetail.length > 0) {
            this.selectedSpecialItem.SpecialDayDetail.forEach(y => {
              SpecialDaytmp.push({
                OpenDate: y.OpenDate,
                ExpirationDate: y.ExpirationDate,
                Note: y.Note,
                Operator: y.Operator,
                SpecialDayType: 'SPECIAL'
              })
            })
          }
          const body = {
            SpecialDay: {
              Id: this.value,
              SampleDateName: this.selectedItem.SampleDateName,
              SpecialDayType: 'NORMAL',
              NormalDayDetail: tmp,
              SpecialDayDetail: SpecialDaytmp
            },
          }

          commonServices
            .updateSpecialDay(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.getListSpecialDay_RSD01_AC1()
              }
            })
        }
      })
    },
    saveTemplate_RSD01_AC3() {
      if(!this.selectedItem.SampleDateName) {
        return this.showToast('error', this.$t('rate_code_please_input_template_name'))
      }
      this.$refs.specialDay.validate().then(async success => {
        if (success) {
          let check 
          const tmp = []
          this.selectedItem.NormalDayDetail.forEach(x => {
            tmp.push({
              OpenDate: x.OpenDate,
              ExpirationDate: x.ExpirationDate,
              Note: x.Note,
              Operator: 'IN',
              SpecialDayType: 'NORMAL'
            })
          })
          const SpecialDaytmp = []
          if(this.selectedSpecialItem.SpecialDayDetail.length > 0) {
            this.selectedSpecialItem.SpecialDayDetail.forEach(y => {
              SpecialDaytmp.push({
                OpenDate: y.OpenDate,
                ExpirationDate: y.ExpirationDate,
                Note: y.Note,
                Operator: y.Operator,
                SpecialDayType: 'SPECIAL'
              })
            })
          }
          const body = {
            SpecialDay: {
              SampleDateName: this.selectedItem.SampleDateName,
              SpecialDayType: 'TEMPLATE',
              NormalDayDetail: tmp,
              SpecialDayDetail: SpecialDaytmp
            },
          }
          await commonServices
            .addNewSpecialDay(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.value = res.Data.SpecialDay.Id
                this.getListSpecialDay_RSD01_AC1()
              }
            })
        }
      })
    },
    addRange() {
      this.selectedItem.NormalDayDetail.push({
        OpenDate: this.getUTC('start', 'day'),
        ExpirationDate: this.getUTC('end', 'day'),
        Note: null,
      })
    },
    addRangeSpecial() {
      this.selectedSpecialItem.SpecialDayDetail.push({
        OpenDate: this.getUTC('start', 'day'),
        ExpirationDate: this.getUTC('end', 'day'),
        Operator: 'IN',
        Note: null,
      })
    },
    deleteRange(index) {
      this.selectedItem.NormalDayDetail.splice(index, 1)
    },
    deleteRangeSpecial(index) {
      this.selectedSpecialItem.SpecialDayDetail.splice(index, 1)
    },
    async deleteTemplate_RSD01_AC2(templateId) {
      const body = {
        SpecialDay: {
          Id: templateId,
          SpecialDayType: 'TEMPLATE',
        },
      }
      await commonServices
        .deleteSpecialDay(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status === '200') {
            this.getListSpecialDay_RSD01_AC1()
          }
        })
    },
    confirm_RSD01_AC4() {
      if (this.value) {
        this.$emit('input', this.value)
        this.isOpen = false
      } else {
        this.showToast('error', this.$t('rate_code_please_save_template'))
      }
    },
  },
}

export const paramsUpdateRateCode = {
  BookingDetail: {
    Id: 196,
    RateId: 13,
    Reasons: "xyz",
    BookingDetailServices:[
      {
        BookingDetailId:196,
        ServiceId:1,
        RateId: 13,
        UnitPrice:1000,
        Quantity:1,
        Amount:1000,
        TotalAmount:1000,
        Discount: {
          DiscountCode:"ABC",
          DiscountAmount:1000,
          DiscountType: "FIX",
          Reasons:  "ABC",
          Amount:1000,
          SignerCode: null
        }
      }
    ]
  }
}
